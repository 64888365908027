import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ImportExportComponent } from '../dialogs/import-export/import-export.component';
import { CreateEditPhoneNumbersComponent } from './dialogs/create-edit-phone-numbers/create-edit-phone-numbers.component';
import { Setting, Number } from './../../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { redirectIfNotAdmin } from 'src/app/globals';
@Component({
  selector: 'app-phonenumbers',
  templateUrl: './phone-numbers.component.html',
  styleUrls: ['./phone-numbers.component.scss'],
})
export class PhoneNumbersComponent implements OnInit {
  @ViewChild('TABLE', { static: false }) table: ElementRef;
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  numberCollection: AngularFirestoreCollection<Number>;
  numbers: Observable<Number[]>;
  public filteredNumbers: ReplaySubject<Number[]> = new ReplaySubject<Number[]>(
    1
  );
  displayedColumns = ['order', 'name', 'phone', 'button'];
  importedData: Subject<Number[]> = new ReplaySubject<Number[]>();
  totalNumbers: number;
  orderNumbersArray: number[] = [];
  totalActive = 0;
  header = true;
  fileUploaded: File;
  worksheet: any;
  storeData: any;
  csvRecords: any[] = [];
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;

  organisationId = localStorage.getItem('orgId');
  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.settingDoc = this.db.doc<Setting>(
      `organisations/${this.organisationId}/settings/general`
    );
    this.setting = this.settingDoc.valueChanges();
    this.setting.subscribe((val) => {
      console.log('this.settings', val);
    });
    this.numberCollection = this.db.collection<Number>(
      `organisations/${this.organisationId}/numbers`,
      (ref) => ref.orderBy('order')
    );
    this.numbers = this.numberCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Number;
          // console.log('tag', data);
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.numbers.subscribe((value) => {
      console.log('numbers', value);
    });
    const combinedObservable = combineLatest(
      this.numbers,
      this.searchQueryChanged
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const numbers = values[0];
        const searchQuery = values[1];

        let filteredNumbers = numbers.filter((item) => this.checkFilters(item));
        numbers.forEach(async (numberData) => {
          this.orderNumbersArray.push(Number(numberData.order));
        });
        console.log('filteredNumbers', filteredNumbers);
        this.totalNumbers = filteredNumbers.length;
        this.filteredNumbers.next(filteredNumbers);
      });
    this.searchQueryChanged.next('');
    redirectIfNotAdmin(this.db.firestore, this.router);
  }

  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    }
  }

  checkFilters(number) {
    let passesSearchFilter = true;
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (number.name) {
        if (
          number.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      return number;
    }
  }

  openImportExport() {
    const dialogRef = this.dialog.open(ImportExportComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result.type === 'Export') {
        this.exportTableToExcel();
      }
      if (result.type === 'Import') {
        this.uploader.nativeElement.click();
      }
    });
  }

  editNumber(number?) {
    const dialogRef = this.dialog.open(CreateEditPhoneNumbersComponent, {
      width: '350px',
      data: {
        number,
        totalActive: this.totalActive,
        orderNumbersArray: this.orderNumbersArray,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  newNumber() {
    const dialogRef = this.dialog.open(CreateEditPhoneNumbersComponent, {
      width: '350px',
      data: {
        totalActive: this.totalActive,
        orderNumbersArray: this.orderNumbersArray,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  uploadedFile(event) {
    if (this.uploader.nativeElement.value === null) {
      return;
    } else {
      console.log('event', event);
      console.log('uploading file');
      this.fileUploaded = event.target.files[0];
      this.readExcel();
    }
  }
  readExcel() {
    const readFile = new FileReader();
    const spreadsheet = {};
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];

      Object.keys(this.worksheet).forEach((key) => {
        if (key !== '!ref' && key !== '!margins') {
          const rowId = key.match(/\d+/g).toString();
          const colId = key.match(/[a-zA-Z]+/g).toString();
          if (!spreadsheet[rowId]) {
            spreadsheet[rowId] = {};
          }
          spreadsheet[rowId][colId] = this.worksheet[key].w;
        }
      });
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'volgorde':
            columnNames[key] = 'order';
            break;
          case 'naam':
            columnNames[key] = 'name';
            break;
          case 'telefoonnummer':
            columnNames[key] = 'phone';
            break;
        }
      });
      delete spreadsheet[1];
      const importedData = [];
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {};
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          if (colName) {
            if (colName === 'order') {
              spreadsheet[key][colKey] = Number(spreadsheet[key][colKey]);
            }
            if (colName === 'name') {
              spreadsheet[key][colKey] = spreadsheet[key][colKey];
            }
            if (colName === 'phone') {
              spreadsheet[key][colKey] = spreadsheet[key][colKey];
            }
            rowObj[colName] = spreadsheet[key][colKey];
          }
        });
        console.log('rowObj', rowObj);
        importedData.push(rowObj);
      });
      this.importedData.next(importedData);
      this.fileChangeListener();
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async exportTableToExcel() {
    const numbersRef = this.db.collection<Number>(
      `organisations/${this.organisationId}/numbers`
    );
    const numbersArray = [];
    const numbersObservable = await numbersRef.get();
    await numbersObservable.forEach((numbers) => {
      numbers.forEach((numberDoc) => {
        const number = numberDoc.data() as Number;
        const exportNumberObj = {};
        exportNumberObj['Volgorde'] = number.order ? number.order : '';
        exportNumberObj['Naam'] = number.name ? number.name : '';
        exportNumberObj['Telefoonnummer'] = number.phone ? number.phone : '';
        numbersArray.push(exportNumberObj);
      });
    });
    if (numbersArray.length > 0) {
      numbersArray.sort(function (a, b) {
        if (a.Volgorde < b.Volgorde) {
          return -1;
        }
        if (a.Volgorde > b.Volgorde) {
          return 1;
        }
        return 0;
      });
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(numbersArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Numbers');

      // /* save to file */
      XLSX.writeFile(wb, 'telefoonnummers.xlsx');
    } else {
      console.log('nothingsend');
      // Nothing to export
    }
  }

  async removeNumber(number: Number) {
    console.log('id', number.id);
    await this.db
      .doc(`organisations/${this.organisationId}/numbers/${number.id}`)
      .delete();
    this._snackBar.open('Nummer is verwijderd', 'X', {
      duration: 5000,
    });
  }

  // Your applications input change listener for the CSV File
  fileChangeListener() {
    this.numberCollection = this.db.collection<Number>(
      `organisations/${this.organisationId}/numbers`
    );
    this.numbers = this.numberCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Number;
          // console.log('tag', data);
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.numbers.subscribe((value) => {
      console.log('numbers', value);
      this.totalNumbers = value.length;
    });
    this.importedData.subscribe((val) => {
      console.log('this.importedData', val);
    });
    try {
      const combinedObservable = combineLatest(this.numbers, this.importedData);
      combinedObservable
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((res: [Number[], Array<any>]) => {
          console.log('res', res);
          const existingNumbers = res[0];
          const importedNumbers = res[1];
          if (importedNumbers === null) {
            return;
          }
          // check for existingVolunteers with importedVolunteers' data, update their info.
          // create new entry for all newly imported volunteers, process account creation in functions trigger.
          importedNumbers.forEach(async (numberData) => {
            console.log('numberData', numberData);
            let order;
            let name;
            let phone;
            // this section looks for a recognized column title and matches it with the correct userData
            if (numberData.order) {
              order = numberData.order;
            }
            if (numberData.name) {
              name = numberData.name;
            }
            if (numberData.phone) {
              phone = numberData.phone;
            }
            const importData: Number = { order, name, phone };
            //
            const existingData = existingNumbers.find((element) => {
              return element.name === importData.name;
            });

            console.log('existingData', existingData);
            if (existingData) {
              // update existing doc

              await this.numberCollection
                .doc(existingData.id)
                .set(importData, { merge: true });
            } else {
              // create new doc
              await this.numberCollection.add(importData);
            }
            this.importedData.next(null);
            this.uploader.nativeElement.value = null;
          });

          this._snackBar.open('De telefoon nummers zijn geimporteerd', 'X', {
            duration: 5000,
          });
        });
    } catch (err) {
      console.error(err);
    }
  }
}
