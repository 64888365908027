import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-export-audits',
  templateUrl: './export-audits.component.html',
  styleUrls: ['./export-audits.component.scss'],
})
export class ExportAuditsDialogComponent implements OnInit {
  exportForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ExportAuditsDialogComponent>
  ) {}

  ngOnInit(): void {
    this.exportForm = this.fb.group({
      timeAdded: [false],
    });
  }

  closeDialog() {
    return { value: this.exportForm.controls.timeAdded.value };
  }
}
