import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { logExport } from 'src/app/globals';
import { UserId } from 'src/app/interfaces';
import { CustomValidators } from 'src/app/validators/custom-validators';
import * as XLSX from 'xlsx';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
import { AccountType } from 'src/app/enums';
import { getAuth } from 'firebase/auth';
import moment from 'moment';

const app = initializeApp(environment.firebase);
const functions = getFunctions(app, 'europe-west1');

export interface DialogData {
  allUsers: any[];
  filteredUsers: any[];
  icons: any[];
}

@Component({
  selector: 'app-import-export-users',
  templateUrl: './import-export-users.component.html',
  styleUrls: ['./import-export-users.component.scss'],
})
export class ImportExportUsersComponent implements OnInit {
  organisationId = localStorage.getItem('orgId');
  processing = false;
  formGroup = new FormGroup({
    type: new FormControl('export'),
    allOrSome: new FormControl('filtered'),
    importFile: new FormControl({ value: null, disabled: true }, [
      Validators.required,
      CustomValidators.requiredFileType('xlsx'),
    ]),
  });
  importFileWorksheet: any;
  typeOptions: any[] = [
    { name: 'Exporteren', value: 'export' },
    { name: 'Importeren', value: 'import', disabled: true },
  ];
  allOrSomeOptions: any[] = [
    { name: 'Alle gebruikers (inclusief verstopte)', value: 'all' },
    { name: 'Alleen gefilterde deelnemers', value: 'filtered' },
  ];

  constructor(
    public dialogRef: MatDialogRef<ImportExportUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public db: AngularFirestore
  ) {}

  async ngOnInit(): Promise<void> {
    console.log('allUsers', this.data.allUsers);
    console.log('filteredUsers', this.data.filteredUsers);
    this.formGroup.valueChanges.subscribe((formValue) => {
      if (formValue.type === 'import') {
        if (this.formGroup.controls.importFile.disabled) {
          this.formGroup.controls.importFile.enable();
          this.formGroup.controls.importFile.reset();
        }
      } else if (formValue.type === 'export') {
        if (this.formGroup.controls.importFile.enabled) {
          this.formGroup.controls.importFile.disable();
        }
      }
      if (formValue.importFile) {
        console.log('file', formValue.importFile);
        this.importFileWorksheet = null;
        const importFile = formValue.importFile as unknown as Blob;
        this.readExcel(importFile);
      }
    });
    const currentUserId = getAuth().currentUser?.uid;
    const currentUser = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/users/${currentUserId}`
        )
      )
    ).data() as UserId;
    console.log('currentUser', currentUser);
    if (currentUser?.accountType == AccountType.Admin) {
      this.typeOptions[1].disabled = false;
    }
  }

  async import() {
    if (this.processing) {
      return;
    }
    this.processing = true;
    // Process worksheet
    const promises: any[] = [];
    const spreadsheet = {};
    Object.keys(this.importFileWorksheet).forEach((key) => {
      try {
        if (
          key !== '!ref' &&
          key !== '!margins' &&
          key !== '!autofilter' &&
          key !== '!merges'
        ) {
          // console.log('key', key);
          const rowId = key.match(/\d+/g).toString();
          const colId = key.match(/[a-zA-Z]+/g).toString();
          if (!spreadsheet[rowId]) {
            spreadsheet[rowId] = {};
          }
          spreadsheet[rowId][colId] = this.importFileWorksheet[key].w;
        }
      } catch (error) {
        console.log('key with error:', key);
        console.error(error);
      }
    });
    const columnNames = spreadsheet[1];
    Object.keys(columnNames).forEach((key) => {
      // console.log('key', key);
      key = key;
      const val = columnNames[key].toLowerCase();
      switch (val) {
        default:
          // check if columname is included in icons
          const foundIcon = this.data.icons.find(
            ({ name }) => name.toLowerCase() === val
          );
          if (foundIcon) {
            columnNames[key] = foundIcon.id;
          } else {
            delete columnNames[key];
          }
          break;
        case 'deelnemer id':
          columnNames[key] = 'userId';
          break;
        case 'e-mail':
          columnNames[key] = 'email';
          break;
        case 'geboortedatum':
          columnNames[key] = 'birthDate';
          break;
        case 'kaartnummer':
          columnNames[key] = 'cardId';
          break;
        case 'naam':
          columnNames[key] = 'name';
          break;
        case 'stad':
          columnNames[key] = 'city';
          break;
        case 'straatnaam':
          columnNames[key] = 'streetName';
          break;
        case 'huisnummer':
          columnNames[key] = 'houseNumber';
          break;
        case 'huisnummer toevoeging':
          columnNames[key] = 'houseNumberAddition';
          break;
        case 'postcode':
          columnNames[key] = 'postal';
          break;
        case 'telefoonnummer':
          columnNames[key] = 'phoneNumber';
          break;
        case 'acceptatiedatum privacybeleid':
          columnNames[key] = 'privacyPolicyAcceptDate';
          break;
      }
    });
    delete spreadsheet[1];
    Object.keys(spreadsheet).forEach((key) => {
      let userId: String;
      const userData = {} as UserId;
      Object.keys(spreadsheet[key]).forEach((colKey) => {
        const colName = columnNames[colKey];
        if (colName && spreadsheet[key][colKey].length !== 0) {
          const value = spreadsheet[key][colKey];
          if (value && !value.includes('N.V.T')) {
            switch (colName) {
              default:
                userData[colName] = spreadsheet[key][colKey];
                // check if columname is included in icons
                const foundIcon = this.data.icons.find(
                  ({ id }) => id === colName
                );
                if (foundIcon) {
                  if (!userData.icons) {
                    userData.icons = {};
                  }
                  userData.icons[colName] = spreadsheet[key][colKey]
                    .toLowerCase()
                    .includes('ja')
                    ? true
                    : false;
                  delete userData[colName];
                }
                break;
              case 'userId':
                userId = spreadsheet[key][colKey];
                break;
              case 'birthDate':
                userData[colName] = this.transformDutchDateStringToDate(
                  spreadsheet[key][colKey]
                );
                break;
              case 'privacyPolicyAcceptDate':
                userData[colName] = this.transformDutchDateStringToDate(
                  spreadsheet[key][colKey]
                );
                break;
            }
          }
        }
      });
      console.log('userData.name:', userData.name);
      console.log('userData:', userData);
      if (userId) {
        const userDoc = doc(
          this.db.firestore,
          `organisations/${this.organisationId}/users/${userId}`
        );
        promises.push(setDoc(userDoc, userData, { merge: true }));
      } else {
        const callable = httpsCallable(functions, 'httpAddUser');
        const params = {
          email: userData.email,
          phoneNumber: userData.phoneNumber,
          organisationId: this.organisationId,
          saveObj: userData,
        };
        promises.push(callable(params));
      }
    });
    await Promise.all(promises);
    this.dialogRef.close();
  }

  transformDutchDateStringToDate(dutchDateString: string) {
    const splitDateTime = dutchDateString.split(' ');
    const dateArray = splitDateTime[0].split('-');
    let timeString = '';
    if (splitDateTime.length > 1) {
      // Includes time aswell
      timeString = ` ${splitDateTime[1]}`;
    }
    const dateString = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}${timeString}`;
    return new Date(dateString);
  }

  readExcel(importFile: Blob) {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      const storeData = readFile.result as any;
      const data = new Uint8Array(storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.importFileWorksheet = workbook.Sheets[firstSheetName];
    };
    readFile.readAsArrayBuffer(importFile);
    console.log('this.vouchersFileUploaded', this.formGroup.value.importFile);
  }

  async export() {
    const usersExcel = [];
    console.log(
      'this.formGroup.value.allOrSome',
      this.formGroup.value.allOrSome
    );
    const usersToExport =
      this.formGroup.value.allOrSome === 'filtered'
        ? this.data.filteredUsers
        : this.data.allUsers;

    console.log('usersToExport', usersToExport);
    usersToExport.forEach((element: any) => {
      let birthDate = element.birthDate ? element.birthDate.toDate() : 'N.V.T';
      let createdAt = element.createdAt ? element.createdAt.toDate() : 'N.V.T';
      let privacyPolicyAcceptence = element.privacyPolicyAcceptDate
        ? element.privacyPolicyAcceptDate.toDate()
        : 'N.V.T';
      let accountType = element.accountType ? element.accountType : 'Deelnemer';
      if (birthDate !== 'N.V.T') {
        birthDate = moment(birthDate).format('DD-MM-YYYY');
      }

      if (createdAt !== 'N.V.T') {
        createdAt = moment(createdAt).format('DD-MM-YYYY HH:mm');
      }
      if (privacyPolicyAcceptence !== 'N.V.T') {
        privacyPolicyAcceptence = moment(privacyPolicyAcceptence).format(
          'DD-MM-YYYY HH:mm'
        );
      }

      if (accountType !== 'Deelnemer') {
        switch (accountType) {
          case AccountType.Admin:
            accountType = 'Administrator';
            break;
          case AccountType.Driver:
            accountType = 'Chauffeur';
            break;
        }
      }

      let objectToPush = {
        'Kaartnummer': element.cardId ? element.cardId : 'N.V.T',
        'Naam': element.name ? element.name : 'N.V.T',
        'E-mail': element.email ? element.email : 'N.V.T',
        'Verborgen Opmerkingen': element.hiddenRemarks
          ? element.hiddenRemarks
          : 'N.V.T',
        'Geboortedatum': birthDate,
        'Stad': element.city ? element.city : 'N.V.T',
        'Straatnaam': element.streetName ? element.streetName : 'N.V.T',
        'Huisnummer': element.houseNumber ? element.houseNumber : 'N.V.T',
        'Huisnummer toevoeging': element.houseNumberAddition
          ? element.houseNumberAddition
          : 'N.V.T',
        'Postcode': element.postal ? element.postal : 'N.V.T',
        'Telefoonnummer': element.phoneNumber ? element.phoneNumber : 'N.V.T',
        'Credits': element.credits ? element.credits : 'N.V.T',
        'Account type': accountType,
        'Gemaakt op': createdAt,
        'Plan naam': element.currentPlan ? element.currentPlan.name : 'N.V.T',
        'Acceptatiedatum privacybeleid': privacyPolicyAcceptence,
      };
      this.data.icons.forEach((icon) => {
        objectToPush[icon.name] = element.icons?.[icon.id] ? 'Ja' : 'N.V.T';
      });
      objectToPush['Deelnemer ID'] = element.id;
      usersExcel.push(objectToPush);
    });
    console.log('usersExcel', usersExcel);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(usersExcel); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Gebruikers');

    // /* save to file */
    XLSX.writeFile(wb, 'Gebruikers' + '.xlsx');
    await logExport('users');
    this.dialogRef.close();
  }
}
