<h1 mat-dialog-title>Dagdeel {{ dayPartData.daypart.name }} kopiëren</h1>
<div *ngIf="!hasGlobalException">
  <div mat-dialog-content>
    <form [formGroup]="copyForm">
      <mat-form-field>
        <mat-label>Wekelijks of om de week</mat-label>
        <mat-select formControlName="copyType" required>
          <mat-option [value]="'weekly'">Wekelijks</mat-option>
          <mat-option [value]="'everyOtherWeek'">Om de week</mat-option>
        </mat-select>
        <mat-error *ngIf="copyForm.controls.copyType.hasError('required')">
          Dit veld is verplicht
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Aantal kopieën</mat-label>
        <input
          formControlName="copyTimes"
          type="number"
          autocomplete="off"
          min="1"
          step="1"
          matInput
          required
        />
        <mat-error *ngIf="copyForm.controls.copyTimes.hasError('required')">
          Dit veld is verplicht
        </mat-error>
      </mat-form-field>
    </form>
    <div class="custom-message">
      <p>{{ customMessage }}</p>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]>annuleren</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!copyForm.valid"
      (click)="copyDaypart()"
    >
      kopiëren
    </button>
  </div>
</div>
<div *ngIf="hasGlobalException">
<div mat-dialog-content>
    <div
      class="custom-message"
      *ngIf= "customMessage != ''"
    >
      <p>{{ customMessage }}</p>
    </div>
    <div
      class="custom-message"
      *ngIf= "customMessageList.length > 0"
    >
      <p>De ritten zijn niet volledig gekopieerd omdat niet alle planners op alle dagdelen aanwezig zijn</p>
      <p *ngFor="let message of customMessageList">
        {{ message[0] }} is niet aanwezig op de volgende data: {{message[1]}}
      </p>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]
      (click)="closeDialog()"
    >
      OK
    </button>
  </div>
</div>
