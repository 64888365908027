<h1 mat-dialog-title>
  <span 
    class="title"
    *ngIf="date && data.planType == 'drivers'"
  >
    {{ date | date: 'dd/MM' }} - {{ data.dayPart.daypart.name }} -
    {{ data.vehicle.name }}
  </span>
  <span
    class="title"
    *ngIf="date && data.planType == 'planners'"
  >
    {{ date | date: 'dd/MM' }} - {{ data.dayPart.daypart.name }}
  </span>
  <div class="buttons">
    <button
      mat-icon-button
      (click)="openCopyDialog('driver')"
      *ngIf="data.planType == 'drivers'"
    >
      <mat-icon>file_copy</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="openCopyDialog('planner')"
      *ngIf="data.planType == 'planners'"
    >
      <mat-icon>file_copy</mat-icon>
    </button>
  </div>
</h1>
<div 
  mat-dialog-content
  *ngIf="data.planType == 'drivers'"
>
  <form [formGroup]="planDayPartDayForm">
    <mat-form-field>
      <mat-label>Welke chauffeur rijdt deze dienst?</mat-label>
      <mat-select
        cdkFocusInitial
        formControlName="driverId"
        (selectionChange)="changedDriver($event.value)"
        required
      >
        <mat-option>
          <ngx-mat-select-search
            placeholderLabel="Zoeken"
            (keyup)="this.searchQueryChanged.next($event.target.value)"
            noEntriesFoundLabel="Geen resultaten gevonden"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="'none'"> Geen </mat-option>
        <mat-option
          *ngFor="let plannedUser of filteredUsers | async"
          [value]="plannedUser.id"
        >
          <div class="info">
            <mat-icon
              (click)="navigateToUser(plannedUser.id)"
              *ngIf="planDayPartDayForm.value.driverId !== plannedUser.id"
            >
              info
            </mat-icon>
            <div>{{ plannedUser.user.name }}</div>
          </div>
          <div class="icons">
            <mat-icon
              *ngIf="planDayPartDayForm.value.driverId !== plannedUser.id"
              [matTooltipPosition]="'above'"
              matTooltip="{{
                plannedUser.ridesVehicle
                  ? 'De chauffeur wil in dit voertuig rijden'
                  : 'De chauffeur wil niet in dit voertuig rijden'
              }}"
              [ngClass]="{
                green: plannedUser.ridesVehicle,
                red: !plannedUser.ridesVehicle
              }"
            >
              taxi_alert
            </mat-icon>
            <mat-icon
              *ngIf="planDayPartDayForm.value.driverId !== plannedUser.id"
              [ngClass]="{
                green: plannedUser.available && !plannedUser.hasException,
                red: !plannedUser.available || plannedUser.hasException
              }"
              matTooltip="{{
                plannedUser.available && !plannedUser.hasException
                  ? 'De chauffeur kan op dit tijdstip rijden'
                  : 'De chauffeur kan niet rijden op dit tijdstip'
              }}"
              [matTooltipPosition]="'above'"
            >
              event_busy
            </mat-icon>
            <div class="icon-recommended" *ngIf="plannedUser.recommended"></div>
            <div
              class="icon-notRecommended"
              *ngIf="!plannedUser.recommended"
              matTooltip="Deze chauffeur wordt niet aangeraden om in te plannen. Neem contact op met de chauffeur voor meer informatie"
              [matTooltipPosition]="'above'"
            ></div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <h5>Begin tijd</h5>
      <div class="datetime remove-top-room">
        <mat-form-field class="hour">
          <mat-label>Uur</mat-label>
          <mat-select formControlName="startHour">
            <mat-option *ngFor="let hour of hours" [value]="hour">
              {{ hour }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="minute">
          <mat-label>Minuut</mat-label>
          <mat-select formControlName="startMinute">
            <mat-option *ngFor="let minute of minutes" [value]="minute.val">
              {{ minute.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div>
      <h5>Eind tijd</h5>
      <div class="datetime remove-top-room">
        <mat-form-field class="hour">
          <mat-label>Uur</mat-label>
          <mat-select formControlName="endHour">
            <mat-option *ngFor="let hour of hours" [value]="hour">
              {{ hour }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="minute">
          <mat-label>Minuut</mat-label>
          <mat-select formControlName="endMinute">
            <mat-option *ngFor="let minute of minutes" [value]="minute.val">
              {{ minute.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div *ngIf='currentUser' class="user-info" >
    <div>
      {{currentUser.user.name}}
    </div>
    <div class="icons icons-under">
      <mat-icon
        [matTooltipPosition]="'above'"
        matTooltip="{{
          currentUser.ridesVehicle
            ? 'De chauffeur wil in dit voertuig rijden'
            : 'De chauffeur wil niet in dit voertuig rijden'
        }}"
        [ngClass]="{
          green: currentUser.ridesVehicle,
          red: !currentUser.ridesVehicle
        }"
      >
        taxi_alert
      </mat-icon>
      <mat-icon
        [ngClass]="{
          green: currentUser.available && !currentUser.hasException,
          red: !currentUser.available || currentUser.hasException
        }"
        matTooltip="{{
          currentUser.available && !currentUser.hasException
            ? 'De chauffeur kan op dit tijdstip rijden'
            : 'De chauffeur kan niet rijden op dit tijdstip'
        }}"
        [matTooltipPosition]="'above'"
      >
        event_busy
      </mat-icon>
    </div>
  </div>
  <div class="driver-info" *ngIf="driverEmail || driverPhone">
    <h4 *ngIf="driverEmail && driverEmail.length">E-mail:</h4>
    <p *ngIf="driverEmail && driverEmail.length" class="data">
      <a target="_blank" href="mailto:{{ driverEmail }}">{{ driverEmail }}</a>
    </p>
    <br />
    <h4 *ngIf="driverPhone && driverPhone.length">Telefoon:</h4>
    <p *ngIf="driverPhone && driverPhone.length" class="data">
      <a target="_blank" href="tel:{{ driverPhone }}">{{ driverPhone }}</a>
    </p>
    <br />
  </div>
</div>
<div 
  mat-dialog-content
  *ngIf="data.planType == 'planners'"
>
  <!--h1? Welke planner werkt er deze dienst?-->
  <!--p? selecteer een planner in de lijst hieronder-->
  <form [formGroup]="planDayPartDayPlannerForm">
    <mat-form-field>
      <mat-label>Selecteer een Planner</mat-label>
      <mat-select
        cdkFocusInitial
        formControlName="plannerIds"
        (selectionChange)="selectPlanner($event.value)"
        required
        multiple
        disableOptionCentering
      >
        <mat-option>
          <ngx-mat-select-search
            placeholderLabel="Zoeken"
            (keyup)="this.searchQueryChanged.next($event.target.value)"
            noEntriesFoundLabel="Geen resultaten gevonden"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="'none'"> Geen </mat-option>
        <mat-option
          *ngFor="let plannedUser of filteredUsers | async"
          [value]="plannedUser.id"
        >
          <div class="info">
            <mat-icon
              (click)="navigateToUser(plannedUser)"
              *ngIf="planDayPartDayPlannerForm.value.plannerIds.indexOf(plannedUser.id) < 0"
            >
              info
            </mat-icon>
          </div>
          <span>{{ plannedUser.user.name }}</span>
          <div class="icons">
            <mat-icon
              *ngIf="planDayPartDayPlannerForm.value.plannerIds.indexOf(plannedUser.id) < 0"
              [ngClass]="{
                green: plannedUser.available && !plannedUser.hasException,
                red: !plannedUser.available || plannedUser.hasException
              }"
              matTooltip="{{
                plannedUser.available && !plannedUser.hasException
                  ? 'De planner kan op dit tijdstip werken'
                  : 'De planner kan niet werken op dit tijdstip'
              }}"
              [matTooltipPosition]="'above'"
            >
              event_busy
            </mat-icon>
          </div>
          <div class="icon-recommended" *ngIf="plannedUser.recommended"></div>
          <div
            class="icon-notRecommended"
            *ngIf="!plannedUser.recommended"
            matTooltip="Deze planner wordt niet aangeraden om in te plannen. Neem contact op met de planner voor meer informatie"
            [matTooltipPosition]="'above'"
          ></div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="planner-info">
      <div *ngFor="let planner of selectedPlannerUsers">
          <div class="info">
            <mat-icon
              (click)="navigateToUser(planner.id)"
            >
              info
            </mat-icon>
          </div>
          <p>{{ planner.user.name }}</p>
          <div class="icons">
            <mat-icon
              [ngClass]="{
                green: planner.available && !planner.hasException,
                red: !planner.available || planner.hasException
              }"
              matTooltip="{{
                planner.available && !planner.hasException
                  ? 'De planner kan op dit tijdstip werken'
                  : 'De planner kan niet werken op dit tijdstip'
              }}"
              [matTooltipPosition]="'above'"
            >
              event_busy
            </mat-icon>
          </div>
          <div class="icon-recommended" *ngIf="planner.recommended"></div>
          <div
            class="icon-notRecommended"
            *ngIf="!planner.recommended"
            matTooltip="Deze planner wordt niet aangeraden om in te plannen. Neem contact op met de planner voor meer informatie"
            [matTooltipPosition]="'above'"
          ></div>
          <div
            class="delete"
            matTooltip="Verwijder deze planner bij dit dagdeel"
            [matTooltipPosition]="'above'"
            (click)="removePlanner(planner.id)"
          >
            <mat-icon>delete</mat-icon>
          </div>
      </div>
    </div>
    <div>
      <h5>Begin tijd</h5>
      <div class="datetime remove-top-room">
        <mat-form-field class="hour">
          <mat-label>Uur</mat-label>
          <mat-select formControlName="startHour">
            <mat-option *ngFor="let hour of hours" [value]="hour">
              {{ hour }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="minute">
          <mat-label>Minuut</mat-label>
          <mat-select formControlName="startMinute">
            <mat-option *ngFor="let minute of minutes" [value]="minute.val">
              {{ minute.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div>
      <h5>Eind tijd</h5>
      <div class="datetime remove-top-room">
        <mat-form-field class="hour">
          <mat-label>Uur</mat-label>
          <mat-select formControlName="endHour">
            <mat-option *ngFor="let hour of hours" [value]="hour">
              {{ hour }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="minute">
          <mat-label>Minuut</mat-label>
          <mat-select formControlName="endMinute">
            <mat-option *ngFor="let minute of minutes" [value]="minute.val">
              {{ minute.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button 
    mat-button 
    [mat-dialog-close]="false"
    (click)="restorePlannerUsers()"
  >ANNULEREN</button>
  <button
    mat-flat-button color="primary" 
    (click)="saveDriver()"
    *ngIf="data.planType == 'drivers'"
  >
    OPSLAAN
  </button>
  <button 
    mat-flat-button color="primary" 
    (click)="savePlanner()"
    *ngIf="data.planType == 'planners'"
  >
    OPSLAAN
  </button>
</div>
