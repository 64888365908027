<h1
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  Rit kopiëren
</h1>
<div mat-dialog-content>
  <form (ngSubmit)="copy()" [formGroup]="copyForm">
    <mat-form-field>
      <mat-label>Wekelijks of om de week</mat-label>
      <mat-select formControlName="type" required>
        <!-- <mat-option value="daily">Dagelijks</mat-option> -->
        <mat-option value="weekly">Wekelijks</mat-option>
        <mat-option value="everyotherweek">Om de week</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Aantal kopieën</mat-label>
      <input
        formControlName="times"
        type="number"
        autocomplete="off"
        min="1"
        step="1"
        matInput
        required
      />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Annuleren</button>
  <button
    mat-flat-button
    [disabled]="!copyForm.valid"
    (click)="copy()"
    color="primary"
  >
    Kopieën maken
  </button>
</div>
