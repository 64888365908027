<h1
  mat-dialog-title
  *ngIf="!this.isEditing"
>
  Dagdeel toevoegen
</h1>
<h1
  mat-dialog-title
  *ngIf="this.isEditing"
>
  Dagdeel wijzigen
</h1>
<div mat-dialog-content>
  <form
    [formGroup]="daypartsForm"
    *ngIf="hours.length !== 0"
  >
    <mat-form-field class="name">
      <mat-label>Naam</mat-label>
      <input
        matInput
        type="text"
        autocomplete="off"
        formControlName="name"
        required
      />
      <mat-error *ngIf="daypartsForm.controls.name.errors?.required">
        Dit veld is verplicht
      </mat-error>
    </mat-form-field>
    <mat-form-field class="day">
      <mat-label>Dag</mat-label>
      <mat-select
        formControlName="day"
        required
      >
        <mat-option
          *ngFor="let day of days"
          [value]="day"
          >{{ day }}</mat-option
        >
      </mat-select>
      <mat-error *ngIf="daypartsForm.controls.day.errors?.required">
        Dit veld is verplicht
      </mat-error>
    </mat-form-field>
    <h5>Van</h5>
    <div class="half-fields">
      <mat-form-field class="hour">
        <mat-label>Uur</mat-label>
        <mat-select
          formControlName="startHour"
          required
        >
          <mat-option
            *ngFor="let hour of hours"
            [value]="hour"
            >{{ hour }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="daypartsForm.controls.startHour.errors?.required">
          Dit veld is verplicht
        </mat-error>
      </mat-form-field>
      <mat-form-field class="min">
        <mat-label>Minuten</mat-label>
        <mat-select
          formControlName="startMin"
          required
        >
          <mat-option
            *ngFor="let min of minutes"
            [value]="min.val"
            >{{ min.text }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="daypartsForm.controls.startMin.errors?.required">
          Dit veld is verplicht
        </mat-error>
      </mat-form-field>
    </div>
    <h5>Tot</h5>
    <div class="half-fields">
      <mat-form-field class="hour">
        <mat-label>Uur</mat-label>
        <mat-select
          formControlName="endHour"
          required
        >
          <mat-option
            *ngFor="let hour of hours"
            [value]="hour"
            >{{ hour }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="daypartsForm.controls.endHour.errors?.required">
          Dit veld is verplicht
        </mat-error>
      </mat-form-field>
      <mat-form-field class="min">
        <mat-label>Minuten</mat-label>
        <mat-select
          formControlName="endMin"
          required
        >
          <mat-option
            *ngFor="let min of minutes"
            [value]="min.val"
            >{{ min.text }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="daypartsForm.controls.endHour.errors?.required">
          Dit veld is verplicht
        </mat-error>
      </mat-form-field>
    </div>
    <mat-error *ngIf="this.isEditing">
      Deze wijziging heeft alleen effect op dagdeeldagen waar nog geen chauffeur
      is gekoppeld.
    </mat-error>
  </form>
</div>
<div
  mat-dialog-actions
  align="end"
  *ngIf="hours.length !== 0"
>
  <button
    mat-button
    [mat-dialog-close]
  >
    ANNULEREN
  </button>
  <button
    mat-flat-button
    (click)="addDaypart()"
    cdkFocusInitial
    [disabled]="!daypartsForm.valid"
  >
    {{ this.isEditing ? 'WIJZIG' : 'TOEVOEGEN' }}
  </button>
</div>

<div *ngIf="!daypartsForm">
  <mat-spinner></mat-spinner>
</div>
