import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ImportExportComponent } from '../dialogs/import-export/import-export.component';
import { NgxCsvParser } from 'ngx-csv-parser';
import { CreateEditVehiclesComponent } from './dialogs/create-edit-vehicles/create-edit-vehicles.component';
import { WarningDialog } from '../../global-dialogs/warning/warning.component';
import { Setting, Vehicle } from './../../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { redirectIfNotAdmin } from 'src/app/globals';
@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss'],
})
export class VehiclesComponent implements OnInit {
  @ViewChild('TABLE', { static: false }) table: ElementRef;
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  vehicleCollection: AngularFirestoreCollection<Vehicle>;
  vehicles: Observable<Vehicle[]>;
  public filteredVehicles: ReplaySubject<Vehicle[]> = new ReplaySubject<
    Vehicle[]
  >(1);
  displayedColumnsWith = [
    'name',
    'seats',
    'currentMileage',
    'color',
    'sortingNumber',
    'backgroundColorEmpty',
    'backgroundColorFull',
    'backgroundColorNoDriver',
    'button',
  ];
  displayedColumnsWithout = [
    'name',
    'seats',
    'currentMileage',
    'color',
    'sortingNumber',
    'backgroundColorEmpty',
    'backgroundColorFull',
    'button',
  ];
  importedData: Subject<Vehicle[]> = new ReplaySubject<Vehicle[]>();
  totalVehicles: number;
  totalActive: number = 0;
  header: boolean = true;
  fileUploaded: File;
  worksheet: any;
  storeData: any;
  csvRecords: any[] = [];
  orderNumbersArray: number[] = [];
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;
  maxTotalVehicle: number;
  ignoreDriverStatus: boolean = true;
  showDriverPlanning: boolean = false;

  organisationId = localStorage.getItem('orgId');

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private ngxCsvParser: NgxCsvParser,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.showDriverPlanning = JSON.parse(
      localStorage.getItem('usesDriverPlanning')
    );
    this.settingDoc = this.db.doc<Setting>(
      `organisations/${this.organisationId}/settings/general`
    );
    this.setting = this.settingDoc.valueChanges();
    this.setting.subscribe((val) => {
      this.maxTotalVehicle = val.maxActiveVehicles ?? 10;
      this.ignoreDriverStatus = val.ignoreDriverStatus ?? false;
    });
    this.vehicleCollection = this.db.collection<Vehicle>(
      `organisations/${this.organisationId}/vehicles`,
      (ref) => ref.orderBy('calendarStyle.sortingNumber', 'asc')
    );
    this.vehicles = this.vehicleCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Vehicle;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.vehicles.subscribe((value) => {
      let totalActive = 0;
      value.forEach((val) => {
        if (val.active === true) {
          totalActive = totalActive + 1;
        }
      });
      this.totalActive = totalActive;
      this.totalVehicles = value.length;
    });
    const combinedObservable = combineLatest(
      this.vehicles,
      this.searchQueryChanged
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        const vehicles = values[0];
        const searchQuery = values[1];
        let filteredVehicles = vehicles.filter((item) =>
          this.checkFilters(item)
        );
        this.totalVehicles = filteredVehicles.length;
        this.filteredVehicles.next(filteredVehicles);
        vehicles.forEach(async (vehicleData) => {
          this.orderNumbersArray.push(
            Number(vehicleData.calendarStyle.sortingNumber)
          );
        });
      });
    this.searchQueryChanged.next('');
    redirectIfNotAdmin(this.db.firestore, this.router);
  }

  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    }
  }

  checkFilters(vehicle) {
    let passesSearchFilter = true;
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (vehicle.name) {
        if (
          vehicle.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      return vehicle;
    }
  }

  openImportExport() {
    const dialogRef = this.dialog.open(ImportExportComponent, {
      width: '375px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result.type === 'Export') {
        this.exportTableToExcel();
      }
      if (result.type === 'Import') {
        this.uploader.nativeElement.click();
      }
    });
  }

  editVehicle(vehicle?) {
    const dialogRef = this.dialog.open(CreateEditVehiclesComponent, {
      width: '375px',
      data: {
        vehicle,
        totalActive: this.totalActive,
        orderNumbersArray: this.orderNumbersArray,
      },
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  async deactivateVehicle(vehicle) {
    // await this.db.collection('vehicles').doc(vehicle.id).delete();
  }

  uploadedFile(event) {
    if (this.uploader.nativeElement.value === null) {
      return;
    } else {
      this.fileUploaded = event.target.files[0];
      this.readExcel();
    }
  }
  readExcel() {
    const readFile = new FileReader();
    const spreadsheet = {};
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];

      Object.keys(this.worksheet).forEach((key) => {
        if (key !== '!ref' && key !== '!margins') {
          const rowId = key.match(/\d+/g).toString();
          const colId = key.match(/[a-zA-Z]+/g).toString();
          if (!spreadsheet[rowId]) {
            spreadsheet[rowId] = {};
          }
          spreadsheet[rowId][colId] = this.worksheet[key].w;
        }
      });
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'naam':
            columnNames[key] = 'name';
            break;
          case 'zitplaatsen':
            columnNames[key] = 'seats';
            break;
          case 'auto kleur':
            columnNames[key] = 'color';
            break;
          case 'volgordenummer':
            columnNames[key] = 'sortingNumber';
            break;
          case 'kilometerstand':
            columnNames[key] = 'currentMileage';
            break;
          case 'rit leeg kleur':
            columnNames[key] = 'backgroundColorEmpty';
            break;
          case 'rit vol kleur':
            columnNames[key] = 'backgroundColorFull';
            break;
          case 'geen chauffeur kleur':
            columnNames[key] = 'backgroundColorNoDriver';
            break;
          case 'actief':
            columnNames[key] = 'active';
            break;
        }
      });
      delete spreadsheet[1];
      const importedData = [];
      let totalActive = 0;
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {};
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          if (colName) {
            if (colName === 'seats') {
              spreadsheet[key][colKey] = Number(spreadsheet[key][colKey]);
            }
            if (colName === 'sortingNumber') {
              spreadsheet[key][colKey] = Number(spreadsheet[key][colKey]);
            }
            if (colName === 'active') {
              if (spreadsheet[key][colKey].toLowerCase() === 'waar') {
                spreadsheet[key][colKey] = true;
                totalActive = totalActive + 1;
              } else {
                spreadsheet[key][colKey] = false;
              }
            }
            rowObj[colName] = spreadsheet[key][colKey];
          }
        });
        importedData.push(rowObj);
      });
      if (totalActive > this.maxTotalVehicle) {
        const dialogRef = this.dialog.open(WarningDialog, {
          width: '600px',
          data: {
            dialogType: 'importFailedCars',
            optionalData: {
              maxTotalVehicles: this.maxTotalVehicle,
            },
          },
        });
        dialogRef.afterClosed().subscribe((result) => {});
        return;
      }
      this.importedData.next(importedData);
      this.fileChangeListener();
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async exportTableToExcel() {
    const vehiclesRef = this.db.collection<Vehicle>(
      `organisations/${this.organisationId}/vehicles`
    );
    const vehiclesArray = [];
    const vehiclesObservable = await vehiclesRef.get();
    await vehiclesObservable.forEach((vehicles) => {
      vehicles.forEach((vehicleDoc) => {
        const vehicle = vehicleDoc.data() as Vehicle;
        const exportVehicleObj = {};
        exportVehicleObj['Naam'] = vehicle.name ? vehicle.name : '';
        exportVehicleObj['Actief'] = vehicle.active ? 'WAAR' : 'ONWAAR';
        exportVehicleObj['Zitplaatsen'] = vehicle.seats ? vehicle.seats : '';
        exportVehicleObj['Volgordenummer'] = vehicle.calendarStyle.sortingNumber
          ? vehicle.calendarStyle.sortingNumber
          : '';
        exportVehicleObj['Kilometerstand'] = vehicle.currentMileage
          ? vehicle.currentMileage
          : '';
        exportVehicleObj['Auto kleur'] = vehicle.calendarStyle.color
          ? vehicle.calendarStyle.color
          : '';
        exportVehicleObj['Rit leeg kleur'] = vehicle.calendarStyle
          .backgroundColorEmpty
          ? vehicle.calendarStyle.backgroundColorEmpty
          : '';
        exportVehicleObj['Rit vol kleur'] = vehicle.calendarStyle
          .backgroundColorFull
          ? vehicle.calendarStyle.backgroundColorFull
          : '';
        exportVehicleObj['Geen chauffeur kleur'] = vehicle.calendarStyle
          .backgroundColorNoDriver
          ? vehicle.calendarStyle.backgroundColorNoDriver
          : '';
        vehiclesArray.push(exportVehicleObj);
      });
    });
    if (vehiclesArray.length > 0) {
      vehiclesArray.sort(function (a, b) {
        if (a.Naam.toLowerCase() < b.Naam.toLowerCase()) {
          return -1;
        }
        if (a.Naam.toLowerCase() > b.Naam.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      console.log(`vehihcles: `, vehiclesArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vehiclesArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vehicles');

      // /* save to file */
      XLSX.writeFile(wb, 'voertuigen.xlsx');
    } else {
      // Nothing to export
      this._snackBar.open('Er zijn geen voertuigen om te exporteren', 'X', {
        duration: 5000,
      });
    }
  }

  // Your applications input change listener for the CSV File
  fileChangeListener() {
    this.vehicleCollection = this.db.collection<Vehicle>(
      `organisations/${this.organisationId}/vehicles`
    );
    this.vehicles = this.vehicleCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Vehicle;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.vehicles.subscribe((value) => {
      this.totalVehicles = value.length;
    });
    this.importedData.subscribe((val) => {});
    try {
      const combinedObservable = combineLatest(
        this.vehicles,
        this.importedData
      );
      combinedObservable
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((res: [Vehicle[], Array<any>]) => {
          const existingVehicles = res[0];
          const importedVehicles = res[1];
          if (importedVehicles === null) {
            return;
          }
          // check for existingVolunteers with importedVolunteers' data, update their info.
          // create new entry for all newly imported volunteers, process account creation in functions trigger.
          importedVehicles.forEach(async (vehicleData) => {
            let seats;
            let name;
            // tslint:disable-next-line: prefer-const
            let calendarStyle: any = {};
            let currentMileage;
            let active = true;
            // this section looks for a recognized column title and matches it with the correct userData
            if (vehicleData.name) {
              name = vehicleData.name;
            }
            if (vehicleData.seats) {
              seats = +vehicleData.seats;
            }
            if (vehicleData.color) {
              calendarStyle.color = vehicleData.color;
            }
            if (vehicleData.currentMileage) {
              currentMileage = vehicleData.currentMileage;
            }
            if (vehicleData.sortingNumber) {
              calendarStyle.sortingNumber = vehicleData.sortingNumber;
            }
            if (vehicleData.backgroundColorEmpty) {
              calendarStyle.backgroundColorEmpty =
                vehicleData.backgroundColorEmpty;
            }
            if (vehicleData.backgroundColorFull) {
              calendarStyle.backgroundColorFull =
                vehicleData.backgroundColorFull;
            }
            if (vehicleData.backgroundColorNoDriver) {
              calendarStyle.backgroundColorNoDriver =
                vehicleData.backgroundColorNoDriver;
            }
            if (vehicleData.active === false) {
              active = false;
            }
            const importData: Vehicle = {
              seats,
              name,
              calendarStyle,
              active,
              currentMileage,
            };
            const overwriteImportData: Vehicle = { active, calendarStyle };
            //
            const existingData = existingVehicles.find((element) => {
              return element.name === importData.name;
            });

            if (existingData) {
              // update existing doc
              await this.vehicleCollection
                .doc(existingData.id)
                .set(overwriteImportData, { merge: true });
            } else {
              // create new doc
              await this.vehicleCollection.add(importData);
            }
            this.importedData.next(null);
            this.uploader.nativeElement.value = null;
          });

          this._snackBar.open('De voertuigen zijn geimporteerd', 'X', {
            duration: 5000,
          });
        });
    } catch (err) {
      console.error(err);
    }
  }
}
