<h1 mat-dialog-title>{{ isNew ? 'Nieuw voertuig' : 'Voertuig bewerken' }}</h1>
<mat-dialog-content>
  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="vehicleForm"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Voertuignaam</mat-label>
      <input
        placeholder="Voertuignaam"
        formControlName="name"
        autocomplete="off"
        type="text"
        matInput
        required
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Omschrijving</mat-label>
      <input
        placeholder="Omschrijving"
        type="text"
        formControlName="description"
        matInput
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Aantal zitplaatsen</mat-label>
      <input
        type="number"
        placeholder="Zitplaatsen"
        formControlName="seats"
        matInput
        required
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Auto kleur</mat-label>
      <input
        matInput
        placeholder="#ffffff"
        readonly="true"
        formControlName="color"
        [value]="color"
        required
        #ignoredInput
        [cpIgnoredElements]="[ignoredButton, ignoredInput]"
        [(cpToggle)]="toggle"
        [(colorPicker)]="color"
        [cpAlphaChannel]="'disabled'"
        [cpOKButton]="true"
        [cpPosition]="'top'"
        [cpCancelButton]="true"
        [cpCancelButtonClass]="'mat-stroked-button'"
        [cpOKButtonClass]="'mat-stroked-button'"
        [cpCancelButtonText]="'Annuleren'"
      />
      <button
        matSuffix
        mat-icon-button
        #ignoredButton
        type="button"
        (click)="toggle = !toggle"
      >
        <mat-icon
          [ngStyle]="{
            color: color
          }"
          >circle
        </mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Volgordenummer</mat-label>
      <input
        type="text"
        placeholder="Volgorde Nummer"
        formControlName="sortingNumber"
        matInput
        required
      />
      <mat-error *ngIf="vehicleForm.controls.sortingNumber.errors?.required">
        Dit veld is verplicht
      </mat-error>
      <mat-error *ngIf="vehicleForm.controls.sortingNumber.errors?.numberInput">
        Je mag hier alleen hele getallen invullen
      </mat-error>
      <mat-error
        *ngIf="vehicleForm.controls.sortingNumber.errors?.orderValidator"
      >
        Dit volgordenummer is al in gebruik
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Rit leeg kleur</mat-label>
      <input
        matInput
        placeholder="#efefef"
        readonly="true"
        formControlName="backgroundColorEmpty"
        [value]="backgroundColorEmpty"
        #ignoredInput
        [cpIgnoredElements]="[ignoredButton, ignoredInput]"
        [(cpToggle)]="toggle2"
        [(colorPicker)]="backgroundColorEmpty"
        [cpAlphaChannel]="'disabled'"
        [cpOKButton]="true"
        [cpPosition]="'top'"
        [cpCancelButton]="true"
        [cpCancelButtonClass]="'mat-stroked-button'"
        [cpOKButtonClass]="'mat-stroked-button'"
        [cpCancelButtonText]="'Annuleren'"
      />
      <button
        matSuffix
        mat-icon-button
        #ignoredButton
        type="button"
        (click)="toggle2 = !toggle2"
      >
        <mat-icon
          [ngStyle]="{
            color: backgroundColorEmpty
          }"
          >circle
        </mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Rit vol kleur</mat-label>
      <input
        matInput
        placeholder="#efefef"
        readonly="true"
        formControlName="backgroundColorFull"
        [value]="backgroundColorFull"
        #ignoredInput
        [cpIgnoredElements]="[ignoredButton, ignoredInput]"
        [(cpToggle)]="toggle3"
        [(colorPicker)]="backgroundColorFull"
        [cpAlphaChannel]="'disabled'"
        [cpOKButton]="true"
        [cpPosition]="'top'"
        [cpCancelButton]="true"
        [cpCancelButtonClass]="'mat-stroked-button'"
        [cpOKButtonClass]="'mat-stroked-button'"
        [cpCancelButtonText]="'Annuleren'"
      />
      <button
        matSuffix
        mat-icon-button
        #ignoredButton
        type="button"
        (click)="toggle3 = !toggle3"
      >
        <mat-icon
          [ngStyle]="{
            color: backgroundColorFull
          }"
          >circle
        </mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field *ngIf="showDriverPlanning && !ignoreDriverStatus">
      <mat-label>Geen chauffeur kleur</mat-label>
      <input
        matInput
        placeholder="#efefef"
        readonly="true"
        formControlName="backgroundColorNoDriver"
        [value]="backgroundColorNoDriver"
        #ignoredInput
        [cpIgnoredElements]="[ignoredButton, ignoredInput]"
        [(cpToggle)]="toggle4"
        [(colorPicker)]="backgroundColorNoDriver"
        [cpAlphaChannel]="'disabled'"
        [cpOKButton]="true"
        [cpPosition]="'top'"
        [cpCancelButton]="true"
        [cpCancelButtonClass]="'mat-stroked-button'"
        [cpOKButtonClass]="'mat-stroked-button'"
        [cpCancelButtonText]="'Annuleren'"
      />
      <button
        matSuffix
        mat-icon-button
        #ignoredButton
        type="button"
        (click)="toggle4 = !toggle4"
      >
        <mat-icon
          [ngStyle]="{
            color: backgroundColorNoDriver
          }"
          >circle
        </mat-icon>
      </button>
    </mat-form-field>
    <mat-slide-toggle
      [disabled]="totalActive >= this.maxTotalVehicle && !isActive"
      formControlName="active"
    >
      Actief
    </mat-slide-toggle>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    type="button"
    (click)="close()"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    type="submit"
    (click)="onSubmit()"
    [disabled]="vehicleForm.invalid"
  >
    Opslaan
  </button>
</mat-dialog-actions>
