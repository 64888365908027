<h1 mat-dialog-title>{{ isNew ? 'Nieuw kenmerk' : 'Kenmerk bewerken' }}</h1>
<mat-dialog-content>
  <form
    (ngSubmit)="onSubmit()"
    [formGroup]="characteristicForm"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Kenmerknaam</mat-label>
      <input
        placeholder="Kenmerknaam"
        formControlName="name"
        autocomplete="off"
        type="text"
        matInput
        required
        (keydown.space)="$event.preventDefault()"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Omschrijving</mat-label>
      <input
        placeholder="Omschrijving"
        type="text"
        formControlName="description"
        matInput
      />
    </mat-form-field>
    <mat-slide-toggle formControlName="active"> Actief </mat-slide-toggle>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    type="button"
    (click)="close()"
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    type="submit"
    [disabled]="characteristicForm.invalid"
    (click)="onSubmit()"
  >
    Opslaan
  </button>
</mat-dialog-actions>
