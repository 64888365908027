import * as i0 from '@angular/core';
import { Injectable, Component, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
class NgxCSVParserError {}
class NgxCsvParser {
  constructor() {
    this.defaultCSVParserConfig = {
      header: true,
      delimiter: ',',
      encoding: 'utf8'
    };
  }
  parse(csvFile, config) {
    config = {
      ...this.defaultCSVParserConfig,
      ...config
    };
    const ngxCSVParserObserver = new Observable(observer => {
      try {
        let csvRecords = null;
        if (this.isCSVFile(csvFile)) {
          const reader = new FileReader();
          reader.readAsText(csvFile, config.encoding);
          reader.onload = () => {
            const csvData = reader.result.trim();
            if (csvData) {
              const csvRecordsArray = this.csvStringToArray(csvData, config.delimiter);
              const headersRow = this.getHeaderArray(csvRecordsArray);
              csvRecords = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length, config);
              observer.next(csvRecords);
            } else {
              observer.next([]);
            }
            observer.complete();
          };
          reader.onerror = () => {
            this.badCSVDataFormatErrorHandler(observer);
          };
        } else {
          this.notCSVFileErrorHandler(observer);
        }
      } catch (error) {
        this.unknownCSVParserErrorHandler(observer);
      }
    });
    return ngxCSVParserObserver;
  }
  csvStringToArray(csvDataString, delimiter) {
    const regexPattern = new RegExp(`(\\${delimiter}|\\r?\\n|\\r|^)(?:\"((?:\\\\.|\"\"|[^\\\\\"])*)\"|([^\\${delimiter}\"\\r\\n]*))`, 'gi');
    let matchedPatternArray = regexPattern.exec(csvDataString);
    const resultCSV = [[]];
    while (matchedPatternArray) {
      if (matchedPatternArray[1].length && matchedPatternArray[1] !== delimiter) {
        resultCSV.push([]);
      }
      const cleanValue = matchedPatternArray[2] ? matchedPatternArray[2].replace(new RegExp('[\\\\"](.)', 'g'), '$1') : matchedPatternArray[3];
      resultCSV[resultCSV.length - 1].push(cleanValue);
      matchedPatternArray = regexPattern.exec(csvDataString);
    }
    return resultCSV;
  }
  getDataRecordsArrayFromCSVFile(csvRecordsArray, headerLength, config) {
    const dataArr = [];
    const headersArray = csvRecordsArray[0];
    const startingRowToParseData = config.header ? 1 : 0;
    for (let i = startingRowToParseData; i < csvRecordsArray.length; i++) {
      const data = csvRecordsArray[i];
      if (data.length === headerLength && config.header) {
        const csvRecord = {};
        for (let j = 0; j < data.length; j++) {
          if (data[j] === undefined || data[j] === null) {
            csvRecord[headersArray[j]] = '';
          } else {
            csvRecord[headersArray[j]] = data[j].trim();
          }
        }
        dataArr.push(csvRecord);
      } else {
        dataArr.push(data);
      }
    }
    return dataArr;
  }
  isCSVFile(file) {
    return file.name.toLowerCase().endsWith('.csv');
  }
  getHeaderArray(csvRecordsArr) {
    const headers = csvRecordsArr[0];
    const headerArray = [];
    for (const header of headers) {
      headerArray.push(header);
    }
    return headerArray;
  }
  notCSVFileErrorHandler(observer) {
    const ngcCSVParserError = this.errorBuilder('NOT_A_CSV_FILE', 'Selected file is not a csv File Type.', 2);
    observer.error(ngcCSVParserError);
  }
  unknownCSVParserErrorHandler(observer) {
    const ngcCSVParserError = this.errorBuilder('UNKNOWN_ERROR', 'Unknown error. Please refer to official documentation for library usage.', 404);
    observer.error(ngcCSVParserError);
  }
  badCSVDataFormatErrorHandler(observer) {
    const ngcCSVParserError = this.errorBuilder('BAD_CSV_DATA_FORMAT', 'Unable to parse CSV File.', 1);
    observer.error(ngcCSVParserError);
  }
  errorBuilder(type, message, code) {
    const ngcCSVParserError = new NgxCSVParserError();
    ngcCSVParserError.type = type;
    ngcCSVParserError.message = message;
    ngcCSVParserError.code = code;
    return ngcCSVParserError;
  }
  static {
    this.ɵfac = function NgxCsvParser_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxCsvParser)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxCsvParser,
      factory: NgxCsvParser.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCsvParser, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class NgxCsvParserComponent {
  constructor() {}
  ngOnInit() {}
  static {
    this.ɵfac = function NgxCsvParserComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxCsvParserComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxCsvParserComponent,
      selectors: [["lib-ngx-csv-parser"]],
      decls: 2,
      vars: 0,
      template: function NgxCsvParserComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "p");
          i0.ɵɵtext(1, " ngx-csv-parser works! ");
          i0.ɵɵelementEnd();
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCsvParserComponent, [{
    type: Component,
    args: [{
      selector: 'lib-ngx-csv-parser',
      template: `
    <p>
      ngx-csv-parser works!
    </p>
  `
    }]
  }], function () {
    return [];
  }, null);
})();
class NgxCsvParserModule {
  static {
    this.ɵfac = function NgxCsvParserModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxCsvParserModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxCsvParserModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [NgxCsvParser]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxCsvParserModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxCsvParserComponent],
      imports: [],
      providers: [NgxCsvParser],
      exports: [NgxCsvParserComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-csv-parser
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxCSVParserError, NgxCsvParser, NgxCsvParserComponent, NgxCsvParserModule };
