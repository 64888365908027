<h1 mat-dialog-title>Mijn ritten</h1>
<div mat-dialog-content>
  <div class="wrapper">
    <h4>Klik op een rit waarvoor jij bent ingepland om de details te zien</h4>
    <mat-selection-list role="list" [multiple]="false">
      <mat-list-option
        class="ride-item"
        *ngFor="let ride of rides"
        (click)="openRideDetails(ride)"
        (keyup.enter)="openRideDetails(ride)"
        [ngClass]="{
          upcoming: ride.upcoming,
          belongsToDaypart: ride.belongsToDaypart,
          finished: ride.finished
        }"
      >
        <div class="startTime">
          <div class="driver-planned-dayparts">
            <div class="planned-rides-driver">
              <h5>
                {{ ride.title }}
              </h5>
              <div class="room-bottom">
                <h5>
                  Van:
                  {{ ride.start | date: 'H:mm' }}
                </h5>
                <h5>
                  Tot:
                  {{ ride.end | date: 'H:mm' }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </mat-list-option>
      <p *ngIf="rides?.length == 0 && loading === false">
        Je bent nog nergens voor ingepland
      </p>
      <p *ngIf="rides?.length == 0 && loading === true">
        We zijn bezig met de het rooster updaten
      </p>
    </mat-selection-list>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close] color="primary">
    ANNULEREN
  </button>
</div>
