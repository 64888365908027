import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable, Subject, ReplaySubject, combineLatest } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { ImportExportComponent } from '../dialogs/import-export/import-export.component';
import { NgxCsvParser } from 'ngx-csv-parser';
import { CreateEditCharacteristicComponent } from './dialogs/create-edit-characteristics/create-edit-characteristics.component';
import { Characteristic, Setting } from './../../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-characteristics',
  templateUrl: './characteristics.component.html',
  styleUrls: ['./characteristics.component.scss'],
})
export class CharacteristicsComponent implements OnInit {
  @ViewChild('TABLE', { static: false }) table: ElementRef;
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  characteristicCollection: AngularFirestoreCollection<Characteristic>;
  characteristics: Observable<Characteristic[]>;
  public filteredCharacteristics: ReplaySubject<Characteristic[]> =
    new ReplaySubject<Characteristic[]>(1);
  displayedColumns = ['name', 'description', 'button'];
  importedData: Subject<Characteristic[]> = new ReplaySubject<
    Characteristic[]
  >();
  totalCharacteristics: number;
  totalActive = 0;
  header = true;
  fileUploaded: File;
  worksheet: any;
  storeData: any;
  csvRecords: any[] = [];
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;

  organisationId = localStorage.getItem('orgId');
  // tslint:disable-next-line: variable-name
  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private ngxCsvParser: NgxCsvParser,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.settingDoc = this.db.doc<Setting>(
      `organisations/${this.organisationId}/settings/general`
    );
    this.setting = this.settingDoc.valueChanges();
    this.characteristicCollection = this.db.collection<Characteristic>(
      `organisations/${this.organisationId}/characteristics`,
      (ref) => ref.orderBy('name', 'asc')
    );
    this.characteristics = this.characteristicCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Characteristic;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.characteristics.subscribe((value) => {
      console.log('characteristics', value);
      let totalActive = 0;
      value.forEach((val) => {
        if (val.active === true) {
          totalActive = totalActive + 1;
          console.log('totalActive', totalActive);
        }
      });
      this.totalActive = totalActive;
      this.totalCharacteristics = value.length;
      console.log('this.totalActive', this.totalActive);
    });
    const combinedObservable = combineLatest(
      this.characteristics,
      this.searchQueryChanged
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const characteristics = values[0];
        const searchQuery = values[1];
        let filteredCharacteristics = characteristics.filter((item) =>
          this.checkFilters(item)
        );
        console.log('filteredCharacteristics', filteredCharacteristics);
        this.totalCharacteristics = filteredCharacteristics.length;
        this.filteredCharacteristics.next(filteredCharacteristics);
      });
    this.searchQueryChanged.next('');
  }
  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    }
  }

  checkFilters(characteristic) {
    let passesSearchFilter = true;
    if (this.searchQuery) {
      passesSearchFilter = false;
        if (characteristic.name && characteristic.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
        }
        if (characteristic.description && characteristic.description.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
        }    
    }

    if (passesSearchFilter) {
      return characteristic;
    }
  }

  openImportExport() {
    const dialogRef = this.dialog.open(ImportExportComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result.type === 'Export') {
        this.exportTableToExcel();
      }
      if (result.type === 'Import') {
        this.uploader.nativeElement.click();
      }
    });
  }

  editCharacteristic(characteristic?) {
    const dialogRef = this.dialog.open(CreateEditCharacteristicComponent, {
      width: '300px',
      data: { characteristic, totalActive: this.totalActive },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  newCharacteristic() {
    const dialogRef = this.dialog.open(CreateEditCharacteristicComponent, {
      width: '300px',
      data: { totalActive: this.totalActive },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  uploadedFile(event) {
    if (this.uploader.nativeElement.value === null) {
      return;
    } else {
      console.log('event', event);
      console.log('uploading file');
      this.fileUploaded = event.target.files[0];
      this.readExcel();
    }
  }
  readExcel() {
    const readFile = new FileReader();
    const spreadsheet = {};
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];
      Object.keys(this.worksheet).forEach((key) => {
        if (key !== '!ref' && key !== '!margins') {
          const rowId = key.match(/\d+/g).toString();
          const colId = key.match(/[a-zA-Z]+/g).toString();
          if (!spreadsheet[rowId]) {
            spreadsheet[rowId] = {};
          }
          spreadsheet[rowId][colId] = this.worksheet[key].w;
        }
      });
      const columnNames = spreadsheet[1];
      Object.keys(columnNames).forEach((key) => {
        key = key;
        const val = columnNames[key].toLowerCase();
        switch (val) {
          default:
            delete columnNames[key];
            break;
          case 'naam':
            columnNames[key] = 'name';
            break;
          case 'omschrijving':
            columnNames[key] = 'description';
            break;
          case 'actief':
            columnNames[key] = 'active';
            break;
        }
      });

      delete spreadsheet[1];
      const importedData = [];
      let totalActive = 0;
      Object.keys(spreadsheet).forEach((key) => {
        const rowObj = {};
        Object.keys(spreadsheet[key]).forEach((colKey) => {
          const colName = columnNames[colKey];
          if (colName) {
            if (colName === 'name') {
              spreadsheet[key][colKey] = spreadsheet[key][colKey];
            }
            if (colName === 'description') {
              spreadsheet[key][colKey] = spreadsheet[key][colKey];
            }
            if (colName === 'active') {
              if (spreadsheet[key][colKey].toLowerCase() === 'waar') {
                spreadsheet[key][colKey] = true;
                totalActive = totalActive + 1;
              } else {
                spreadsheet[key][colKey] = false;
              }
            }
            rowObj[colName] = spreadsheet[key][colKey];
          }
        });
        importedData.push(rowObj);
      });
      this.importedData.next(importedData);
      this.fileChangeListener();
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async exportTableToExcel() {
    const characteristicsRef = this.db.collection<Characteristic>(
      `organisations/${this.organisationId}/characteristics`
    );
    const characteristicsArray = [];
    const characteristicsObservable = await characteristicsRef.get();
    await characteristicsObservable.forEach((characteristics) => {
      characteristics.forEach((characteristicDoc) => {
        const characteristic = characteristicDoc.data() as Characteristic;
        const exportCharacteristicObj = {};
        exportCharacteristicObj['Naam'] = characteristic.name
          ? characteristic.name
          : '';
        exportCharacteristicObj['Actief'] = characteristic.active
          ? 'WAAR'
          : 'ONWAAR';
        exportCharacteristicObj['Omschrijving'] = characteristic.description
          ? characteristic.description
          : '';
        characteristicsArray.push(exportCharacteristicObj);
      });
    });
    if (characteristicsArray.length > 0) {
      console.log('vouchers', characteristicsArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(characteristicsArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Characteristics');

      // /* save to file */
      XLSX.writeFile(wb, 'kenmerken.xlsx');
    } else {
      // Nothing to export
      this._snackBar.open('Er zijn geen kenmerken om te exporteren', 'X', {
        duration: 5000,
      });
    }
  }

  // Your applications input change listener for the CSV File
  fileChangeListener() {
    this.characteristicCollection = this.db.collection<Characteristic>(
      `organisations/${this.organisationId}/characteristics`
    );
    this.characteristics = this.characteristicCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Characteristic;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.characteristics.subscribe((value) => {
      console.log('characteristics', value);
      this.totalCharacteristics = value.length;
    });
    this.importedData.subscribe((val) => {
      console.log('this.importedData', val);
    });
    try {
      const combinedObservable = combineLatest(
        this.characteristics,
        this.importedData
      );
      combinedObservable
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((res: [Characteristic[], Array<any>]) => {
          console.log('res', res);
          const existingCharacteristics = res[0];
          const importedCharacteristics = res[1];
          if (importedCharacteristics === null) {
            return;
          }
          // check for existingVolunteers with importedVolunteers' data, update their info.
          // create new entry for all newly imported volunteers, process account creation in functions trigger.
          importedCharacteristics.forEach(async (characteristicData) => {
            console.log('characteristicData', characteristicData);
            let name;
            // tslint:disable-next-line: prefer-const
            let description;
            let active;
            // this section looks for a recognized column title and matches it with the correct userData
            if (characteristicData.name) {
              name = characteristicData.name;
            }
            if (characteristicData.description) {
              description = characteristicData.description;
            }
            if (characteristicData.active) {
              active = characteristicData.active;
            } else {
              active = false;
            }
            const importData: Characteristic = {
              name,
              description,
              active,
            };
            const existingData = existingCharacteristics.find((element) => {
              return element.name === importData.name;
            });

            console.log('existingData', existingData);
            if (existingData) {
              // update existing doc

              await this.characteristicCollection
                .doc(existingData.id)
                .set(importData, { merge: true });
            } else {
              // create new doc
              await this.characteristicCollection.add(importData);
            }
            this.importedData.next(null);
            this.uploader.nativeElement.value = null;
          });

          this._snackBar.open('De kenmerken zijn geimporteerd', 'X', {
            duration: 5000,
          });
        });
    } catch (err) {
      console.error(err);
    }
  }
}
