import { MatChipSelectionChange } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';
import mime from 'mime';
import moment from 'moment';
import { AccountType } from 'src/app/enums';
import { PublicSettings, User } from 'src/app/interfaces';
import tinycolor from 'tinycolor2';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

export async function saveOrgInfoLocal(orgId: string) {
  let shouldReload = false;
  const publicSettings = (
    await getDoc(doc(getFirestore(), `organisations/${orgId}/settings/public`))
  ).data() as PublicSettings;
  if (!publicSettings) {
    return shouldReload;
  }

  if (
    publicSettings.primaryColor !== localStorage.getItem('primaryColor') ||
    publicSettings.accentColor !== localStorage.getItem('accentColor') ||
    publicSettings.banner !== localStorage.getItem('banner') ||
    publicSettings.logo !== localStorage.getItem('logo') ||
    (publicSettings.name &&
      publicSettings.name !== localStorage.getItem('orgName'))
  ) {
    console.log('orgId:', orgId);
    console.log(
      publicSettings.primaryColor,
      localStorage.getItem('primaryColor')
    );
    console.log(
      publicSettings.primaryColor !== localStorage.getItem('primaryColor')
    );
    console.log(
      publicSettings.accentColor !== localStorage.getItem('accentColor')
    );
    console.log(publicSettings.banner !== localStorage.getItem('banner'));
    console.log(publicSettings.logo !== localStorage.getItem('logo'));
    shouldReload = true;
  }
  localStorage.setItem('primaryColor', publicSettings.primaryColor);
  localStorage.setItem('accentColor', publicSettings.accentColor);
  localStorage.setItem('banner', <string>publicSettings.banner);
  localStorage.setItem('logo', <string>publicSettings.logo);
  localStorage.setItem('orgName', publicSettings.name ?? 'Buurtvervoer');

  const favicon: HTMLLinkElement = document.querySelector('#favicon');
  favicon.href = <string>publicSettings.favicon;

  return shouldReload;
}

export function getFileExtension(file: File) {
  const fname = file.name;
  return fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2);
}

export async function getFileFromUrl(url: string | URL, fileName: string) {
  const faviconBlob = await (await fetch(new URL(url))).blob();

  return new File(
    [faviconBlob],
    `${fileName}.${mime.getExtension(faviconBlob.type)}`,
    { type: faviconBlob.type }
  );
}

export async function logExport(exportType: string) {
  const user = getAuth().currentUser!;
  const organisationId = localStorage.getItem('orgId') as string;
  await addDoc(
    collection(getFirestore(), `eventLogs/${organisationId}/exportedFiles`),
    {
      date: new Date(),
      doneByUser: user.email,
      doneByUid: user.uid,
      exportType,
    }
  );
}

export async function logSettingsChange(
  originalSettings: any,
  newSettings: any,
  originalPublicSettings: any,
  newPublicSettings: any
) {
  const user = getAuth().currentUser!;
  const organisationId = localStorage.getItem('orgId') as string;
  for (const key of Object.keys(newSettings)) {
    if (newSettings[key]._methodName === 'deleteField') {
      newSettings[key] = 'DELETED';
    }
  }
  for (const key of Object.keys(newPublicSettings)) {
    if (newPublicSettings[key]._methodName === 'deleteField') {
      newPublicSettings[key] = 'DELETED';
    }
  }
  const changes: any[] = getDifferences(newSettings, originalSettings);
  console.log('changes', changes);
  const publicChanges: any[] = getDifferences(
    newPublicSettings,
    originalPublicSettings
  );
  console.log('publicChanges', publicChanges);
  console.log('changedSettings', {
    date: new Date(),
    doneByUser: user.email,
    doneByUid: user.uid,
    changes,
    publicChanges,
  });
  if (changes.length > 0 || publicChanges.length > 0) {
    await addDoc(
      collection(getFirestore(), `eventLogs/${organisationId}/changedSettings`),
      {
        date: new Date(),
        doneByUser: user.email,
        doneByUid: user.uid,
        changes,
        publicChanges,
      }
    );
  }
}

function getDifferences(newObj: object, oldObj: object) {
  const changes = [];
  for (const [key, value] of Object.entries(newObj)) {
    // console.log(`${key}: ${value}`);
    let oldValue = oldObj[key];
    if (value instanceof Date && oldValue) {
      oldValue = oldValue.toDate();
    }
    if (typeof value === 'object' && value !== null) {
      console.log('objectEquals', objectEquals(value, oldValue));
      if (!objectEquals(value, oldValue)) {
        changes.push({
          from: oldValue ?? 'undefined',
          to: value ?? 'undefined',
          field: key,
        } as any);
      }
    } else if (oldValue != value) {
      changes.push({
        from: oldValue ?? 'undefined',
        to: value ?? 'undefined',
        field: key,
      } as any);
    }
  }
  return changes;
}

function objectEquals(x: object, y: object) {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== 'object') return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!objectEquals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
}

export function getDateString(date: Date) {
  return moment(date).format('YYYY-MM-DD');
}

export function openSnackbar(snackBar: MatSnackBar, text: string) {
  snackBar.open(text, 'X', {
    duration: 5000,
  });
}

export async function redirectIfNotSuperUser(
  firestore: firebase.default.firestore.Firestore,
  router: Router
) {
  const currentUserId = getAuth().currentUser?.uid;
  const currentUser = (
    await getDoc(doc(firestore, `/users/${currentUserId}`))
  ).data() as User;
  if (!currentUser.rights || currentUser.rights !== AccountType.Admin) {
    router.navigateByUrl('/planner');
  }
}

export async function redirectIfNotAdmin(
  firestore: firebase.default.firestore.Firestore,
  router: Router
) {
  const currentUserId = getAuth().currentUser?.uid;
  const organisationId = localStorage.getItem('orgId');
  const currentUser = (
    await getDoc(
      doc(firestore, `/organisations/${organisationId}/users/${currentUserId}`)
    )
  ).data() as User;
  if (currentUser?.accountType !== AccountType.Admin) {
    switch (currentUser?.accountType) {
      case AccountType.Planner:
        router.navigateByUrl('/planner');
        break;
      case AccountType.Driver:
        router.navigateByUrl('/availability');
        break;
    }
  }
}

export async function redirectIfDriver(
  firestore: firebase.default.firestore.Firestore,
  router: Router
) {
  const currentUserId = getAuth().currentUser?.uid;
  const organisationId = localStorage.getItem('orgId');
  const currentUser = (
    await getDoc(
      doc(firestore, `/organisations/${organisationId}/users/${currentUserId}`)
    )
  ).data() as User;
  if (currentUser?.accountType === AccountType.Driver) {
    router.navigateByUrl('/availability');
  }
}

export function updateTheme(colors: Color[], theme: string) {
  colors.forEach((color) => {
    // console.log(`--theme-${theme}-${color.name}`, color.hex);
    if (color.name === '40') {
      document.documentElement.style.setProperty(`--${theme}`, color.hex);
      document.documentElement.style.setProperty(
        `--${theme}-contrast`,
        color.darkContrast ? 'rgba(black, 0.87)' : 'white'
      );
    }
    document.documentElement.style.setProperty(
      `--theme-${theme}-${color.name}`,
      color.hex
    );
  });
}

export function computeColors(hex: string): Color[] {
  return [
    getColorObject(tinycolor(hex).darken(100), '0'),
    getColorObject(tinycolor(hex).darken(40), '10'),
    getColorObject(tinycolor(hex).darken(30), '20'),
    getColorObject(tinycolor(hex).darken(25), '25'),
    getColorObject(tinycolor(hex).darken(15), '30'),
    getColorObject(tinycolor(hex).darken(10), '35'),
    getColorObject(tinycolor(hex), '40'),
    getColorObject(tinycolor(hex).lighten(5), '50'),
    getColorObject(tinycolor(hex).lighten(10), '60'),
    getColorObject(tinycolor(hex).lighten(20), '70'),
    getColorObject(tinycolor(hex).lighten(30), '80'),
    getColorObject(tinycolor(hex).lighten(40), '90'),
    getColorObject(tinycolor(hex).lighten(40), '95'),
    getColorObject(tinycolor(hex).lighten(46), '98'),
    getColorObject(tinycolor(hex).lighten(48), '99'),
    getColorObject(tinycolor(hex).lighten(100), '100'),
  ];
}

function getColorObject(value, name): Color {
  const c = tinycolor(value);
  return {
    name: name,
    hex: c.toHexString(),
    darkContrast: c.isLight(),
  };
}
