<app-nav-bar></app-nav-bar>
<div
  class="users"
>
  <div
    class="header"
  >
    <div
      class="h-title"
    >
      <mat-icon>build</mat-icon>
      <span>Admin tools</span>
    </div>
  </div>
  <mat-card class="room-below-card">
    <button
      mat-flat-button
      type="button"
      (click)="newOrganisation()"
      class="tool-button"
    >
      <mat-icon>add</mat-icon>
      Nieuwe omgeving aanmaken
    </button>
    <button
      mat-flat-button
      type="button"
      (click)="deleteRides()"
      class="tool-button"
    >
      <mat-icon>delete</mat-icon>
      Ritten binnen een range verwijderen
    </button>
    <button
      mat-flat-button
      type="button"
      (click)="adjustPassword()"
      class="tool-button"
    >
      <mat-icon>manage_accounts</mat-icon>
      Wachtwoord van een account veranderen
    </button>
    <button
      mat-flat-button
      type="button"
      class="tool-button"
      routerLink="/admin-tools/setup-payment-methods"
    >
      <mat-icon>payments</mat-icon>
      Betaalmethodes instellen
    </button>
    <button
      mat-flat-button
      type="button"
      (click)="generateSepas()"
      class="tool-button"
    >
      <mat-icon>attach_money</mat-icon>
      SEPA genereren voor Buurtvervoerders
    </button>
    <button
      mat-flat-button
      type="button"
      (click)="addUserToOrgs()"
      class="tool-button"
    >
      <mat-icon>group_add</mat-icon>
      Superusers organisaties beheren
    </button>
    <button
      mat-flat-button
      type="button"
      (click)="managePaidFunctionalities()"
      class="tool-button"
    >
      <mat-icon>toggle_on</mat-icon>
      Betaalde functionaliteiten beheren
    </button>
  </mat-card>
</div>
