<h1 mat-dialog-title>{{ isNew ? 'Nieuw telefoonnummer' : 'Telefoonnummer bewerken' }}</h1>
<mat-dialog-content>
  <form
    (ngSubmit)="onSubmit()"
    autocomplete="off"
    [formGroup]="phoneForm"
  >
    <mat-form-field>
      <mat-label>Volgorde</mat-label>
      <input
        type="text"
        placeholder="Volgorde"
        formControlName="order"
        matInput
        required
      />
      <mat-error *ngIf="phoneForm.controls.order.errors?.required">
        Dit veld is verplicht
      </mat-error>
      <mat-error *ngIf="phoneForm.controls.order.errors?.numberInput">
        Je mag hier alleen hele getallen invullen
      </mat-error>
      <mat-error *ngIf="phoneForm.controls.order.errors?.orderValidator">
        Dit volgordenummer is al in gebruik 
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Naam</mat-label>
      <input placeholder="Naam" type="text" formControlName="name" matInput required />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Telefoonnummer</mat-label>
      <input
        placeholder="Telefoonnummer"
        type="tel"
        formControlName="phone"
        matInput
        required
      />
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button type="button" (click)="close()">Annuleren</button>
  <button
    mat-flat-button
    type="submit"
    [disabled]="phoneForm.invalid"
    (click)="onSubmit()"
  >
    Opslaan
  </button>
</mat-dialog-actions>