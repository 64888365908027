import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  doc,
  getDoc,
  query,
  where,
  collectionGroup,
  getDocs,
  collection,
} from 'firebase/firestore';
import moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';
import { Organisation, PaymentMethodsConfig } from 'src/app/interfaces';
import { CreateSepaComponent } from 'src/app/users/dialogs/create-sepa/create-sepa.component';
import { createSepaData } from 'src/app/users/dialogs/create-sepa/sepa-helpers';
import { SepaCustomer } from 'src/app/users/dialogs/create-sepa/sepa-interface';
import { getDateString } from 'src/app/globals';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import sha1 from 'crypto-js/sha1';
import sha256 from 'crypto-js/sha256';

@Component({
  selector: 'app-create-sepa-for-orgs',
  templateUrl: './create-sepa-for-orgs.component.html',
  styleUrls: ['./create-sepa-for-orgs.component.scss'],
})
export class CreateSepaForOrgsComponent implements OnInit {
  loading: boolean = false;
  loadingDialog: boolean = true;
  sepaForm: UntypedFormGroup;
  months: any = [];
  organisations = [];

  constructor(
    public db: AngularFirestore,
    public dialogRef: MatDialogRef<CreateSepaComponent>,
    private _FileSaverService: FileSaverService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.sepaForm = this.fb.group({
      month: ['', Validators.required],
    });
    await this.getOrganisations();
    this.getMonths();
    this.loadingDialog = false;
  }

  async getOrganisations() {
    const organisations = (
      await getDocs(collection(this.db.firestore, 'organisations'))
    ).docs;
    let index: number = 0;
    organisations.forEach((organisation) => {
      const docData = {
        ...organisation.data(),
        id: organisation.id,
      } as Organisation;

      const pushObj = {
        name: docData.name,
        id: docData.id,
        formControl: `${docData.id}-${index}`,
      };

      this.sepaForm.addControl(
        pushObj.formControl,
        new UntypedFormControl(true)
      );

      this.organisations.push(pushObj);
      index++;
    });
  }

  getMonths() {
    moment.locale('nl');
    let monthsRequired = 12;
    for (let i = 1; i <= monthsRequired; i++) {
      const monthMoment = moment().add(-i, 'months');
      const monthName = monthMoment.format('MMMM');
      this.months.push({
        name: monthName.charAt(0).toUpperCase() + monthName.slice(1),
        period: monthMoment.format('YYYY-MM'),
      });
    }
  }

  async createSepa() {
    this.loading = true;
    const paymentMethodsConfigRef = doc(
      this.db.firestore,
      `payment-configs/wemaron`
    );
    const paymentMethodsConfig = (
      await getDoc(paymentMethodsConfigRef)
    ).data() as PaymentMethodsConfig;
    const dateNow = new Date();
    const paymentReferenceId = moment(dateNow).format('DD-MM-YYYY.HH:MM:SS');
    const creditorName = paymentMethodsConfig.creditor_name;
    const creditorCountry = paymentMethodsConfig.creditor_country;
    const creditorAddress = paymentMethodsConfig.creditor_address;
    const creditorMunicipality = paymentMethodsConfig.creditor_municipality;
    const creditorPostal = paymentMethodsConfig.creditor_postal;
    const creditorIban = paymentMethodsConfig.creditor_iban;
    const creditorId = paymentMethodsConfig.creditor_id;
    const creditorBic = paymentMethodsConfig.creditor_bic;
    const orgsSepaEarnings = await this.getSepaEarnings();
    if (orgsSepaEarnings.length > 0) {
      const customers: SepaCustomer[] = [];
      for (let i = 0, len = orgsSepaEarnings.length, text = ''; i < len; i++) {
        const org = orgsSepaEarnings[i];
        console.log('org', org);
        const orgPaymentMethodsConfig = (
          await getDoc(doc(this.db.firestore, `payment-configs/${org.id}`))
        ).data() as PaymentMethodsConfig;
        console.log('orgPaymentMethodsConfig', orgPaymentMethodsConfig);

        const amount =
          Math.round(
            ((org.totalTransactions * 0.75 + org.sepaGenerated * 2) * 1.21 +
              Number.EPSILON) *
              100
          ) / 100;
        const formattedDate = moment(dateNow).format('DDMMYYYY');
        const creditorMandate = orgPaymentMethodsConfig.creditor_name.substring(
          0,
          3
        );
        customers.push({
          name: orgPaymentMethodsConfig.creditor_name,
          iban: orgPaymentMethodsConfig.creditor_iban,
          mandateId: `${creditorMandate}${formattedDate}`,
          dateOfSignature: getDateString(dateNow),
          amount: amount,
          credits: 0,
        });
      }
      console.log('customers', customers);

      const sepaData = await createSepaData(
        paymentReferenceId,
        dateNow,
        customers,
        creditorName,
        creditorCountry,
        creditorAddress,
        creditorMunicipality,
        creditorPostal,
        creditorIban,
        creditorId,
        creditorBic
      );
      console.log('sepaData', sepaData);
      this.createXmlFile(
        `${paymentMethodsConfig.creditor_name}${paymentReferenceId}`,
        sepaData,
        `${paymentMethodsConfig.creditor_name}${paymentReferenceId}.xml`
      );
    } else {
      this.snackBar.open(
        'Er zijn geen betalingen mogelijk in deze periode',
        'X',
        {
          duration: 5000,
        }
      );
    }
    this.loading = false;
    this.dialogRef.close();
  }

  async getSepaEarnings() {
    try {
      const orgs = [];
      const orgsToAdd = [];
      this.organisations.forEach((organisation) => {
        if (this.sepaForm.controls[organisation.formControl].value) {
          orgsToAdd.push(organisation.id);
        }
      });

      const yearMonthDocId = this.sepaForm.value.month.period;
      const colGroup = collectionGroup(this.db.firestore, 'sepa-earnings');
      const q = query(colGroup, where('period', '==', yearMonthDocId));
      const sepaEarningsSnapshot = await getDocs(q);
      sepaEarningsSnapshot.forEach((doc) => {
        const orgId = doc.ref.parent.parent.id;
        if (orgsToAdd.find((orgObject) => orgObject == orgId)) {
          const docData = doc.data();
          console.log('doc.ref.path', doc.ref.path);
          const data = { ...docData, id: doc.ref.parent.parent.id };
          orgs.push(data);
        }
      });
      console.log('testOrgs', orgs);
      return orgs;
    } catch (error) {
      console.log(error);
    }
  }

  createXmlFile(paymentReferenceId: string, data: string, fileName: string) {
    const checkSumSha1 = sha1(data);
    const checkSumSha256 = sha256(data);
    this._FileSaverService.save(new Blob([data]), fileName);
    const checkSumString = `Checksum SHA-1: ${checkSumSha1}\nChecksum SHA-256: ${checkSumSha256}`;
    this._FileSaverService.save(
      new Blob([checkSumString]),
      `Checksum ${paymentReferenceId}.txt`
    );
  }
}
