<app-nav-bar></app-nav-bar>
<div class="users">
  <div class="header">
    <div class="h-title">
      <mat-icon>tune</mat-icon>
      <div>Kenmerken</div>
    </div>
    <div class="export-button">
      <button mat-raised-button (click)="openImportExport()">
        <mat-icon>import_export</mat-icon
        ><span>Importeren/Exporteren</span>
      </button>
    </div>
  </div>
  <mat-card
    class="room-below-card"
    *ngIf="characteristics; else loading"
  >
    <div class="title">
      <h3>
        Alle kenmerken <span>({{ totalCharacteristics }})</span>
      </h3>
      <div class="search">
        <mat-form-field class="mat-form-field-no-subscript">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label class="placeholder">Zoeken</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder="Zoeken"
            [(ngModel)]="searchQuery"
            (ngModelChange)="onFilterChange('search', $event)"
          />
        </mat-form-field>
      </div>
    </div>
    <div #TABLE>
      <mat-table
        mat-table
        id="tblData"
        #table
        [dataSource]="filteredCharacteristics"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Kenmerk</mat-header-cell>
          <mat-cell *matCellDef="let characteristic">
            <p class="text-truncate" *ngIf="characteristic.name">
              {{ characteristic.name }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>Omschrijving</mat-header-cell>
          <mat-cell *matCellDef="let characteristic">
            <p class="text-truncate" *ngIf="characteristic.description">
              {{ characteristic.description }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- button Column -->
        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let characteristic">
            <div class="buttons">
              <button
                mat-icon-button
                [disabled]="!characteristic.active"
                [matMenuTriggerFor]="moreMenu"
                aria-label="More"
                (click)="$event.stopPropagation()"
              >
                <mat-icon class="secondary-text">more_vert</mat-icon>
              </button>

              <mat-menu #moreMenu="matMenu">
                <button
                  mat-menu-item
                  (click)="editCharacteristic(characteristic)"
                >
                  <span>Bewerken</span>
                </button>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          [ngClass]="{ 'card-inactive': !characteristic.active }"
          *matRowDef="let characteristic; columns: displayedColumns"
          class="user"
          (click)="editCharacteristic(characteristic)"
          matRipple
        ></tr>
      </mat-table>
    </div>
  </mat-card>
  <ng-template #loading>
    <div id="loading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  <input
    hidden
    type="file"
    accept=".xlsx"
    #uploader
    (change)="uploadedFile($event)"
  />
</div>
<button mat-fab class="fab-fixed" (click)="newCharacteristic()">
  <mat-icon>add</mat-icon>
</button>
