<app-nav-bar></app-nav-bar>
<div class="users">
  <div class="header">
    <div class="header-internals">
      <div class="header-title">
        <mat-icon>people</mat-icon>
        <div>Deelnemers</div>
      </div>
      <div class="header-button-search">
        <button
          class="header-button sepa-button"
          mat-raised-button
          (click)="requestSepaCreation()"
          *ngIf="publicSettings?.sepaEnabled && shouldShowSepaButton"
        >
          <mat-icon>euro</mat-icon>
          Incasso aanmaken
        </button>

        <button
          class="header-button"
          mat-raised-button
          (click)="importExportUsers()"
        >
          <mat-icon>import_export</mat-icon>
          Exporteren/Importeren
        </button>
        <form [formGroup]="filterForm">
          <div class="search">
            <mat-form-field
              class="mat-form-field-no-subscript white-form-field secondary-color"
            >
              <mat-label class="placeholder">Zoeken</mat-label>
              <input
                matInput
                placeholder="Zoeken"
                autocomplete="off"
                formControlName="search"
                [(ngModel)]="searchQuery"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="clearSearch()"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
  <mat-card
    class="room-below-card"
    *ngIf="!loading"
  >
    <div class="title">
      <h3>
        Alle deelnemers <span>({{ filteredUsers.length }})</span>
      </h3>
      <h4>Filter</h4>
    </div>
    <form
      class="filters"
      [formGroup]="filterForm"
    >
      <mat-chip-listbox class="chips">
        <mat-chip-option
          class="chip primary-color"
          [selected]="this.filterForm.value.filter === filterOption.NoCards"
          (click)="setFilter(filterOption.NoCards)"
        >
          Zonder kaart
        </mat-chip-option>
        <mat-chip-option
          class="chip primary-color"
          [selected]="this.filterForm.value.filter === filterOption.NoPlan"
          (click)="setFilter(filterOption.NoPlan)"
        >
          Zonder betaalplan
        </mat-chip-option>
        <mat-chip-option
          class="chip primary-color"
          [selected]="
            this.filterForm.value.filter &&
            this.filterForm.value.filter !== filterOption.NoCards &&
            this.filterForm.value.filter !== filterOption.NoPlan
          "
          (selectionChange)="dropdownChipSelection($event)"
          [matMenuTriggerFor]="additionalFiltersMenu"
        >
          Meldingen
          <mat-icon class="trailing">arrow_drop_down</mat-icon>
        </mat-chip-option>

        <mat-menu #additionalFiltersMenu="matMenu">
          <button
            [class.active-list-item]="
              this.filterForm.value.filter === filterOption.WarningLowCredits
            "
            mat-menu-item
            (click)="setFilter(filterOption.WarningLowCredits)"
          >
            Te weinig credits
          </button>
          <button
            [class.active-list-item]="
              this.filterForm.value.filter === filterOption.WarningPaymentError
            "
            mat-menu-item
            (click)="setFilter(filterOption.WarningPaymentError)"
          >
            Fout tijdens betalen
          </button>
          <button
            *ngIf="publicSettings.sepaEnabled"
            [class.active-list-item]="
              this.filterForm.value.filter ===
              filterOption.WarningSendSepaMandate
            "
            mat-menu-item
            (click)="setFilter(filterOption.WarningSendSepaMandate)"
          >
            Verstuur SEPA machtiging
          </button>
          <button
            *ngIf="publicSettings.sepaEnabled"
            [class.active-list-item]="
              this.filterForm.value.filter ===
              filterOption.WarningUploadSepaMandate
            "
            mat-menu-item
            (click)="setFilter(filterOption.WarningUploadSepaMandate)"
          >
            Upload SEPA machtiging
          </button>
        </mat-menu>
      </mat-chip-listbox>
      <div>
        <button
          mat-icon-button
          (click)="
            this.filterForm.controls.showHidden.setValue(
              !this.filterForm.value.showHidden
            )
          "
          [matTooltip]="
            this.filterForm.value.showHidden
              ? 'Laat verstopte deelnemers niet meer zien'
              : 'Laat verstopte deelnemers zien'
          "
        >
          <mat-icon *ngIf="this.filterForm.value.showHidden">
            visibility_off
          </mat-icon>
          <mat-icon *ngIf="!this.filterForm.value.showHidden">
            visibility
          </mat-icon>
        </button>
      </div>
    </form>
    <div class="list-items">
      <div class="list-item-row headers">
        <div class="list-item-col">
          Naam
          <button
            mat-icon-button
            (click)="
              this.filterForm.controls.aToZ.setValue(
                !this.filterForm.value.aToZ
              )
            "
            matTooltip="Sorteren omdraaien"
          >
            <mat-icon *ngIf="this.filterForm.value.aToZ">
              arrow_upward
            </mat-icon>
            <mat-icon *ngIf="!this.filterForm.value.aToZ">
              arrow_downward
            </mat-icon>
          </button>
        </div>
        <div class="list-item-col">E-mail</div>
        <div 
          class="list-item-col hide-column"
          *ngIf="this.askBirthDay"
        >
          Geboortedatum
        </div>
        <div class="list-item-col">Telefoonnummer</div>
        <div class="list-item-col">Plan</div>
        <div class="list-item-col hide-column">Opmerkingen</div>
        <div class="list-item-col hide-column">Registratiedatum</div>
        <div class="list-item-col">Kaartnummer</div>
        <div class="list-item-col hide-column">Openstaande betaling</div>
        <div class="list-item-col"></div>
      </div>
      <cdk-virtual-scroll-viewport
        itemSize="50px"
        class="list-wrapper"
      >
        <div
          class="list-item-row"
          *ngFor="let user of filteredUsers"
          (click)="editUser(user)"
        >
          <div class="list-item-col">
            <div 
              class="icons-wrapper"
              *ngIf="user.warnings.length !== 0"
            >
              <span
                *ngIf="
                  user.warnings.length > 1 &&
                  user.warnings[0].description !==
                    'Er moet een SEPA machtiging verstuurd worden.'
                "
                matTooltip="De gebruiker heeft meerdere waarschuwingen."
                matTooltipPosition="right"
              >
                <div class="circle-icon red-bg">{{ user.warnings.length }}</div>
              </span>
              <span
                *ngIf="
                  user.warnings.length > 1 &&
                  user.warnings[0].description ===
                    'Er moet een SEPA machtiging verstuurd worden.'
                "
                [matTooltip]="user.warnings[0].description"
                matTooltipPosition="right"
              >
                <div
                  class="circle-icon"
                  [ngClass]="user.warnings[0].colorClass"
                >
                  {{
                    user.warnings[0].type === 'text'
                      ? user.warnings[0].iconOrText
                      : ''
                  }}
                  <mat-icon *ngIf="user.warnings[0].type === 'icon'">{{
                    user.warnings[0].iconOrText
                  }}</mat-icon>
                </div>
              </span>
              <span
                *ngIf="user.warnings.length === 1"
                [matTooltip]="user.warnings[0].description"
                matTooltipPosition="right"
              >
                <div
                  class="circle-icon"
                  [ngClass]="user.warnings[0].colorClass"
                >
                  {{
                    user.warnings[0].type === 'text'
                      ? user.warnings[0].iconOrText
                      : ''
                  }}
                  <mat-icon *ngIf="user.warnings[0].type === 'icon'">{{
                    user.warnings[0].iconOrText
                  }}</mat-icon>
                </div>
              </span>
            </div>
            {{ user.name ?? '' }} {{ user.lastName ?? '' }}
            ({{ user.credits ?? '' }})
          </div>
          <div class="list-item-col">{{ user.email ?? '' }}</div>
          <div
            class="list-item-col hide-column"
            *ngIf="this.askBirthDay"
          >
            {{ user?.birthDate ? moment(user.birthDate.toDate()).format('DD-MM-YYYY') : '' }}
          </div>
          <div class="list-item-col">{{ user.phoneNumber ?? user.phone ?? '' }}</div>
          <div class="list-item-col">
            <mat-chip *ngIf="user?.currentPlan?.name"> {{ user.currentPlan.name }} </mat-chip>
          </div>
          <div class="list-item-col hide-column">{{ user.hiddenRemarks ?? '' }}</div>
          <div class="list-item-col hide-column">{{ user.createdAt ? moment(user.createdAt.toDate()).format('DD-MM-YYYY') : '' }}</div>
          <div class="list-item-col">{{ user.cardId ?? '' }}</div>
          <div class="list-item-col hide-column">{{ user?.sepaData?.sepaSendOn ? moment(user.sepaData.sepaSendOn.toDate()).format('DD-MM-YYYY') : '' }}</div>
          <div class="list-item-col list-item-buttons">
            <button
              mat-icon-button
              (click)="$event.stopPropagation()"
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu>
              <button
                mat-menu-item
                (click)="showCreditsHistory(user)"
              >
                <span>Credits historie</span>
              </button>
              <button
                mat-menu-item
                (click)="changeCredits('add', user)"
              >
                <span>Credits toevoegen</span>
              </button>
              <button
                mat-menu-item
                (click)="changeCredits('remove', user)"
              >
                <span>Credits verminderen</span>
              </button>
              <a href="mailto:{{ user.email }}">
                <button mat-menu-item>
                  <span>Verstuur e-mail</span>
                </button>
              </a>
              <button
                mat-menu-item
                (click)="copyEmail(user.email)"
              >
                <span>Kopieer e-mail</span>
              </button>
              <button
                *ngIf="!user.hidden"
                mat-menu-item
                (click)="changeHiddenStatus('hide', user)"
              >
                <span>Verstop deelnemer</span>
              </button>
              <button
                *ngIf="user.hidden"
                mat-menu-item
                (click)="changeHiddenStatus('show', user)"
              >
                <span>Deelnemer weer laten zien</span>
              </button>
              <button
                [disabled]="user.deleted || user.id == currentUser.id"
                mat-menu-item
                (click)="deleteUser(user)"
              >
                <span>Verwijder deelnemer</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </mat-card>
  <div *ngIf="loading" id="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>
<button
  mat-fab
  class="fab-fixed"
  (click)="newUser()"
>
  <mat-icon>add</mat-icon>
</button>
