import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../interfaces';
import { doc, getDoc } from 'firebase/firestore';

@Component({
  selector: 'app-availability-driver',
  templateUrl: './availability-driver.component.html',
  styleUrls: ['./availability-driver.component.scss'],
})
export class AvailabilityDriverComponent implements OnInit {
  organisationId = localStorage.getItem('orgId');
  name: string;
  accountType: string;
  generalSettings: any;
  userId: string;
  selectedType = 'driver';
  selectedTypeName = 'Chauffeur';
  selectedName: string[] = [];
  currentUser: User;
  inactiveUser: User;

  isDriver: boolean = false;
  drivers: User[] = [];
  driverObs: Observable<User[]>;
  isPlanner: boolean = false;
  planners: User[] = [];
  plannerObs: Observable<User[]>;
  usersModifyAvailability: boolean;
  usesPlannerPlanning: boolean;

  public newDrivers: Subject<User[]> = new Subject();
  public newPlanners: Subject<User[]> = new Subject();
  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit() {
    this.redirectIfNotUsingDriverPlanning();
    this.userId = this.route.snapshot.paramMap.get('userId');
    console.log('this.userId', this.userId);
    this.generalSettings = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/settings/general`
        )
      )
    ).data();
    this.usesPlannerPlanning = this.generalSettings.usesPlannerPlanning
      ? this.generalSettings.usesPlannerPlanning
      : false;
    this.usersModifyAvailability =
      this.generalSettings.driversModifyAvailability;
    console.log('usersModifyAvailability', this.usersModifyAvailability);
    this.afAuth.user.subscribe(async (authUser) => {
      if (authUser) {
        console.log('authUser', authUser.uid);
        const newUser = (
          await getDoc(
            doc(
              this.db.firestore,
              `organisations/${this.organisationId}/users/`,
              `${authUser.uid}`
            )
          )
        ).data() as User;
        this.name = newUser.name;
        switch (newUser.accountType) {
          case 'driver':
            this.isDriver = true;
            this.userId = authUser.uid;
            this.accountType = 'Chauffeur';
            break;
          case 'planner':
            this.isPlanner = true;
            this.userId = authUser.uid;
            this.accountType = 'Planner';
            break;
          case 'admin':
            this.accountType = 'Administrator';
            break;
        }
        console.log('isDriver', this.isDriver);
        if (newUser.accountType !== 'driver') {
          this.driverObs = this.getObservable('driver');
          this.driverObs.subscribe((driver) => {
            console.log('driver in subscribe', driver);
            // this.newDrivers.next([]);
            this.newDrivers.next(driver);
            if (!this.currentUser) {
              this.currentUser = driver[0];
            }
          });

          this.plannerObs = this.getObservable('planner');
          this.plannerObs.subscribe((planner) => {
            this.newPlanners.next(planner);
            if (!this.currentUser) {
              this.currentUser = planner[0];
            }
          });

          if (newUser.accountType == 'planner') {
            this.changeType('planner');
          }
        }
      }
    });
  }

  getObservable(type: string) {
    return this.db
    .collection<any>(
      `organisations/${this.organisationId}/users`,
      (ref) => ref.where('accountType', '==', type).orderBy('name')
    )
    .snapshotChanges()
    .pipe(
      map((actions) =>
        actions.map((a, index) => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          if (!this.userId) {
            if (index === 0) {
              data.selected = true;
              this.userId = id;
              this.selectedName[type] = data.name;
            } else {
              data.selected = false;
            }
          }

          if (
            type !== this.selectedType && 
            !this.inactiveUser
          ) {
            if (index === 0) {
              this.inactiveUser = {id , ...data};
              this.selectedName[type] = data.name;
            }
          }

          if (this.userId === id) {
            this.selectedName[type] = data.name;
            this.currentUser = {id , ...data};
            console.log('data', data);
          }
          let dupFound = false;
          if (type == 'driver') {
            if (this.drivers.find((x) => x.id === id)) {
              dupFound = true;
            }

            if (!dupFound) {
              this.drivers.push({ id, ...data });
            }
          } else if (type == 'planner') {
            if (this.planners.find((x) => x.id === id)) {
              dupFound = true;
            }

            if (!dupFound) {
              this.planners.push({ id, ...data });
            }
          }
          return { id, ...data };
        })
      )
    );
  }

  changeType(type: string) {
    if (this.selectedType != type) {
      this.selectedType = type; 

      if (type == 'driver') {
        this.selectedTypeName = 'Chauffeur';
      } else if (type == 'planner') {
        this.selectedTypeName = 'Planner';
      }

      const newUser = this.inactiveUser;
      this.inactiveUser = this.currentUser;
      this.changeUser(newUser);
    }
  }

  changeUser(user: any) {
    this.userId = user.id;
    this.currentUser = user;
    this.selectedName[this.selectedType] = user.name;
    
    if (this.selectedType == 'driver') {
      this.drivers.map((driver: any) =>
        driver.id === this.userId
          ? (driver.selected = true)
          : (driver.selected = false)
      );
      this.newDrivers.next(this.drivers);
    } else if (this.selectedType == 'planner') {
      this.planners.map((planner: any) =>
        planner.id === this.userId
          ? (planner.selected = true)
          : (planner.selected = false)
      );
      this.newPlanners.next(this.planners);
    }
  }

  redirectIfNotUsingDriverPlanning() {
    const usesDriverPlanning = JSON.parse(
      localStorage.getItem('usesDriverPlanning')
    );
    if (!usesDriverPlanning) {
      this.router.navigateByUrl('/planner');
    }
  }
}
