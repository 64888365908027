<h1 mat-dialog-title>Nieuwe omgeving aanmaken</h1>
<div
  mat-dialog-content
  class="dialog-no-overflow"
>
  <form
    [formGroup]="newOrgForm"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Naam</mat-label>
      <input
        formControlName="organisationName"
        matInput
        required
      />
      <mat-error *ngIf="newOrgForm.controls.organisationName.errors?.required">
        Je moet dit veld invullen
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div
  mat-dialog-actions
  align="end"
>
  <button
    mat-button
    mat-dialog-close
  >
    Annuleren
  </button>

  <button
    mat-flat-button
    (click)="create()"
  >
    Aanmaken
  </button>
</div>
