import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Characteristic,
  Icon,
  PublicSettings,
  Ride,
  Setting,
  User,
  Vehicle,
} from '../../interfaces';
import { ImportExportComponent } from '../dialogs/import-export/import-export.component';
import { FileSaverService } from 'ngx-filesaver';
import * as XLSX from 'xlsx';
import { ExportRidesComponent } from '../dialogs/export-rides/export-rides.component';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import moment from 'moment';
import 'moment/locale/nl';
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  getDoc,
  setDoc,
  deleteField,
} from 'firebase/firestore';
import {
  computeColors,
  getFileExtension,
  getFileFromUrl,
  logSettingsChange,
  redirectIfNotAdmin,
  updateTheme,
} from '../../globals';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { getAuth } from 'firebase/auth';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  loadingPage: boolean = true;
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  generalSettings: Setting;
  publicSettings: PublicSettings;
  organisationForm: UntypedFormGroup;
  toggleSignal: boolean = false;
  transfering: boolean = false;
  exportingRides: boolean = false;
  organisationId = localStorage.getItem('orgId');
  cpToggle1: boolean = false;
  cpValue1: string;
  cpToggle2: boolean = false;
  cpValue2: string;
  usesDriverPlanning: boolean = localStorage.getItem('usesDriverPlanning')
    ? JSON.parse(localStorage.getItem('usesDriverPlanning'))
    : false;
  usesDriverRemarksInApp: boolean = localStorage.getItem(
    'usesDriverRemarksInApp'
  )
    ? JSON.parse(localStorage.getItem('usesDriverRemarksInApp'))
    : false;
  usesUserNotificationSystem: boolean = localStorage.getItem(
    'usesUserNotificationSystem'
  )
    ? JSON.parse(localStorage.getItem('usesUserNotificationSystem'))
    : false;
  minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
  travelMinutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
  hours = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];
  segmentMinutes = [5, 10, 15, 20, 25, 30];
  faviconPreview: string;
  bannerPreview: string;
  logoPreview: string;
  loginImagePreview: string;
  planningMenuImagePreview: string;
  saving: boolean = false;
  isSuperUser: boolean = false;

  constructor(
    public db: AngularFirestore,
    private fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _FileSaverService: FileSaverService,
    private storage: AngularFireStorage,
    private router: Router,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.organisationForm = this.fb.group({
      name: [{ value: '', disabled: true }],
      primaryColor: [''],
      accentColor: [''],
      favicon: [],
      banner: [],
      logo: [],
      loginImage: [],
      planningMenuImage: [],
      privacyLink: ['', [Validators.required]],
      maxActiveVehicles: [{ value: '', disabled: true }],
      signalColor: [''],
      pinAddCredits: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      ],
      pinSubstractCredits: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)],
      ],
      askPin: [true],
      askKilometers: [false],
      driversModifyAvailability: [false],
      driversShowParticipantPhone: [false],
      driversAddRemarkInApp: [false],
      startHour: [1],
      endHour: [24],
      pCommentsToggle: [true],
      dCommentsToggle: [true],
      alertHour: [8],
      alertMinute: [0],
      doubleBookingActivated: [false],
      activateSignalColor: [true],
      askBirthDay: [false],
      emailNotificationsDriversDayPart: [false],
      emailNotificationsParticipants: [false],
      emailNotificationsParticipantsRideConfirmed: [false],
      emailNotificationsParticipantsRideChanged: [false],
      emailNotificationsParticipantsRideDeleted: [false],
      emailNotificationsParticipantsRideReminder: [false],
      smsNotificationsParticipants: [false],
      smsNotificationsParticipantsRideConfirmed: [false],
      smsNotificationsParticipantsRideChanged: [false],
      smsNotificationsParticipantsRideDeleted: [false],
      smsNotificationsParticipantsRideReminder: [false],
      emailNotificationsCCAddress: [''],
      emailNotificationsBCCAddress: [''],
      askGender: [false],
      ignoreDriverStatus: [false],
      characteristicsStatus: [false],
      customRideDuration: [15],
      customSegment: [15],
      showRideProduct: [true],
      usesSendgrid: [true],
      noDeleteReason: [false],
      usesPlannerPlanning: [],
    });

    this.generalSettings = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/settings/general`
        )
      )
    ).data() as Setting;

    this.publicSettings = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/settings/public`
        )
      )
    ).data() as PublicSettings;

    this.isSuperUser = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/users/${
            getAuth().currentUser.uid
          }`
        )
      )
    ).data().superuser;

    const fileObject = {} as any;
    fileObject['favicon'] = this.publicSettings.favicon
      ? await getFileFromUrl(
          <string>this.publicSettings.favicon,
          `${this.organisationId}-favicon`
        )
      : null;

    fileObject['banner'] = this.publicSettings.banner
      ? await getFileFromUrl(
          <string>this.publicSettings.banner,
          `${this.organisationId}-banner`
        )
      : null;

    fileObject['logo'] = this.publicSettings.logo
      ? await getFileFromUrl(
          <string>this.publicSettings.logo,
          `${this.organisationId}-logo`
        )
      : null;

    fileObject['loginImage'] = this.publicSettings.loginImage
      ? await getFileFromUrl(
          <string>this.publicSettings.loginImage,
          `${this.organisationId}-loginImage`
        )
      : null;

    fileObject['planningMenuImage'] = this.publicSettings.planningMenuImage
      ? await getFileFromUrl(
          <string>this.publicSettings.planningMenuImage,
          `${this.organisationId}-planningMenuImage`
        )
      : null;

    this.organisationForm.patchValue({
      ...this.generalSettings,
      ...this.publicSettings,
      ...fileObject,
    });

    if (this.isSuperUser) {
      this.organisationForm.controls.name.enable();
    }

    this.cpValue1 = this.organisationForm.controls.primaryColor.value;
    this.cpValue2 = this.organisationForm.controls.accentColor.value;

    if (this.generalSettings.daypartSettings) {
      if (this.generalSettings.daypartSettings.startHour) {
        this.organisationForm.controls.startHour.setValue(
          this.generalSettings.daypartSettings.startHour
        );
      }
      if (this.generalSettings.daypartSettings.endHour) {
        this.organisationForm.controls.endHour.setValue(
          this.generalSettings.daypartSettings.endHour
        );
      }
    }

    if (this.generalSettings.alertDrivers) {
      if (this.generalSettings.alertDrivers.alertHour) {
        this.organisationForm.controls.alertHour.setValue(
          this.generalSettings.alertDrivers.alertHour
        );
      }
      if (this.generalSettings.alertDrivers.alertMinute) {
        this.organisationForm.controls.alertMinute.setValue(
          this.generalSettings.alertDrivers.alertMinute
        );
      }
    }

    this.organisationForm.controls.askPin.valueChanges.subscribe(() => {
      if (this.organisationForm.controls.askPin.value) {
        this.organisationForm.get('pinAddCredits').enable();
        this.organisationForm.get('pinSubstractCredits').enable();
      } else {
        this.organisationForm.get('pinAddCredits').disable();
        this.organisationForm.get('pinSubstractCredits').disable();
      }
    });

    this.faviconPreview = this.organisationForm.controls.favicon.value
      ? URL.createObjectURL(this.organisationForm.controls.favicon.value)
      : null;
    this.organisationForm.controls.favicon.valueChanges.subscribe(() => {
      this.faviconPreview = this.organisationForm.controls.favicon.value
        ? URL.createObjectURL(this.organisationForm.controls.favicon.value)
        : null;
    });

    this.bannerPreview = this.organisationForm.controls.banner.value
      ? URL.createObjectURL(this.organisationForm.controls.banner.value)
      : null;
    this.organisationForm.controls.banner.valueChanges.subscribe(() => {
      this.bannerPreview = this.organisationForm.controls.banner.value
        ? URL.createObjectURL(this.organisationForm.controls.banner.value)
        : null;
    });

    this.logoPreview = this.organisationForm.controls.logo.value
      ? URL.createObjectURL(this.organisationForm.controls.logo.value)
      : null;
    this.organisationForm.controls.logo.valueChanges.subscribe(() => {
      this.logoPreview = this.organisationForm.controls.logo.value
        ? URL.createObjectURL(this.organisationForm.controls.logo.value)
        : null;
    });

    this.loginImagePreview = this.organisationForm.controls.loginImage.value
      ? URL.createObjectURL(this.organisationForm.controls.loginImage.value)
      : null;
    this.organisationForm.controls.loginImage.valueChanges.subscribe(() => {
      this.loginImagePreview = this.organisationForm.controls.loginImage.value
        ? URL.createObjectURL(this.organisationForm.controls.loginImage.value)
        : null;
    });

    this.planningMenuImagePreview = this.organisationForm.controls
      .planningMenuImage.value
      ? URL.createObjectURL(
          this.organisationForm.controls.planningMenuImage.value
        )
      : null;
    this.organisationForm.controls.planningMenuImage.valueChanges.subscribe(
      () => {
        this.planningMenuImagePreview = this.organisationForm.controls
          .planningMenuImage.value
          ? URL.createObjectURL(
              this.organisationForm.controls.planningMenuImage.value
            )
          : null;
      }
    );

    redirectIfNotAdmin(this.db.firestore, this.router);
    this.loadingPage = false;
  }

  openImportExport() {
    const dialogRef = this.dialog.open(ImportExportComponent, {
      width: '375px',
      data: { showDate: true },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result && result.type === 'Export') {
        // export data
        this.transfering = true;
        await this.exportFirestoreDb(this.organisationId, result.startFrom, [
          'rides',
        ]); // for now hardcoded should be pulldown with checkboxes in dialog
        this.transfering = false;
      }
      if (result && result.type === 'Import') {
        // import data
        this.uploader.nativeElement.click();
      }
    });
  }

  openFileInput(id) {
    const element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  uploadedFile(event, id) {
    const file = event.target.files[0] as File;
    if (!file) {
      return;
    }
    switch (id) {
      case 'import':
        this.transfering = true;
        const reader = new FileReader();
        const batches = [];
        reader.readAsText(file);
        reader.onload = async () => {
          const importedJsonData = JSON.parse(reader.result as string);
          batches[0] = this.db.firestore.batch();
          let batchIndex = 0;
          let operationCounter = 0;
          for (const collection in importedJsonData) {
            if (collection === 'rides') {
              // correct dates and references
              for (const [key, value] of Object.entries(
                importedJsonData[collection]
              )) {
                const rideData = value as Ride;
                rideData.start = new Date(rideData.start);
                rideData.end = new Date(rideData.end);
                rideData.autoUpdateDateTime = new Date(
                  rideData.autoUpdateDateTime
                );
                rideData.lastAutoUpdate = new Date(rideData.lastAutoUpdate);

                rideData.vehicleRef = this.db.doc(
                  `organisations/${this.organisationId}/vehicles/${rideData.vehicleId}`
                ).ref;
                importedJsonData[collection][key] = rideData;
              }
            }
          }
          for (const [key, value] of Object.entries(importedJsonData)) {
            for (const [docKey, docValue] of Object.entries(value)) {
              batches[batchIndex].set(
                this.db.doc(
                  `organisations/${this.organisationId}/${key}/${docKey}`
                ).ref,
                docValue
              );
              operationCounter++;
              // needs testing if it works with more then 500 rides
              if (operationCounter === 500) {
                batchIndex++;
                batches[batchIndex] = this.db.firestore.batch();
              }
            }
          }

          batches.forEach(async (batch) => {
            await batch.commit();
          });
          this.transfering = false;
          this._snackBar.open('Ritten succesvol geimporteerd', 'X', {
            duration: 5000,
          });
        };
      case 'favicon':
        if (file.size > 2048000) {
          this._snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
            duration: 5000,
          });
          return;
        }
        this.organisationForm.controls.favicon.setValue(file);
        break;
      case 'banner':
        if (file.size > 2048000) {
          this._snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
            duration: 5000,
          });
          return;
        }
        this.organisationForm.controls.banner.setValue(file);
        break;
      case 'logo':
        if (file.size > 2048000) {
          this._snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
            duration: 5000,
          });
          return;
        }
        this.organisationForm.controls.logo.setValue(file);
        break;
      case 'loginImage':
        if (file.size > 2048000) {
          this._snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
            duration: 5000,
          });
          return;
        }
        this.organisationForm.controls.loginImage.setValue(file);
        break;
      case 'planningMenuImage':
        if (file.size > 2048000) {
          this._snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
            duration: 5000,
          });
          return;
        }
        this.organisationForm.controls.planningMenuImage.setValue(file);
        break;
    }
    event.target.value = null;
  }

  downloadFile(id) {
    const objectUrl = URL.createObjectURL(
      this.organisationForm.controls[id].value
    );
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = objectUrl;
    a.download = `${this.organisationForm.controls[id].value.name}`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  deleteFile(id) {
    this.organisationForm.controls[id].setValue(null);
  }

  previewImage(id) {
    switch (id) {
      case 'favicon':
        return this.faviconPreview;
      case 'banner':
        return this.bannerPreview;
      case 'logo':
        return this.logoPreview;
      case 'loginImage':
        return this.loginImagePreview;
      case 'planningMenuImage':
        return this.planningMenuImagePreview;
    }
  }

  async exportFirestoreDb(
    organisation: string,
    date: Date,
    collections: string[]
  ) {
    try {
      console.log('Exporting....');
      const saveObj = {} as any;
      for (const collection of collections) {
        let data;
        if (collection === 'rides') {
          data = (
            await this.db
              .collection(
                `organisations/${organisation}/${collection}`,
                (ref) => ref.where('start', '>', date)
              )
              .get()
              .toPromise()
          ).docs;
        } else {
          data = (
            await this.db
              .collection(`organisations/${organisation}/${collection}`)
              .get()
              .toPromise()
          ).docs;
        }
        // need to build up the obj. Cannot set a value to property which parents are undefined
        saveObj[collection] = {};
        if (data.length === 0) {
          return this._snackBar.open('Er is geen data om te exporteren', 'X', {
            duration: 5000,
          });
        }
        for (const newData of data) {
          if (collection === 'rides') {
            let rideData = newData.data();
            rideData.end = new Date(rideData.end.toDate());
            rideData.start = new Date(rideData.start.toDate());
            rideData.autoUpdateDateTime = rideData.autoUpdateDateTime
              ? new Date(rideData.autoUpdateDateTime)
              : null;
            rideData.lastAutoUpdate = rideData.lastAutoUpdate
              ? new Date(rideData.lastAutoUpdate)
              : null;

            rideData.users = rideData.users ? rideData.users : null;
            rideData.rideProduct = rideData.rideProduct
              ? rideData.rideProduct
              : null;
            if (rideData.vehicleRef) {
              // cannot save ref to file. convert to string and recompile when importing
              rideData.vehicleRef = `organisations/${this.organisationId}/vehicles/${rideData.vehicleId}`;
            }
            saveObj[collection][newData.id] = rideData;
          } else {
            saveObj[collection][newData.id] = newData.data();
          }
        }
      }

      this._FileSaverService.save(
        new Blob([JSON.stringify(saveObj)]),
        `Backup-${this.organisationId} ${new Date().toLocaleDateString()}.json`
      );
    } catch (e) {
      console.error(e);
    }
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    this.organisationForm.markAllAsTouched();
    if (this.organisationForm.valid) {
      const saveObj = { ...this.organisationForm.value };
      const startHour = saveObj.startHour;
      const endHour = saveObj.endHour;

      saveObj.daypartSettings = {
        startHour,
        endHour,
      };
      if (saveObj.alertDrivers) {
        saveObj.alertDrivers = {
          alertHour: saveObj.alertHour,
          alertMinute: saveObj.alertMinute,
        };
      }
      delete saveObj.alertHour;
      delete saveObj.alertMinute;
      let publicSaveObj = {};

      for (const key of Object.keys(saveObj)) {
        if (
          key.includes('NotificationsParticipants') ||
          key.includes('privacyLink') ||
          key.includes('askBirthDay') ||
          key.includes('askGender') ||
          key.includes('name') ||
          key.includes('primaryColor') ||
          key.includes('accentColor') ||
          key.includes('favicon') ||
          key.includes('banner') ||
          key.includes('logo') ||
          key.includes('loginImage') ||
          key.includes('planningMenuImage')
        ) {
          if (key.includes('primaryColor')) {
            saveObj[key] = this.cpValue1;
          }
          if (key.includes('accentColor')) {
            saveObj[key] = this.cpValue2;
          }
          if (key.includes('favicon') && saveObj[key] !== null) {
            const filePath = `organisations/${
              this.organisationId
            }/organisation_images/${
              this.organisationId
            }-favicon.${getFileExtension(
              this.organisationForm.controls.favicon.value
            )}`;

            const uploadTask = await this.storage.upload(
              filePath,
              this.organisationForm.controls.favicon.value
            );

            saveObj.favicon = await uploadTask.ref.getDownloadURL();
          }
          if (key.includes('banner') && saveObj[key] !== null) {
            const filePath = `organisations/${
              this.organisationId
            }/organisation_images/${
              this.organisationId
            }-banner.${getFileExtension(
              this.organisationForm.controls.banner.value
            )}`;

            const uploadTask = await this.storage.upload(
              filePath,
              this.organisationForm.controls.banner.value
            );

            saveObj.banner = await uploadTask.ref.getDownloadURL();
          }
          if (key.includes('logo') && saveObj[key] !== null) {
            const filePath = `organisations/${
              this.organisationId
            }/organisation_images/${
              this.organisationId
            }-logo.${getFileExtension(
              this.organisationForm.controls.logo.value
            )}`;

            const uploadTask = await this.storage.upload(
              filePath,
              this.organisationForm.controls.logo.value
            );

            saveObj.logo = await uploadTask.ref.getDownloadURL();
          }
          if (key.includes('loginImage') && saveObj[key] !== null) {
            const filePath = `organisations/${
              this.organisationId
            }/organisation_images/${
              this.organisationId
            }-loginImage.${getFileExtension(
              this.organisationForm.controls.loginImage.value
            )}`;

            const uploadTask = await this.storage.upload(
              filePath,
              this.organisationForm.controls.loginImage.value
            );

            saveObj.loginImage = await uploadTask.ref.getDownloadURL();
          }
          if (key.includes('planningMenuImage') && saveObj[key] !== null) {
            const filePath = `organisations/${
              this.organisationId
            }/organisation_images/${
              this.organisationId
            }-planningMenuImage.${getFileExtension(
              this.organisationForm.controls.planningMenuImage.value
            )}`;

            const uploadTask = await this.storage.upload(
              filePath,
              this.organisationForm.controls.planningMenuImage.value
            );

            saveObj.planningMenuImage = await uploadTask.ref.getDownloadURL();
          }
          if (saveObj[key] !== null) {
            publicSaveObj[key] = saveObj[key];
          } else {
            publicSaveObj[key] = deleteField();
          }
        }
        if (saveObj[key] === null) {
          saveObj[key] = deleteField();
        }
      }

      try {
        await this.db
          .doc(`organisations/${this.organisationId}`)
          .update({ name: saveObj.name });
        await setDoc(
          doc(
            this.db.firestore,
            `organisations/${this.organisationId}/settings/public`
          ),
          publicSaveObj,
          { merge: true }
        );
        await setDoc(
          doc(
            this.db.firestore,
            `organisations/${this.organisationId}/settings/general`
          ),
          saveObj,
          { merge: true }
        );
        await logSettingsChange(
          this.generalSettings,
          saveObj,
          this.publicSettings,
          publicSaveObj
        );
        this.titleService.setTitle(saveObj.name);
        updateTheme(computeColors(saveObj.primaryColor), 'primary');
        updateTheme(computeColors(saveObj.accentColor), 'accent');
        this._snackBar.open('Organisatie aangepast', 'X', {
          duration: 5000,
        });
        this.router.navigateByUrl('/planner');
      } catch (err) {
        console.error(err);
        this.saving = false;
      }
    }
    this.saving = false;
  }
  // Niet weghalen zonder overleg, dit was een export die beuningen nodig had, en kan misschien nog ooit terug komen als vraag.
  // async exportRides() {
  //   const dialogRef = this.dialog.open(ExportRidesComponent, {
  //     width: '375px',
  //   });
  //   dialogRef.afterClosed().subscribe(async (result) => {
  //     console.log('result', result);
  //     if (!result) {
  //       return false;
  //     }
  //     const ridesToExport = [];
  //     const allVehicles: Vehicle[] = [];
  //     const allUsers: User[] = [];
  //     const vehicleDocs = await getDocs(
  //       collection(
  //         this.db.firestore,
  //         `organisations/${this.organisationId}/vehicles`
  //       )
  //     );
  //     vehicleDocs.forEach((doc) => {
  //       allVehicles.push(doc.data() as Vehicle);
  //     });
  //     const userDocs = await getDocs(
  //       collection(
  //         this.db.firestore,
  //         `organisations/${this.organisationId}/users`
  //       )
  //     );
  //     userDocs.forEach((doc) => {
  //       let user = doc.data() as User;
  //       user = { ...user, id: doc.id };
  //       allUsers.push(user);
  //     });
  //     let queryConstraints = [];
  //     queryConstraints.push(where('finished', '==', true));
  //     switch (result.type) {
  //       case 'year':
  //         queryConstraints.push(where('year', '==', result.year));
  //         break;
  //       case 'month':
  //         queryConstraints.push(where('year', '==', result.year));
  //         queryConstraints.push(where('month', '==', result.month));
  //         break;
  //       case 'week':
  //         queryConstraints.push(where('week', '==', result.week));
  //         break;
  //       case 'day':
  //         queryConstraints.push(where('year', '==', result.year));
  //         queryConstraints.push(where('month', '==', result.month));
  //         queryConstraints.push(where('day', '==', result.day));
  //         break;
  //     }
  //     const q = query(
  //       collection(
  //         this.db.firestore,
  //         `organisations/${this.organisationId}/rides`
  //       ),
  //       ...queryConstraints
  //     );
  //     const ridesDocs = await getDocs(q);
  //     const rides: Ride[] = [];
  //     ridesDocs.forEach((doc) => {
  //       let ride = doc.data() as Ride;
  //       ride = { ...ride, id: doc.id };
  //       rides.push(ride);
  //     });
  //     console.log('rides', rides);
  //     try {
  //       for (const ride of rides) {
  //         const exportRideObj = {};

  //         exportRideObj['Rit ID'] = '-';
  //         exportRideObj['Starttijd'] = '-';
  //         exportRideObj['Eindtijd'] = '-';
  //         exportRideObj['Herkomst rit'] = '-';
  //         exportRideObj['Eindbestemming rit'] = '-';

  //         ride.start
  //           ? (exportRideObj['Starttijd'] = `${moment(
  //               ride.start.toDate()
  //             ).format('YYYY-MM-DD HH:mm')}`)
  //           : (exportRideObj['Starttijd'] = '-');
  //         ride.end
  //           ? (exportRideObj['Eindtijd'] = `${moment(ride.end.toDate()).format(
  //               'YYYY-MM-DD HH:mm'
  //             )}`)
  //           : (exportRideObj['Eindtijd'] = '-');

  //         exportRideObj['Rit ID'] = `${ride.id}`;

  //         if (ride.users && Object.keys(ride.users).length > 0) {
  //           const userKeys = Object.keys(ride.users);
  //           const firstUserId = userKeys[0];
  //           const firstUserInRide = ride.users[firstUserId];
  //           const lastUserId = userKeys[userKeys.length - 1];
  //           const lastUserInRide = ride.users[lastUserId];
  //           exportRideObj['Herkomst rit'] =
  //             typeof firstUserInRide.from === 'object'
  //               ? firstUserInRide.from.address
  //               : firstUserInRide.from;
  //           exportRideObj['Eindbestemming rit'] =
  //             typeof lastUserInRide.to === 'object'
  //               ? lastUserInRide.to.address
  //               : lastUserInRide.to;
  //         }
  //         ridesToExport.push(exportRideObj);

  //         if (ride.users && Object.keys(ride.users).length > 0) {
  //           const userKeys = Object.keys(ride.users);
  //           userKeys.forEach((id) => {
  //             const userInRide = ride.users[id];
  //             console.log('userInRide', userInRide);
  //             // for (const userInRide of Object.values(ride.users)) {
  //             const currentUser = allUsers.find((user) => {
  //               return user.id === id;
  //             });
  //             const newExportRideObj = { ...exportRideObj };
  //             newExportRideObj['Deelnemer ID'] = id;
  //             newExportRideObj['Naam deelnemer'] = '';
  //             newExportRideObj['Herkomst deelnemer'] =
  //               typeof userInRide.from === 'object'
  //                 ? userInRide.from.address
  //                 : userInRide.from;
  //             newExportRideObj['Eindbestemming deelnemer'] =
  //               typeof userInRide.to === 'object'
  //                 ? userInRide.to.address
  //                 : userInRide.to;
  //             if (currentUser) {
  //               newExportRideObj['Naam deelnemer'] = currentUser.name;
  //             } else {
  //               newExportRideObj['Naam deelnemer'] = userInRide.title;
  //             }
  //             ridesToExport.push(newExportRideObj);
  //             // }
  //           });
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //     if (ridesToExport.length > 0) {
  //       console.log('ridesToExport', ridesToExport);
  //       let fileName = 'Ritten.xlsx';
  //       let monthName;
  //       moment.locale('nl');
  //       switch (result.type) {
  //         case 'year':
  //           fileName = `Ritten ${result.year}.xlsx`;
  //           break;
  //         case 'month':
  //           monthName = moment.months(result.month);
  //           fileName = `Ritten ${monthName} ${result.year}.xlsx`;
  //           break;
  //         case 'week':
  //           fileName = `Ritten week ${result.week}.xlsx`;
  //           break;
  //         case 'day':
  //           monthName = moment.months(result.month);
  //           fileName = `Ritten ${result.day} ${monthName} ${result.year}.xlsx`;
  //           break;
  //       }
  //       this.ridesToExport = ridesToExport;
  //       this.sortExportRidesOutput(this.ridesToExport);
  //       const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.ridesToExport); // converts a DOM TABLE element to a worksheet
  //       const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //       XLSX.utils.book_append_sheet(wb, ws, 'Rides');
  //       // /* save to file */
  //       XLSX.writeFile(wb, fileName);
  //     } else {
  //       // Nothing to export
  //       this._snackBar.open('Er zijn geen ritten om te exporteren', 'X', {
  //         duration: 5000,
  //       });
  //     }
  //   });
  // }

  async exportRides() {
    const allCharacteristics = [];
    if (this.generalSettings.characteristicsStatus) {
      const characteristicsDocs = await getDocs(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/characteristics`
        )
      );
      characteristicsDocs.forEach((doc) => {
        allCharacteristics.push(doc.data() as Characteristic);
      });
    }
    const dialogRef = this.dialog.open(ExportRidesComponent, {
      width: '400px',
      data: {
        characteristics: allCharacteristics,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('result', result);
      if (!result) {
        return false;
      }
      const ridesToExport = [];
      const allVehicles: Vehicle[] = [];
      const allUsers: User[] = [];
      const allIcons: Icon[] = [];
      const vehicleDocs = await getDocs(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/vehicles`
        )
      );
      vehicleDocs.forEach((doc) => {
        allVehicles.push(doc.data() as Vehicle);
      });
      const userDocs = await getDocs(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/users`
        )
      );
      userDocs.forEach((doc) => {
        let user = doc.data() as User;
        user = { ...user, id: doc.id };
        allUsers.push(user);
      });
      const iconDocs = await getDocs(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/icons`
        )
      );
      iconDocs.forEach((doc) => {
        allIcons[doc.id] = doc.data() as Icon;
      });
      let queryConstraints = [];
      queryConstraints.push(where('finished', '==', true));
      switch (result.type) {
        case 'year':
          queryConstraints.push(where('year', '==', result.year));
          break;
        case 'month':
          queryConstraints.push(where('year', '==', result.year));
          queryConstraints.push(where('month', '==', result.month));
          break;
        case 'week':
          queryConstraints.push(where('week', '==', result.week));
          break;
        case 'day':
          queryConstraints.push(where('year', '==', result.year));
          queryConstraints.push(where('month', '==', result.month));
          queryConstraints.push(where('day', '==', result.day));
          break;
      }
      const q = query(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/rides`
        ),
        ...queryConstraints
      );
      const ridesDocs = await getDocs(q);
      const rides: Ride[] = [];
      ridesDocs.forEach((doc) => {
        let ride = doc.data() as Ride;
        ride = { ...ride, id: doc.id };
        rides.push(ride);
      });

      try {
        for (const ride of rides) {
          if (result.characteristics.length > 0) {
            const characteristicsFound = ride.characteristics.filter(
              (item) => result.characteristics.indexOf(item) != -1
            );
            if (characteristicsFound.length <= 0) {
              continue;
            }
          }
          const exportRideObj = {};

          exportRideObj['Maand'] = 0;
          exportRideObj['Week'] = 0;
          exportRideObj['Dag'] = '-';
          exportRideObj['Starttijd'] = '-';
          exportRideObj['Voertuig'] = '-';
          exportRideObj['Chauffeur'] = '-';
          exportRideObj['Kenmerken'] = '-';
          exportRideObj['Kaartnummer'] = '-';
          exportRideObj['Naam'] = '-';
          exportRideObj['Geslacht'] = '-';
          if (this.publicSettings.askBirthDay) {
            exportRideObj['Leeftijd'] = '-';
          }
          exportRideObj['Bijzonderheden'] = '-';
          exportRideObj['Ritprijs'] = 0;
          exportRideObj['Betaald'] = 'Nee';
          exportRideObj['Betaalde Credits'] = 0;
          exportRideObj['Rit ID'] = '-';

          if (ride.week) {
            exportRideObj['Week'] = parseInt(
              ride.week.substring(ride.week.indexOf('-') + 1)
            );
          }

          if (ride.start) {
            moment.locale('nl');
            const day = moment(ride.start.toDate()).locale('nl').format('dddd');
            exportRideObj['Dag'] =
              day.charAt(0).toUpperCase() + day.slice(1, 2);

            const month = moment(ride.start.toDate())
              .locale('nl')
              .format('MMMM');
            exportRideObj['Maand'] =
              month.charAt(0).toUpperCase() + month.slice(1);

            exportRideObj['Starttijd'] = `${moment(ride.start.toDate()).format(
              'DD-MM-YYYY HH:mm'
            )}`;
          }

          if (ride.vehicleId) {
            allVehicles.find((vehicle) => {
              if (vehicle.id === ride.vehicleId) {
                exportRideObj['Voertuig'] = vehicle.name;
              }
            });
          }

          if (ride.driverId) {
            allUsers.find((user) => {
              if (user.id === ride.driverId) {
                exportRideObj['Chauffeur'] = user.name;
              }
            });
          }

          if (ride.characteristics && ride.characteristics.length > 0) {
            exportRideObj['Kenmerken'] = ride.characteristics
              .toString()
              .replaceAll(',', ', ');
          }

          if (ride.rideProduct) {
            exportRideObj['Ritprijs'] = ride.rideProduct.cost;
          }

          exportRideObj['Rit ID'] = `${ride.id}`;

          if (ride.users && Object.keys(ride.users).length > 0) {
            for (const userInRide of Object.values(ride.users)) {
              let pickup = '';
              let destination = '';
              if (userInRide.from) {
                pickup =
                  typeof userInRide.from === 'string'
                    ? userInRide.from
                    : userInRide.from.address;
              }

              if (userInRide.to) {
                destination =
                  typeof userInRide.to === 'string'
                    ? userInRide.to
                    : userInRide.to.address;
              }

              const currentUser = allUsers.find((user) => {
                return user.id === userInRide.id;
              });
              const newExportRideObj = { ...exportRideObj };

              newExportRideObj['Naam'] = currentUser
                ? currentUser.name
                : userInRide.title;
              if (currentUser?.cardId) {
                newExportRideObj['Kaartnummer'] = currentUser.cardId;
              }
              if (currentUser?.gender) {
                newExportRideObj['Geslacht'] = currentUser.gender;
              }

              if (exportRideObj['Leeftijd'] && currentUser?.birthDate) {
                let diff = Date.now() - currentUser.birthDate.toDate();
                let ageDate = new Date(diff);
                newExportRideObj['Leeftijd'] = Math.abs(
                  ageDate.getUTCFullYear() - 1970
                );
              }

              if (currentUser?.icons) {
                let iconNames = [];
                for (const [k, v] of Object.entries(currentUser.icons)) {
                  if (allIcons[k] && v) {
                    iconNames.push(allIcons[k]['name']);
                  }
                }
                if (iconNames) {
                  newExportRideObj['Bijzonderheden'] = iconNames.join(', ');
                }
              }

              if (result.exportFromAndTo) {
                newExportRideObj['Ophaaladres'] = pickup;
                newExportRideObj['Bestemmingsadres'] = destination;
              }
              if (userInRide.paid) {
                newExportRideObj['Betaald'] = 'ja';
                newExportRideObj['Betaalde Credits'] =
                  userInRide.charge.creditsSpent;
              }
              if (userInRide.rideProduct) {
                newExportRideObj['Ritprijs'] = userInRide.rideProduct.cost;
              }
              ridesToExport.push(newExportRideObj);
            }
          } else {
            ridesToExport.push(exportRideObj);
          }
        }
      } catch (error) {
        console.error(error);
      }
      if (ridesToExport.length > 0) {
        let fileName = 'Ritten.xlsx';
        let monthName;
        switch (result.type) {
          case 'year':
            fileName = `Ritten ${result.year}.xlsx`;
            break;
          case 'month':
            monthName = moment.months(result.month);
            fileName = `Ritten ${monthName} ${result.year}.xlsx`;
            break;
          case 'week':
            fileName = `Ritten week ${result.week}.xlsx`;
            break;
          case 'day':
            monthName = moment.months(result.month);
            fileName = `Ritten ${result.day} ${monthName} ${result.year}.xlsx`;
            break;
        }
        this.sortExportRidesOutput(ridesToExport);
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ridesToExport); // converts a DOM TABLE element to a worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Rides');
        // /* save to file */
        XLSX.writeFile(wb, fileName);
      } else {
        // Nothing to export
        this._snackBar.open('Er zijn geen ritten om te exporteren', 'X', {
          duration: 5000,
        });
      }
    });
  }

  sortExportRidesOutput(ridesArray) {
    ridesArray.sort((row1, row2) => {
      if (row1['Week'] < row2['Week']) {
        return -1;
      }
      if (row1['Week'] > row2['Week']) {
        return 1;
      }
      if (row1['Week'] === row2['Week']) {
        if (row1['Starttijd'] < row2['Starttijd']) {
          return -1;
        }
        if (row1['Starttijd'] > row2['Starttijd']) {
          return 1;
        }
      }
      return 0;
    });
  }

  changeHeight() {
    this.toggleSignal = !this.toggleSignal;
  }
}
