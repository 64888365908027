<h1 mat-dialog-title *ngIf="!newUser">
  {{ user.name }} ({{ user.email }})
</h1>
<h1 mat-dialog-title *ngIf="newUser">Nieuwe gebruiker</h1>
<div mat-dialog-content>
  <form (ngSubmit)="save()" [formGroup]="userForm">
    <mat-form-field *ngIf="newUser">
      <mat-label>E-mail</mat-label>
      <input
        formControlName="email"
        autocomplete="one-time-code"
        type="email"
        matInput
        required
      />
      <mat-error *ngIf="userForm.controls.email.errors?.required">
        Je moet dit veld invullen
      </mat-error>
      <mat-error *ngIf="userForm.controls.email.errors?.email">
        Ongeldige e-mail
      </mat-error>
      <mat-error *ngIf="userForm.controls.email.errors?.customError">
        Ongeldige e-mail
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="newUser">
      <mat-label>Wachtwoord</mat-label>
      <input
        formControlName="password"
        type="password"
        autocomplete="new-password"
        matInput
        required
      />
      <mat-error *ngIf="userForm.controls.password.errors?.required">
        Je moet dit veld invullen
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Voor- & achternaam</mat-label>
      <input formControlName="name" type="name" matInput required />
      <mat-error *ngIf="userForm.controls.name.errors?.required">
        Je moet dit veld invullen
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Telefoonnummer</mat-label>
      <input formControlName="phone" type="tel" matInput required />
      <mat-error *ngIf="userForm.controls.phone.errors?.required">
        Je moet dit veld invullen
      </mat-error>
      <mat-error *ngIf="userForm.controls.phone.errors?.pattern">
        Ongeldig telefoonnummer
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Rechten</mat-label>
      <mat-select formControlName="accountType" required>
        <mat-option *ngIf="!newUser" style="font-style: italic" [value]="'none'"
          >Geen
        </mat-option>
        <mat-option [value]="'driver'">Chauffeur</mat-option>
        <mat-option [value]="'planner'">Planner</mat-option>
        <mat-option [value]="'admin'">Administrator</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName="withdrawRights" *ngIf="!newUser"
      >Maak gebruiker inactief</mat-slide-toggle
    >
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">ANNULEREN</button>
  <button
    [class.spinner]="saving"
    mat-flat-button
    [disabled]="!userForm.valid || saving"
    (click)="save()"
    cdkFocusInitial
  >
    OPSLAAN
  </button>
</div>
