<h1 mat-dialog-title>Exporteren ritten</h1>
<div mat-dialog-content>
  <form class="export-form" [formGroup]="exportForm">
    <mat-label>Periode</mat-label>
    <div class="date-filter">
      <mat-radio-group
        (change)="periodChange()"
        formControlName="selectedFilter"
        class="radio-group secondary-color"
      >
        <mat-radio-button
          *ngFor="let filter of dateFilter"
          [value]="filter.value"
        >
          {{ filter.viewValue }}
        </mat-radio-button>
      </mat-radio-group>
      <div class="select-fields">
        <mat-form-field
          [hideRequiredMarker]="true"
          *ngIf="
            exportForm.value.selectedFilter == 'year' ||
            exportForm.value.selectedFilter == 'month' ||
            exportForm.value.selectedFilter == 'week'
          "
        >
          <mat-label>Jaar</mat-label>
          <mat-select formControlName="year">
            <mat-option
              *ngFor="let year of years"
              [value]="year.value"
            >
              {{ year.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          [hideRequiredMarker]="true"
          *ngIf="exportForm.value.selectedFilter == 'month'"
        >
          <mat-label>Maand</mat-label>
          <mat-select formControlName="month">
            <mat-option
              *ngFor="let month of months"
              [value]="month.value"
            >
              {{ month.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          [hideRequiredMarker]="true"
          *ngIf="exportForm.value.selectedFilter == 'week'"
        >
          <mat-label>Week</mat-label>
          <mat-select formControlName="week">
            <mat-option
              *ngFor="let week of weeks"
              [value]="week"
            >
              {{ week }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          [hideRequiredMarker]="true"
          *ngIf="exportForm.value.selectedFilter == 'day'"
        >
          <mat-label>Kies een datum</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="day"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div
  mat-dialog-actions
  align="end"
>
  <button
    mat-button
    mat-dialog-close
  >
    ANNULEREN
  </button>
  <button
    mat-flat-button
    (click)="close()"
  >
    EXPORTEER
  </button>
</div>
