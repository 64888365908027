<h1 mat-dialog-title>Audits exporteren</h1>
<div mat-dialog-content>
  <form [formGroup]="exportForm">
    <mat-slide-toggle
      formControlName="timeAdded"
    >
      Tijd toevoegen
    </mat-slide-toggle>
  </form>
</div>
<div
  mat-dialog-actions
  align="end"
>
  <button
    mat-button
    mat-dialog-close
  >
    ANNULEREN
  </button>
  <button
    autofocus
    mat-flat-button
    [mat-dialog-close]="closeDialog()"
    cdkFocusInitial
  >
    EXPORTEER
  </button>
</div>
