<app-nav-bar></app-nav-bar>
<div class="wrapper">
  <div class="header">
    <div class="header-internals">
      <div class="header-title">
        <mat-icon>playlist_add_check</mat-icon>
        <div>Incasso historie</div>
      </div>
    </div>
  </div>
  <div class="content-wrapper" *ngIf="!loadingData">
    <mat-card class="title">
      Alle incasso's ({{ debitOrders.length }})
    </mat-card>
    <div class="card-wrapper">
      <mat-card
        class="card-order"
        *ngFor="let debitOrder of debitOrders; let i = index"
      >
        <div class="card-title">
          <div class="title-text">{{ debitOrder.id }}</div>
          <div class="title-total">
            Totaal €{{ debitOrder.totalAmountToEarn }},00
          </div>
        </div>
        <div class="card-customer">
          <div class="customers-title" (click)="toggleMenu(i)">
            <div class="title-text">
              Deelnemers ({{ debitOrder.customers.length }})
            </div>
            <mat-icon class="title-arrow">{{
              isCollapsed[i] === 'in' ? 'expand_more' : 'expand_less'
            }}</mat-icon>
          </div>
          <div [@slide]="isCollapsed[i]" class="customers-list">
            <div class="info">
              <div class="info-title">naam</div>
              <div
                class="info-data"
                *ngFor="let customer of debitOrder.customers"
              >
                {{ customer.name }}
              </div>
            </div>
            <div class="info">
              <div class="info-title">betaald</div>
              <div
                class="info-data"
                *ngFor="let customer of debitOrder.customers"
              >
                {{ customer.amount }}
              </div>
            </div>
            <div class="info">
              <div class="info-title">credits</div>
              <div
                class="info-data"
                *ngFor="let customer of debitOrder.customers"
              >
                {{ customer.credits }}
              </div>
            </div>
          </div>
        </div>
        <div class="card-info">
          <div class="created-by">
            Aangemaakt door {{ debitOrder.createdBy }} op
            {{ debitOrder.shownDate }}
          </div>
          <button
            mat-flat-button
            class="download-button secondary-color"
            (click)="downloadDebitOrder(i)"
          >
            <mat-icon>download</mat-icon>Download Incasso
          </button>
        </div>
      </mat-card>
    </div>
  </div>
</div>
<div *ngIf="loadingData" id="loading">
  <mat-spinner></mat-spinner>
</div>
