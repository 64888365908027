<h1 mat-dialog-title>Wil je de rit verwijderen?</h1>
<div mat-dialog-content>
  <p class="data-notification">
    Weet je zeker dat je deze rit wilt verwijderen?
    <span *ngIf="this.data.notification">
      <br>De personen in de rit zullen op de hoogte worden gesteld.
    </span>
  </p>
  <div *ngIf="!this.data.noDeleteReason">
    <p>Geef een reden van verwijderen op.</p>
    <form
      [formGroup]="deleteForm"
    >
      <mat-radio-group class="delete-reason-radio">
      <mat-radio-button
        #radioButton
        *ngFor="let option of radioOptions"
        [checked]="option === defaultOption"
        (change)="checkState($event)"
        value={{option}}
      >
        {{option}}
      </mat-radio-button>
    </mat-radio-group>
    <mat-form-field>
        <mat-label>Waarom verwijder je deze rit?</mat-label>
        <textarea
          formControlName="deleteReason"
          matInput
      ></textarea>
      </mat-form-field>
    </form>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Nee</button>
  <button
    mat-flat-button
    color="warn"
    [mat-dialog-close]="deleteForm.controls.deleteReason.value"
    cdkFocusInitial
    [disabled]="deleteForm.invalid"
  >
    Ja
  </button>
</div>
