import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { redirectIfNotAdmin } from 'src/app/globals';
import { Daypart } from 'src/app/interfaces';
import { UpsertDaypartComponent } from './dialogs/upsert-daypart/upsert-daypart.component';
import { getDoc } from 'firebase/firestore';

@Component({
  selector: 'app-day-parts',
  templateUrl: './day-parts.component.html',
  styleUrls: ['./day-parts.component.scss'],
})
export class DayPartsComponent implements OnInit {
  organisationId = localStorage.getItem('orgId');

  dayparts: any;
  newDayparts: any[] = [];
  loading = true;
  type = 'driver';
  usesPlannerPlanning = false;

  constructor(
    private fb: UntypedFormBuilder,
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) {}

  async ngOnInit() {
    const settings = (await getDoc(
      this.db.firestore
      .doc(`organisations/${this.organisationId}/settings/general`)
    )).data() as any;
    if (settings.usesPlannerPlanning) {
      this.usesPlannerPlanning = true;
    }

    this.getDayparts();
    redirectIfNotAdmin(this.db.firestore, this.router);
  }
//const cityRef = db.collection('cities').doc('SF');
  changeTab(e) {
    if (e.index === 0) {
      this.type = 'driver'
    } else if (e.index === 1) {
      this.type = 'planner'
    }
    this.getDayparts();
  }

  addDayPart() {
    this.dialog.open(UpsertDaypartComponent, {
      width: '475px',
      data: { type: this.type}
    });
  }
  editDaypart(daypart: Daypart, id: string) {
    this.dialog.open(UpsertDaypartComponent, {
      width: '475px',
      data: { daypart: { ...daypart }, id },
    });
  }

  async removeDaypart(id: string) {
    console.log('id', id);
    await this.db
      .doc(`organisations/${this.organisationId}/dayparts/${id}`)
      .delete();
    this.snackBar.open('Dagdeel is verwijderd', 'X', {
      duration: 5000,
    });
    // this.getDayparts();
  }

  async getDayparts() {
    this.loading = true;
    const daypartRef = await this.db
      .collection(`organisations/${this.organisationId}/dayparts`, (ref) => 
        //ref.orderBy('daypart.day')
        ref.where('type', '==', this.type)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );

    // using daynumbers instead of daystring for ordering
    daypartRef.subscribe((daypart) => {
      // console.log('daypart', daypart);
      const newDayparts = [];
      daypart.forEach(async (oldDayparts: any) => {
        console.log('in foreach', oldDayparts.daypart);
        const daypart = oldDayparts.daypart;
        let daypartObj = { day: daypart.day } as any;
        switch (daypart.day) {
          case 0:
            daypartObj.dayString = 'Maandag';
            break;
          case 1:
            daypartObj.dayString = 'Dinsdag';
            break;
          case 2:
            daypartObj.dayString = 'Woensdag';
            break;
          case 3:
            daypartObj.dayString = 'Donderdag';
            break;
          case 4:
            daypartObj.dayString = 'Vrijdag';
            break;
          case 5:
            daypartObj.dayString = 'Zaterdag';
            break;
          case 6:
            daypartObj.dayString = 'Zondag';
            break;
        }

        // format time
        daypartObj.startHour =
          daypart.startHour.toString().length !== 1
            ? daypart.startHour
            : '0' + daypart.startHour.toString();
        daypartObj.endHour =
          daypart.endHour.toString().length !== 1
            ? daypart.endHour
            : '0' + daypart.endHour.toString();
        daypartObj.endMin =
          daypart.endMin.toString().length !== 1
            ? daypart.endMin
            : daypart.endMin.toString() + '0';
        daypartObj.startMin =
          daypart.startMin.toString().length !== 1
            ? daypart.startMin
            : daypart.startMin.toString() + '0';

        daypartObj.id = oldDayparts.id;
        daypartObj.name = daypart.name;

        // remove dupblices day value
        // if (this.newDayparts.find((x) => x.day === daypartObj.day)) {
        //   delete daypartObj.day;
        // }

        const hasIndexToRemove = this.newDayparts.findIndex((x) => {
          x.id === daypartObj.id;
        });
        if (hasIndexToRemove === -1) {
          newDayparts.push(daypartObj);
        }
      });
      this.newDayparts = newDayparts.sort((a, b) => {
        return (
          a.day - b.day ||
          Number(a.startHour) - Number(b.startHour) ||
          Number(a.startMin) - Number(b.startMin) ||
          Number(a.endHour) - Number(b.endHour) ||
          Number(a.endMin) - Number(b.endMin)
        );
      });
      console.log('this.newDayparts', this.newDayparts);
      // this.newDayparts = this.newDayparts.sort(function (a, b) {
      //   // Turn your strings into dates, and then subtract them
      //   // to get a value that is either negative, positive, or zero.
      //   return <any>new Date(a.startHour) - <any>new Date(b.startHour);
      // });
      this.loading = false;
    });
  }
}
