import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  QueryDocumentSnapshot,
  collection,
  getDocs,
  where,
  query,
  orderBy,
  QueryConstraint,
  OrderByDirection,
} from 'firebase/firestore';
import { Vehicle, User, DeletedRide, Year, DateFilter, Month } from '../interfaces';
import { DeletedRideInformationComponent } from './dialogs/deleted-ride-information/deleted-ride-information.component';
import { ExportDeletedRidesComponent } from './dialogs/export-deleted-rides/export-deleted-rides.component';
import * as XLSX from 'xlsx';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';

@Component({
  selector: 'app-deleted-rides',
  templateUrl: './deleted-rides.component.html',
  styleUrls: ['./deleted-rides.component.scss']
})
export class DeletedRidesComponent implements OnInit {
  organisationId = localStorage.getItem('orgId');
  vehiclesData: Vehicle[] = [];
  driversData: User[] = [];

  dateFilter: DateFilter[] = [
    { value: 'year', viewValue: 'Jaar' },
    { value: 'month', viewValue: 'Maand' },
    { value: 'week', viewValue: 'Week' },
  ];
  years: Year[] = [];
  currentYear;
  months: Month[] = [
    { value: 1, viewValue: 'Januari' },
    { value: 2, viewValue: 'Februari' },
    { value: 3, viewValue: 'Maart' },
    { value: 4, viewValue: 'April' },
    { value: 5, viewValue: 'Mei' },
    { value: 6, viewValue: 'Juni' },
    { value: 7, viewValue: 'Juli' },
    { value: 8, viewValue: 'Augustus' },
    { value: 9, viewValue: 'September' },
    { value: 10, viewValue: 'Oktober' },
    { value: 11, viewValue: 'November' },
    { value: 12, viewValue: 'December' },
  ];
  currentMonth: any;
  weekArray: number[] = Array(53)
    .fill(0)
    .map((x, i) => i + 1);

  dateForm: FormGroup = new FormGroup({
    selectedDateFilter:  new FormControl('week'),
    selectedYear: new FormControl(),
    selectedMonth:  new FormControl(),
    selectedWeek:  new FormControl(),
  });
  filterForm: FormGroup = new FormGroup({
    searchOn: new FormControl('all'),
    search: new FormControl(''),
    deleteDateAscToDesc: new FormControl(true),
    rideDateAscToDesc: new FormControl(true),
  });
  filteredDeletedRides: any[];
  allDeletedRides: any[];
  sortByColumn: string = 'date';
  orderDirection: OrderByDirection = 'desc';
  queryConstraints: QueryConstraint[] = [];
  moment = moment;
  loaded: boolean = false;
  loadingRides: boolean = false;

  deletedRidesToExport: any;

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) { }

  async ngOnInit(): Promise<any> {
    const currentYear = parseInt(moment().format('Y'));
    const currentMonth = parseInt(moment().format('M'));
    let yearInStep = parseInt(moment().add(3, 'years').format('Y'));
    for (let step = 0; step < 7; step++) {
      this.years.push({ value: yearInStep, viewValue: yearInStep.toString() });
      yearInStep = yearInStep - 1;
    }
    this.years.forEach((year) => {
      if (year.value === currentYear) {
        this.dateForm.controls.selectedYear.setValue(year.value);
      }
    });
    this.months.forEach((month) => {
      if (month.value === currentMonth) {
       this.dateForm.controls.selectedMonth.setValue(month.value);
      }
    });
    this.dateForm.controls.selectedWeek.setValue(parseInt(moment().format('W')));
    this.filterForm.controls.search.valueChanges.subscribe((value)=>{
      this.searchFilter(value.toLowerCase());
    });

    const vehicleDocRef = collection(
      this.db.firestore,
      `organisations/${this.organisationId}/vehicles`
    );
    const vehicleDocs = await getDocs(vehicleDocRef);
    vehicleDocs.forEach((doc: QueryDocumentSnapshot<Vehicle>) => {
      const vehicle = {...doc.data(), id: doc.id};
      this.vehiclesData.push(vehicle);
    });

    const driverQuery = query(
      collection(
        this.db.firestore,
       `organisations/${this.organisationId}/users`
      ),
      where('accountType', '==', 'driver')
    );
    const driverDocs = await getDocs(driverQuery);
    driverDocs.forEach((doc) => {
      let driver = doc.data() as User;
      driver.id = doc.id;
      this.driversData.push(driver);
    });

    await this.periodFilter();
    this.loaded = true;
  }

 async periodFilter() {
    if (this.loadingRides) {
      return;
    }
    this.loadingRides = true;
    this.dateForm.disable();
    this.queryConstraints = [];
    switch (this.dateForm.value.selectedDateFilter) {
      case 'year':
        this.queryConstraints.push(where('rideData.year', '==', this.dateForm.value.selectedYear));
        break;
      case 'month':
        this.queryConstraints.push(where('rideData.year', '==', this.dateForm.value.selectedYear));
        this.queryConstraints.push(where('rideData.month', '==', this.dateForm.value.selectedMonth - 1));
        break;
      case 'week':
        const weekString = `${this.dateForm.value.selectedYear}-${this.dateForm.value.selectedWeek}`;
        this.queryConstraints.push(where('rideData.week', '==', weekString));
        break;
    }
    await this.getDeletedRides();
    this.loadingRides = false;
    this.dateForm.enable();
    this.searchFilter();
    this.sortDeletedRides();
  }

  async getDeletedRides() {
    const deletedRidesDocs = await getDocs(
      query(
        collection(
          this.db.firestore,
          `eventLogs/${this.organisationId}/deletedRides`
        ),
        ...this.queryConstraints,
        orderBy('date', 'desc')
      )
    );
    const allDeletedRides = [];
    deletedRidesDocs.forEach((doc) => {
      const data = doc.data();
      if (data.rideData) {
        allDeletedRides.push({ id: doc.id, ...data } as DeletedRide);
      }
    });
    this.allDeletedRides = allDeletedRides;
  }

  searchFilter(searchQuery = this.filterForm.value.search.toLowerCase()) {
    const filters = this.filterForm.value
    this.filteredDeletedRides = this.allDeletedRides.filter((deletedRide) => {
      let passesSearchFilter = true;
      if (searchQuery != '') {
        passesSearchFilter = false;

        if (
          !passesSearchFilter &&
          deletedRide.rideData.start &&
          (
            filters.searchOn == 'all' ||
            filters.searchOn == 'start'
          )
        ) {
          let date = moment(deletedRide.rideData.start.toDate()).format('DD-MM-YYYY HH:mm');
          if (date.includes(searchQuery)) {
            passesSearchFilter = true;
          }
        }
        if(
          !passesSearchFilter &&
          deletedRide.vehicleName &&
          (
            filters.searchOn == 'all' ||
            filters.searchOn == 'vehicle'
          ) &&
          deletedRide.vehicleName.toLowerCase().includes(searchQuery)
        ) {
          passesSearchFilter = true;
        }
        if (
          !passesSearchFilter &&
          deletedRide.doneByUser &&
          (
            filters.searchOn == 'all' ||
            filters.searchOn == 'doneByUser'
          ) &&
          deletedRide.doneByUser.toLowerCase().includes(searchQuery)
        ) {
          passesSearchFilter = true;
        }
        if (
          !passesSearchFilter &&
          deletedRide.deleteReason &&
          (
            filters.searchOn == 'all' ||
            filters.searchOn == 'deleteReason'
          ) &&
          deletedRide.deleteReason.toLowerCase().includes(searchQuery)
        ) {
          passesSearchFilter = true;
        }
        if (
          !passesSearchFilter &&
          deletedRide.rideData.users &&
          (
            filters.searchOn == 'all' ||
            filters.searchOn == 'user'
          )
        ) {
          const userIds = Object.keys(deletedRide.rideData.users);
          if (userIds.length > 0) {
            for (let key of userIds) {
              const userObject = deletedRide.rideData.users[key];
              if (userObject.title && userObject.title.toLowerCase().includes(searchQuery)) {
                passesSearchFilter = true;
                break;
              }
            }
          }
        }
      }

      if (passesSearchFilter) {
        return deletedRide;
      }
    });
  }

  sortDeletedRides() {
    this.filteredDeletedRides.sort((a, b) => {
      switch (this.sortByColumn) {
        case 'date':
          a = a.date;
          b = b.date;
          break;
        case 'ridestart':
          a = a.rideData.start;
          b = b.rideData.start;
          break;
      }
      if (this.orderDirection == 'desc') {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
      } else {
        if (a < b) {
          return 1;
        }
        if (a > b) {
          return -1;
        }
      }
      return 0;
    });
  }

  sortColumn(columnName) {
    if (this.sortByColumn == columnName) {
      this.orderDirection = this.orderDirection == 'asc' ? 'desc' : 'asc'; 
    } else {
      this.orderDirection = 'desc';
    }
    this.sortByColumn = columnName;
    this.sortDeletedRides()
  }

  clearSearch() {
    this.filterForm.controls.search.setValue('');
  }
  
  getVehicleName(id: string) {
    let vehicleName: string;
    
    this.vehiclesData.forEach((doc) => {
      if (doc.id === id) {
        vehicleName = doc.name;
      }
    });
    return vehicleName;
  }

  getDriverName(id: string) {
    let driverName: string;
    
    this.driversData.forEach((doc) => {
      if (doc.id === id) {
        driverName = doc.name;
      }
    });
    return driverName;
  }
  
  moreInformationDeletedRide(deletedRide: any) {
    this.dialog.open(DeletedRideInformationComponent, {
      width: '500px',
      data: {
        deletedRide: {...deletedRide,
        driverName: this.getDriverName(deletedRide.rideData.driverId) ?? '',
        vehicleName: this.getVehicleName(deletedRide.rideData.vehicleId) ?? ''}
      },
      autoFocus: false,
    }) 
  }

  exportDeletedRides() {
    const dialogRef = this.dialog.open(ExportDeletedRidesComponent, {
      width: '400px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (!result) {
        return false;
      }
      const deletedRidesToExport = [];
      const allVehicles: Vehicle[] = [];
      const allUsers: User[] = [];
      const vehicleDocs = await getDocs(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/vehicles`
        )
      );
      vehicleDocs.forEach((doc) => {
        allVehicles.push(doc.data() as Vehicle);
      });
      const userDocs = await getDocs(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/users`
        )
      );
      userDocs.forEach((doc) => {
        let user = doc.data() as User;
        user = { ...user, id: doc.id };
        allUsers.push(user);
      });
      let queryConstraints = [];
      switch (result.type) {
        case 'year':
          queryConstraints.push(where('rideData.year', '==', result.year));
          break;
        case 'month':
          queryConstraints.push(where('rideData.year', '==', result.year));
          queryConstraints.push(where('rideData.month', '==', result.month));
          break;
        case 'week':
          queryConstraints.push(where('rideData.week', '==', result.week));
          break;
        case 'day':
          queryConstraints.push(where('rideData.year', '==', result.year));
          queryConstraints.push(where('rideData.month', '==', result.month));
          queryConstraints.push(where('rideData.day', '==', result.day));
          break;
      }
      //constraint used to prevent old incompatible
      queryConstraints.push(orderBy('date', 'desc'));
      const q = query(
        collection(
          this.db.firestore,
          `eventLogs/${this.organisationId}/deletedRides`
        ),
        ...queryConstraints
      );
      const deletedRidesDocs = await getDocs(q);
      const deletedRides: DeletedRide[] = [];
      deletedRidesDocs.forEach((doc) => {
        let deletedRide = doc.data() as DeletedRide;
        deletedRides.push({id: doc.id, ...deletedRide});
      });

      try {
        for (const deletedRide of deletedRides) {
          const exportDeletedRideObj = {};

          exportDeletedRideObj['Ritdatum'] = '-';
          exportDeletedRideObj['Voertuig'] = '-';
          exportDeletedRideObj['Deelnemers'] = '-';
          exportDeletedRideObj['Kenmerken'] = '-';
          exportDeletedRideObj['Verwijderd op'] = '-';
          exportDeletedRideObj['Verwijderd door'] = '-';
          exportDeletedRideObj['Reden van verwijderen'] = '-';
          
          if (deletedRide.rideData.start) {
            exportDeletedRideObj['Ritdatum'] = `${moment(deletedRide.rideData.start.toDate()).format(
              'DD-MM-YYYY HH:mm'
            )}`;
          }

          if (deletedRide.date) {
            exportDeletedRideObj['Verwijderd op'] = `${moment(deletedRide.date.toDate()).format(
              'DD-MM-YYYY HH:mm'
            )}`;
          }

          if (deletedRide.doneByUser) {
            exportDeletedRideObj['Verwijderd door'] = deletedRide.doneByUser;
          }

          if (deletedRide.doneByUser) {
            exportDeletedRideObj['Reden van verwijderen'] = deletedRide.deleteReason;
          }

          if (deletedRide.rideData.vehicleId) {
            allVehicles.find((vehicle) => {
              if (vehicle.id === deletedRide.rideData.vehicleId) {
                exportDeletedRideObj['Voertuig'] = vehicle.name;
              }
            });
          }

          if (deletedRide.rideData.characteristics && deletedRide.rideData.characteristics.length > 0) {
            exportDeletedRideObj['Kenmerken'] = deletedRide.rideData.characteristics
              .toString()
              .replaceAll(',', ', ');
          }

          if (deletedRide.rideData.users && Object.keys(deletedRide.rideData.users).length > 0) {
            for (const userInRide of Object.values(deletedRide.rideData.users)) {
              const currentUser = allUsers.find((user) => {
                return user.id === userInRide.id;
              });
              const newexportDeletedRideObj = { ...exportDeletedRideObj };

              newexportDeletedRideObj['Deelnemers'] = currentUser
                ? currentUser.name
                : userInRide.title;
              deletedRidesToExport.push(newexportDeletedRideObj);
            }
          } else {
            deletedRidesToExport.push(exportDeletedRideObj);
          }
        }
      } catch (error) {
        console.error(error);
      }
      if (deletedRidesToExport.length > 0) {
        let fileName = 'Verwijderde Ritten.xlsx';
        let monthName;
        moment.locale('nl');
        switch (result.type) {
          case 'year':
            fileName = `Verwijderde Ritten ${result.year}.xlsx`;
            break;
          case 'month':
            monthName = moment.months(result.month);
            fileName = `Verwijderde Ritten ${monthName} ${result.year}.xlsx`;
            break;
          case 'week':
            fileName = `Verwijderde Ritten week ${result.week}.xlsx`;
            break;
          case 'day':
            monthName = moment.months(result.month);
            fileName = `Verwijderde Ritten ${result.day} ${monthName} ${result.year}.xlsx`;
            break;
        }
        this.deletedRidesToExport = deletedRidesToExport;
        this.sortExportDeletedRidesOutput(this.deletedRidesToExport);
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.deletedRidesToExport); // converts a DOM TABLE element to a worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Verwijderde ritten');
        // /* save to file */
        XLSX.writeFile(wb, fileName);
      } else {
        // Nothing to export
        this._snackBar.open('Er zijn geen ritten om te exporteren', 'X', {
          duration: 5000,
        });
      }
    });
  }

  sortExportDeletedRidesOutput(deletedRidesArray) {
    deletedRidesArray.sort((row1, row2) => {
      const a = row1.Ritdatum;
      const b = row2.Ritdatum;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
  }
}
