<app-nav-bar></app-nav-bar>
<div class="users">
  <div class="header">
    <div class="h-title">
      <mat-icon>location_on</mat-icon>
      <span>Veelvoorkomende adressen</span>
    </div>
    <div class="export-button">
      <button
        mat-raised-button
        (click)="openImportExport()"
      >
        <mat-icon>import_export</mat-icon>
        <span>Importeren/Exporteren</span>
      </button>
    </div>
  </div>
  <mat-card class="room-below-card">
    <div class="title">
      <h3>
        Alle veelvoorkomende adressen <span>({{ totalAddresses }})</span>
      </h3>
      <div class="search">
        <mat-form-field class="mat-form-field-no-subscript">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label class="placeholder">Zoeken</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder="Zoeken"
            [(ngModel)]="searchQuery"
            (ngModelChange)="onFilterChange('search', $event)"
          />
        </mat-form-field>
      </div>
    </div>
    <mat-table #table [dataSource]="filteredAddresses">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Locatienaam</mat-header-cell>
        <mat-cell *matCellDef="let address">
          <p class="text-truncate">
            {{ address.name }}
          </p>
        </mat-cell>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef>Adres</mat-header-cell>
        <mat-cell *matCellDef="let address">
          <p class="email text-truncate">
            {{ address.address }}
          </p>
        </mat-cell>
      </ng-container>

      <!-- Buttons Column -->
      <ng-container matColumnDef="buttons">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let address">
          <div class="buttons">
            <button
              mat-icon-button
              [matMenuTriggerFor]="moreMenu"
              aria-label="More"
              (click)="$event.stopPropagation()"
            >
              <mat-icon class="secondary-text">more_vert</mat-icon>
            </button>

            <mat-menu #moreMenu="matMenu">
              <button mat-menu-item (click)="editAddress(false, address)">
                <span>Adres bewerken</span>
              </button>
              <button mat-menu-item (click)="deleteAddress(address)">
                <span>Adres verwijderen</span>
              </button>
            </mat-menu>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let address; columns: displayedColumns"
        class="user"
        (click)="editAddress(false, address)"
        matRipple
      >
      </mat-row>
    </mat-table>
  </mat-card>
</div>
<button mat-fab class="fab-fixed" (click)="newAddress(true)">
  <mat-icon>add</mat-icon>
</button>
<input
  hidden
  type="file"
  accept=".xlsx"
  #uploader
  (change)="uploadedFile($event)"
/>
