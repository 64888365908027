import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getDoc } from 'firebase/firestore';
import { Daypart } from 'src/app/interfaces';

export interface dialogData {
  daypart?: Daypart;
  id?: string;
  type? : string;
}

@Component({
  selector: 'app-upsert-daypart',
  templateUrl: './upsert-daypart.component.html',
  styleUrls: ['./upsert-daypart.component.scss'],
})
export class UpsertDaypartComponent implements OnInit {
  daypartsForm: UntypedFormGroup;
  organisationId = localStorage.getItem('orgId');

  hours: number[] = [];
  minutes: any[] = [];
  isEditing = this.data && this.data.daypart ? true : false;

  days = [
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
    'Zondag',
  ];

  minuteStep = 5;

  constructor(
    private fb: UntypedFormBuilder,
    private db: AngularFirestore,
    private dialogRef: MatDialogRef<UpsertDaypartComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: dialogData
  ) {}

  async ngOnInit(): Promise<any> {
    const settings = (await getDoc(
      this.db.firestore
      .doc(`organisations/${this.organisationId}/settings/general`)
    )).data() as any;

    if (!settings.daypartSettings) {
      this.snackBar.open(
        'Er zijn nog geen van tot uren ingesteld bij instellingen',
        'X'
      );
      return;
    }

    const startHour = settings.daypartSettings.startHour;
    const endHour = settings.daypartSettings.endHour;

    for (let i = startHour; i <= endHour; i++) {
      this.hours.push(i);
    }

    for (let i = 0; i < 60; i += this.minuteStep) {
      this.minutes.push({
        val: i,
        text: `0${i}`.slice(-2),
      });
    }

    this.daypartsForm = this.fb.group({
      name: [, Validators.required],
      day: [, Validators.required],
      startHour: [this.hours[0], Validators.required],
      startMin: [0o0, Validators.required],
      endHour: [this.hours[this.hours.length - 1], Validators.required],
      endMin: [0o0, Validators.required],
    });

    if (this.data && this.data.daypart) {
      console.log('this.data.daypart', this.data);
      // create new obj because data types are interfering with interfaces
      const newDaypartObj = this.data.daypart as any;
      newDaypartObj.day = newDaypartObj.dayString;

      newDaypartObj.startHour = Number(newDaypartObj.startHour);
      newDaypartObj.endHour = Number(newDaypartObj.endHour);
      newDaypartObj.startMin = Number(newDaypartObj.startMin);
      newDaypartObj.endMin = Number(newDaypartObj.endMin);

      console.log('newDaypartObj', newDaypartObj);
      this.daypartsForm.patchValue(newDaypartObj);
    }
  }

  async addDaypart() {
    if (this.daypartsForm.valid) {
      console.log('form value', this.daypartsForm.value);
      if (this.daypartsForm.value.startHour > this.daypartsForm.value.endHour) {
        return this.snackBar.open(
          'De van tijd mag niet kleiner zijn dan de tot tijd',
          'X',
          {
            duration: 5000,
          }
        );
      }
      const day = this.daypartsForm.value.day;
      console.log('day', day);
      let saveObj = {} as any;
      switch (day) {
        case 'Maandag':
          saveObj.day = 0;
          break;
        case 'Dinsdag':
          saveObj.day = 1;
          break;
        case 'Woensdag':
          saveObj.day = 2;
          break;
        case 'Donderdag':
          saveObj.day = 3;
          break;
        case 'Vrijdag':
          saveObj.day = 4;
          break;
        case 'Zaterdag':
          saveObj.day = 5;
          break;
        case 'Zondag':
          saveObj.day = 6;
          break;
      }
      delete this.daypartsForm.value.day;
      saveObj = { ...saveObj, ...this.daypartsForm.value }; // merge obj
      console.log('saveObj', saveObj);

      if (this.isEditing) {
        console.log('lets edit', saveObj);
        await this.db
          .doc(`organisations/${this.organisationId}/dayparts/${this.data.id}`)
          .set(
            {
              daypart: saveObj,
            },
            { merge: true }
          );
      } else {
        await this.db
          .collection(`organisations/${this.organisationId}/dayparts`)
          .add({
            daypart: saveObj,
            type: this.data.type
          });
      }
      const snackBarTxt =
        this.data && this.data.daypart ? 'gewijzigd' : 'toegevoegd';
      this.snackBar.open(`Dagdeel ${snackBarTxt}`, 'X', {
        duration: 5000,
      });
      this.dialogRef.close();
    }
  }
}
