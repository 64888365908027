import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { UserComponent } from './dialogs/user/user.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreditsHistoryComponent } from './dialogs/credits-history/credits-history.component';
import { ChangeHiddenStatusComponent } from './dialogs/change-hidden-status/change-hidden-status.component';
import {
  addDoc,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { ChangeCreditsComponent } from './dialogs/change-credits/change-credits.component';
import { DeleteUserComponent } from './dialogs/delete-user/delete-user.component';
import { environment } from 'src/environments/environment';
import { PublicSettings, Setting, User, UserWithWarnings } from '../interfaces';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { redirectIfDriver } from '../globals';
import { Router } from '@angular/router';
import { ImportExportUsersComponent } from './dialogs/import-export-users/import-export-users.component';
import { getAuth } from 'firebase/auth';
import { CreateSepaComponent } from './dialogs/create-sepa/create-sepa.component';
import { PaymentMethod, FilterOption, AccountType } from 'src/app/enums';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { MatChipSelectionChange } from '@angular/material/chips';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
@HostListener('window:scroll', ['$event'])
export class UsersComponent implements OnInit {
  itemsToShow = 20;
  askBirthDay: boolean;
  env = environment;
  currentUser: User;
  publicSettings: PublicSettings;
  filterOption = FilterOption;

  filteredUsers: any[] = [];
  usersCollection: AngularFirestoreCollection<User>;
  displayedColumns = [];
  filterForm: FormGroup = new FormGroup({
    search: new FormControl(''),
    aToZ: new FormControl(true),
    filter: new FormControl(),
    showHidden: new FormControl(false),
  });
  generalSettings: any;

  searchQuery: string = '';
  allUsersList: UserWithWarnings[] = [];
  filteredUsersList: UserWithWarnings[] = [];
  icons: any = [];
  // Used for the delete button
  storeData: any;
  fileUploaded: File;
  worksheet: any;
  organisationId = localStorage.getItem('orgId');
  shouldShowSepaButton: boolean = false;
  accountType: string;

  moment = moment;
  loading: boolean = true;
  isSuperUser: boolean = false;

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public afAuth: AngularFireAuth,
    private fns: AngularFireFunctions,
    private zone: NgZone,
    private router: Router
  ) {}

  async ngOnInit() {
    const currentUserId = getAuth().currentUser?.uid;
    this.currentUser = {
      ...((
        await getDoc(doc(this.db.firestore, `users/${currentUserId}`))
      ).data() as User),
      id: currentUserId,
    };

    const organisationObj = this.currentUser.organisations.find((obj) => {
      return obj.id === this.organisationId;
    });

    if (
      this.currentUser.rights === AccountType.Admin ||
      organisationObj.accountType === AccountType.Admin
    ) {
      this.shouldShowSepaButton = true;
    }
    if (this.currentUser.rights === AccountType.Admin) {
      this.isSuperUser = true;
    }
    this.accountType = organisationObj.accountType;

    this.publicSettings = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/settings/public`
        )
      )
    ).data() as PublicSettings;

    this.generalSettings = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/settings/general`
        )
      )
    ).data() as Setting;
    this.askBirthDay = this.publicSettings
      ? this.publicSettings.askBirthDay
      : false;

    const usersQuery = query(
      collection(
        this.db.firestore,
        `organisations/${this.organisationId}/users`
      )
    );
    onSnapshot(usersQuery, (userSnapshot) => {
      const usersList: UserWithWarnings[] = [];
      const filteredUsers: UserWithWarnings[] = [];
      userSnapshot.forEach((user) => {
        const userData = user.data() as UserWithWarnings;
        if (this.isSuperUser || this.checkAllowed(userData)) {
          if (!userData.credits) {
            userData.credits = 0;
          }
          const id = user.id;
          if (
            userData.sepaData &&
            userData.sepaData.hasOpenSepa &&
            userData.sepaData.sepaSentOn
          ) {
            userData.sepaData.sepaSentOn =
              userData.sepaData.sepaSentOn.toDate();
          }
          userData.warnings = this.getWarnings(userData);
          usersList.push({ id: id, ...userData } as UserWithWarnings);
        }
      });
      this.allUsersList = usersList;
      this.allUsersList.forEach((user) => {
        if (this.checkFilters(user)) {
          filteredUsers.push(user);
        }
      });
      this.filteredUsers = filteredUsers;
      this.sortUsers();
    });

    this.filterForm.valueChanges.subscribe(() => {
      this.filteredUsers = [];
      this.allUsersList.forEach((user) => {
        if (this.checkFilters(user)) {
          this.filteredUsers.push(user);
        }
      });
      this.sortUsers();
    });

    this.usersCollection = this.db.collection<User>(
      `organisations/${this.organisationId}/users`
    );

    const q = collection(
      this.db.firestore,
      `organisations/${this.organisationId}/icons`
    );
    const icons = await getDocs(q);

    if (icons.size > 0) {
      icons.docs.forEach((iconDoc) => {
        const icon = iconDoc.data();
        icon.id = iconDoc.id;
        this.icons.push(icon);
      });
    }
    redirectIfDriver(this.db.firestore, this.router);
    this.loading = false;
  }

  importExportUsers() {
    this.dialog.open(ImportExportUsersComponent, {
      width: '370px',
      data: {
        allUsers: this.allUsersList,
        filteredUsers: this.filteredUsers,
        icons: this.icons,
      },
    });
  }

  clearSearch() {
    this.searchQuery = '';
  }

  copyEmail(email) {
    const el = document.createElement('textarea');
    el.value = email;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this._snackBar.open('E-mail gekopieerd.', 'X', {
      duration: 3000,
    });
  }

  checkFilters(user: UserWithWarnings) {
    this.itemsToShow = 20;
    let passesHiddenFilter = true;
    let passesSearchFilter = true;
    let passesFilter = true;
    const filters = this.filterForm.value;

    if (
      (filters.showHidden &&
        (user.hidden || user.accountType === 'inactive')) ||
      (!filters.showHidden && !user.hidden && user.accountType !== 'inactive')
    ) {
      passesHiddenFilter = true;
    } else {
      passesHiddenFilter = false;
    }
    if (filters.search) {
      const searchQuery = filters.search.toLowerCase();
      passesSearchFilter = false;
      if (user.id) {
        if (user.id.toLowerCase() === searchQuery) {
          passesSearchFilter = true;
        }
      }
      if (user.name) {
        if (user.name.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (user.email) {
        if (user.email.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (user.phoneNumber) {
        if (user.phoneNumber.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (user.cardId) {
        if (user.cardId.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (user.postal) {
        if (user.postal.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (user.streetName) {
        if (user.streetName.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (user.city) {
        if (user.city.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (user.currentPlan) {
        if (
          user.currentPlan.name &&
          user.currentPlan.name.toLowerCase().includes(searchQuery)
        ) {
          passesSearchFilter = true;
        }
        if (
          user.currentPlan.description &&
          user.currentPlan.description.toLowerCase().includes(searchQuery)
        ) {
          passesSearchFilter = true;
        }
      }
    }
    if (filters.filter) {
      const filter = filters.filter as FilterOption;
      switch (filter) {
        case FilterOption.NoCards:
          if (user.cardId) {
            passesFilter = false;
          }
          break;
        case FilterOption.NoPlan:
          if (user.currentPlan) {
            passesFilter = false;
          }
          break;
        case FilterOption.WarningLowCredits:
        case FilterOption.WarningPaymentError:
        case FilterOption.WarningSendSepaMandate:
        case FilterOption.WarningUploadSepaMandate:
          const foundWarning = user.warnings?.find((x) => x.id === filter);
          if (!foundWarning) {
            passesFilter = false;
          }
          break;
      }
    }

    if (this.currentUser.rights !== 'admin') {
      if (user.superuser) {
        passesHiddenFilter = false;
        passesSearchFilter = false;
      }
    }

    if (passesSearchFilter && passesHiddenFilter && passesFilter) {
      return true;
    }
    return false;
  }

  checkAllowed(user: UserWithWarnings) {
    if (
      !user.superuser &&
      (!user.accountType ||
        (this.accountType == AccountType.Admin &&
          user.accountType != AccountType.Admin))
    ) {
      return true;
    }
    return false;
  }

  sortUsers() {
    const aToZ: boolean = this.filterForm.value.aToZ ?? true;
    this.filteredUsers.sort((a, b) => {
      const nameA = a.name ? a.name.toLowerCase() : '';
      const nameB = b.name ? b.name.toLowerCase() : '';
      if (nameA < nameB) {
        return aToZ ? -1 : 1;
      }
      if (nameA > nameB) {
        return aToZ ? 1 : -1;
      }
      return 0;
    });
  }

  async changeCredits(type, user) {
    const dialogRef = this.dialog.open(ChangeCreditsComponent, {
      width: '370px',
      data: { type, userId: user.id },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed with result: ', result);
      if (!result) {
        return;
      }

      let pinAdd = Number(this.generalSettings.pinAddCredits);
      let pinSubstract = Number(this.generalSettings.pinSubstractCredits);

      if (result.credits) {
        if (this.generalSettings.askPin) {
          if (!result.pin) {
            this._snackBar.open('Geen pin ingevoerd', '', {
              duration: 5000,
            });
            return;
          }
          if (!pinAdd || !pinSubstract) {
            return this.showError(
              'Er is nog geen pin ingesteld bij instellingen'
            );
          }
          const pin = result.pin;
          if (type === 'add' && pinAdd !== pin) {
            return this.showError('Pin incorrect');
          }
          if (type === 'remove' && pinSubstract !== pin) {
            return this.showError('Pin incorrect');
          }
        }
        let oldCredits: number;
        let newCredits: number;
        await this.db.firestore.runTransaction((transaction) =>
          transaction
            .get(this.usersCollection.doc(user.id).ref)
            .then((userDoc) => {
              if (userDoc.exists) {
                oldCredits = userDoc.data().credits || 0;
                if (type === 'add') {
                  newCredits = oldCredits + result.credits;
                }
                if (type === 'remove') {
                  newCredits = oldCredits - result.credits;
                }
                transaction.update(this.usersCollection.doc(user.id).ref, {
                  credits: newCredits,
                  paymentFailed: false,
                });
              }
            })
        );
        let saveData;
        console.log('remove or add credits', type);
        if (type === 'add') {
          saveData = {
            userId: user.id,
            userRef: this.db.doc(`users/${user.id}`).ref,
            date: new Date(),
            creditsAdded: result.credits,
            // description: 'Handmatige bijschrijving',
            description: 'Contante betaling',
            status: 'paid',
            type: 'cash',
            remarks: result.remarks ? result.remarks : deleteField(),
            payedToWemaron: false,
            actionBy: this.currentUser.email,
          };

          await this.db.firestore
            .collection(`organisations/${this.organisationId}/payments`)
            .doc()
            .set(saveData, { merge: true });
        }
        if (type === 'remove') {
          saveData = {
            userId: user.id,
            userRef: this.db.doc(`users/${user.id}`).ref,
            date: new Date(),
            creditsSpent: result.credits,
            description: 'Handmatige afschrijving',
            remarks: result.remarks ? result.remarks : deleteField(),
            actionBy: this.currentUser.email,
          };

          await this.db.firestore
            .collection(`organisations/${this.organisationId}/charges`)
            .doc()
            .set(saveData, { merge: true });
        }
        if (type === 'add') {
          this._snackBar.open('Credits toegevoegd', 'X', {
            duration: 5000,
          });
        }
        if (type === 'remove') {
          this._snackBar.open('Credits verminderd', 'X', {
            duration: 5000,
          });
        }
      }
    });
  }

  async showCreditsHistory(user) {
    console.log('user', user);
    this.dialog.open(CreditsHistoryComponent, {
      data: { user },
    });
  }

  async changeHiddenStatus(status, user) {
    console.log('user', user);
    const dialogRef = this.dialog.open(ChangeHiddenStatusComponent, {
      width: '300px',
      data: { type: status, user },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed with result: ', result);
      if (result) {
        if (status === 'hide') {
          await this.usersCollection.doc(user.id).update({ hidden: true });
          this._snackBar.open('Deelnemer is verstopt', '', {
            duration: 5000,
          });
        } else if (status === 'show') {
          await this.usersCollection.doc(user.id).update({ hidden: false });
          this._snackBar.open('Deelnemer is weer zichtbaar', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async editUser(user) {
    const publicSettings = this.publicSettings;
    const generalSettings = this.generalSettings;
    const accountType = this.accountType;
    this.dialog.open(UserComponent, {
      width: '500px',
      data: { publicSettings, generalSettings, accountType, user },
    });
  }

  async deleteUser(user) {
    if (user.id == this.currentUser.id) {
      this._snackBar.open('Je kan jezelf niet verwijderen.', '', {
        duration: 5000,
      });
      return;
    }
    if (user.currentPlan) {
      this._snackBar.open(
        'Deze deelnemer heeft nog een gekoppeld betaalplan en kan niet worden verwijderd.',
        '',
        {
          duration: 5000,
        }
      );
      return;
    }
    const paymentsRef = query(
      collection(
        getFirestore(),
        `organisations/${this.organisationId}/payments`
      ),
      where('userId', '==', user.id),
      orderBy('date', 'desc'),
      limit(1)
    );
    const payments = await getDocs(paymentsRef);
    let lastPayment = null;
    if (payments.size > 0) {
      lastPayment = payments.docs[0].data();
    }
    const dialogRef = this.dialog.open(DeleteUserComponent, {
      width: '300px',
      data: { user },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed', result, user);
      if (result) {
        try {
          await this.db
            .doc(
              `organisations/${this.organisationId}/deleteUsersQueue/${user.id}`
            )
            .set({
              email: user.email ?? '',
              phone: user.phone ?? '',
              date: new Date(),
              status: 'todo',
              userData: user,
              adminEmail: this.currentUser.email,
            });
          await addDoc(
            collection(
              this.db.firestore,
              `eventLogs/${this.organisationId}/deletedUser`
            ),
            {
              date: new Date(),
              doneByUser: getAuth().currentUser.email,
              doneByUid: getAuth().currentUser.uid,
              email: user.email ?? '',
              phone: user.phone ?? '',
              userData: user,
            }
          );
          if (lastPayment) {
            const lastPaymentMoment = moment(lastPayment.date.toDate()).add(
              2,
              'years'
            );
            this._snackBar.open(
              `De deelnemer wordt verwijderd op ${lastPaymentMoment.format(
                'DD-MM-YYYY'
              )}`,
              '',
              {
                duration: 5000,
              }
            );
          } else {
            this._snackBar.open(
              'De deelnemer wordt binnenkort verwijderd.',
              '',
              {
                duration: 5000,
              }
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  }
  newUser() {
    const publicSettings = this.publicSettings;
    const generalSettings = this.generalSettings;
    this.dialog.open(UserComponent, {
      width: '500px',
      data: { publicSettings, generalSettings },
    });
  }

  showError(txt: string): void {
    this._snackBar.open(txt, 'X', {
      duration: 5000,
    });
  }

  requestSepaCreation() {
    this.dialog.open(CreateSepaComponent, {
      width: '400px',
      data: { users: this.allUsersList, plannerEmail: this.currentUser.email },
    });
  }

  setFilter(option: FilterOption) {
    this.filterForm.controls.filter.setValue(
      this.filterForm.value.filter === option ? null : option
    );
  }

  dropdownChipSelection(event: MatChipSelectionChange) {
    if (
      this.filterForm.value.filter &&
      this.filterForm.value.filter !== this.filterOption.NoCards &&
      this.filterForm.value.filter !== this.filterOption.NoPlan
    ) {
      event.source.select();
    } else {
      event.source.deselect();
    }
  }

  getWarnings(user: UserWithWarnings) {
    const warnings = [];
    const userCreditWarning = user.currentPlan?.automaticLimit
      ? user.currentPlan?.automaticLimit
      : 5;

    if (user.paymentMethod === PaymentMethod.SEPA && !user.sepaMandateId) {
      warnings.push({
        id: 'sendSepaMandate',
        iconOrText: 'S',
        description: 'Er moet een SEPA machtiging verstuurd worden.',
        type: 'text',
        colorClass: 'orange-bg',
      });
    }
    if (user.credits < userCreditWarning) {
      warnings.push({
        id: 'lowCredits',
        iconOrText: 'priority_high',
        description: `De gebruiker heeft minder dan ${
          user.currentPlan?.automaticLimit
            ? user.currentPlan?.automaticLimit
            : 5
        } credits.`,
        type: 'icon',
        colorClass: 'orange-bg',
      });
    }
    if (user.paymentFailed) {
      warnings.push({
        id: 'paymentError',
        iconOrText: '€',
        description: 'Er is een fout opgetreden bij het betalen.',
        type: 'text',
        colorClass: 'red-bg',
      });
    }
    if (
      user.paymentMethod === PaymentMethod.SEPA &&
      user.sepaMandateId &&
      !user.sepaPath
    ) {
      warnings.push({
        id: 'uploadSepaMandate',
        iconOrText: 'S',
        description: 'Upload SEPA machtiging.',
        type: 'text',
        colorClass: 'purple-bg',
      });
    }
    return warnings;
  }
}
