import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { EditRideComponent } from '../edit-ride/edit-ride.component';
import { CopyRideComponent } from '../copy-ride/copy-ride.component';
import { Ride, Setting, User } from 'src/app/interfaces';
import { Observable, lastValueFrom } from 'rxjs';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { map, take } from 'rxjs/operators';
import { Vehicle, Icon } from 'src/app/interfaces';
import { FuncsService } from 'src/app/services/funcs.services';
import { DeleteRideComponent } from '../edit-ride/dialogs/delete-ride/delete-ride.component';
import { CopyUpdateComponent } from '../copy-update/copy-update.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RideEditsComponent } from '../ride-edits/ride-edits.component';
import {
  addDoc,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import moment from 'moment';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

export interface DialogData {
  ride: Ride;
  vehicle: Vehicle;
  readOnly?: boolean;
  driverId: string;
}

@Component({
  selector: 'app-ride-details',
  templateUrl: './ride-details.component.html',
  styleUrls: ['./ride-details.component.scss'],
})
export class RideDetailsComponent implements OnInit {
  userId: string;
  userEmail: string;
  users: Array<any>;
  icons: Observable<Icon[]>;
  iconsCollection: AngularFirestoreCollection<Icon>;
  driverDoc: AngularFirestoreDocument<User>;
  driver: Observable<User>;
  organisationId = localStorage.getItem('orgId');
  ignoreDriverStatus: boolean;
  showRideProduct: boolean;
  characteristics: string[] = [];
  emailNotificationsParticipants: {
    enabled: boolean;
    emailNotificationsParticipantsRideConfirmed: boolean;
    emailNotificationsParticipantsRideChanged: boolean;
    emailNotificationsParticipantsRideDeleted: boolean;
  } = {
    enabled: false,
    emailNotificationsParticipantsRideConfirmed: false,
    emailNotificationsParticipantsRideChanged: false,
    emailNotificationsParticipantsRideDeleted: false,
  };
  smsNotificationsParticipants: {
    enabled: boolean;
    smsNotificationsParticipantsRideConfirmed: boolean;
    smsNotificationsParticipantsRideChanged: boolean;
    smsNotificationsParticipantsRideDeleted: boolean;
  } = {
    enabled: false,
    smsNotificationsParticipantsRideConfirmed: false,
    smsNotificationsParticipantsRideChanged: false,
    smsNotificationsParticipantsRideDeleted: false,
  };
  loading: boolean = false;
  showDriverPlanning: boolean;
  characteristicsStatus: boolean;
  generalSettings: Setting;

  constructor(
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RideDetailsComponent>,
    private funcs: FuncsService,
    private _snackBar: MatSnackBar,
    private fns: AngularFireFunctions
  ) {}

  async ngOnInit() {
    this.showDriverPlanning = JSON.parse(
      localStorage.getItem('usesDriverPlanning')
    );
    this.userId = getAuth().currentUser.uid;
    this.userEmail = getAuth().currentUser.email;
    console.log(this.funcs.getRideTitle(this.data.ride));
    console.log('data', this.data);
    if (this.data.ride.users) {
      Object.keys(this.data.ride.users).forEach((key) => {
        this.data.ride.users[key].id = key;
      });
      this.users = Object.values(this.data.ride.users).sort((x, y) => {
        return x.order - y.order;
      });
      for (let a = 0; a < this.users.length; a++) {
        const userDoc = this.db.doc(
          `organisations/${this.organisationId}/users/${this.users[a].id}`
        );
        userDoc.snapshotChanges().subscribe((snapshot) => {
          console.log('snapshot', snapshot);
          if (!snapshot.payload.exists) {
            return;
          }
          const user = snapshot.payload.data() as User;
          console.log('user', user);
          this.users[a].phoneNumber = user.phoneNumber ?? user.phone ?? '';
        });
      }
    }
    console.log('users', this.users);
    this.iconsCollection = this.db.collection<Icon>(
      `organisations/${this.organisationId}/icons`,
      (ref) => ref.orderBy('name', 'asc')
    );
    this.icons = this.iconsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Icon;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );

    if (this.data.ride.characteristics) {
      for (const characteristics of this.data.ride.characteristics) {
        const index = this.characteristics.indexOf(characteristics);
        if (index === -1) {
          this.characteristics.push(characteristics);
        }
      }
    }

    this.generalSettings = (await (
      await this.db
        .doc(`organisations/${this.organisationId}/settings/general`)
        .get()
        .toPromise()
    ).data()) as Setting;

    this.emailNotificationsParticipants.enabled =
      this.generalSettings.emailNotificationsParticipants ?? false;

    this.emailNotificationsParticipants.emailNotificationsParticipantsRideConfirmed =
      this.generalSettings.emailNotificationsParticipantsRideConfirmed ?? false;

    this.emailNotificationsParticipants.emailNotificationsParticipantsRideChanged =
      this.generalSettings.emailNotificationsParticipantsRideChanged ?? false;

    this.emailNotificationsParticipants.emailNotificationsParticipantsRideDeleted =
      this.generalSettings.emailNotificationsParticipantsRideDeleted ?? false;

    this.smsNotificationsParticipants.enabled =
      this.generalSettings.smsNotificationsParticipants ?? false;

    this.smsNotificationsParticipants.smsNotificationsParticipantsRideConfirmed =
      this.generalSettings.smsNotificationsParticipantsRideConfirmed ?? false;

    this.smsNotificationsParticipants.smsNotificationsParticipantsRideChanged =
      this.generalSettings.smsNotificationsParticipantsRideChanged ?? false;

    this.smsNotificationsParticipants.smsNotificationsParticipantsRideDeleted =
      this.generalSettings.smsNotificationsParticipantsRideDeleted ?? false;

    this.ignoreDriverStatus = this.generalSettings.ignoreDriverStatus;

    this.characteristicsStatus = this.generalSettings.characteristicsStatus ?? false;

    this.driverDoc = this.db.doc<User>(
      `organisations/${this.organisationId}/users/${this.data.ride.driverId}`
    );
    this.driver = this.driverDoc.valueChanges();
    this.driver.subscribe((driver) => {
      console.log('driver', driver);
    });
    this.ignoreDriverStatus = this.generalSettings.ignoreDriverStatus;
    this.showRideProduct = this.generalSettings.showRideProduct;

    if (!this.ignoreDriverStatus) {
      this.driverDoc = this.db.doc<User>(
        `organisations/${this.organisationId}/users/${this.data.ride.driverId}`
      );
      this.driver = this.driverDoc.valueChanges();
      this.driver.subscribe((driver) => {
        console.log('driver', driver);
      });
    }
  }

  editRide() {
    this.dialogRef.close();
    this.dialog.open(EditRideComponent, {
      width: '500px',
      data: { ride: this.data.ride },
    });
  }

  async generateRetour() {
    const retourId = this.db.createId();
    const retourRide = {} as Ride;

    const rideDuration = this.generalSettings.customRideDuration
      ? this.generalSettings.customRideDuration
      : 15;

    console.log('retourId', retourId);
    const start = new Date(this.data.ride.end).setMinutes(
      this.data.ride.end.getMinutes() + 15
    );
    const end = new Date(this.data.ride.end).setMinutes(
      this.data.ride.end.getMinutes() + 15 + rideDuration
    );
    retourRide.availableSeats = this.data.ride.availableSeats;
    retourRide.comments = this.data.ride.comments;
    retourRide.fromLocation = this.data.ride.toLocation;
    retourRide.toLocation = this.data.ride.fromLocation;
    retourRide.rideProduct = this.data.ride.rideProduct;
    retourRide.start = start;
    retourRide.end = end;
    retourRide.title = 'Retour ' + this.data.ride.title;
    retourRide.vehicleId = this.data.ride.vehicleId;
    retourRide.vehicleRef = this.data.ride.vehicleRef;
    retourRide.isRetour = true;
    retourRide.id = retourId;

    let retourUserObj = {};
    let retourUsers = [];
    this.users.forEach((val) => retourUsers.push(Object.assign({}, val)));

    retourUsers = retourUsers.reverse();
    retourUsers.forEach((user, i) => {
      if (!user) {
        return;
      }
      user.order = i;
      if (!user.rideProduct) {
        delete user.rideProduct;
        user.rideProduct = deleteField();
      }

      const newFrom = user.to;
      const newTo = user.from;
      const newFromType = user.toType;
      const newToType = user.fromType;
      user.from = newFrom;
      user.fromType = newFromType;
      user.to = newTo;
      user.toType = newToType;

      if (i === 0) {
        if (user.from.address) {
          retourRide.fromLocation = user.from.addres;
        } else {
          retourRide.fromLocation = user.from;
        }
      }
      if (i === retourUsers.length - 1) {
        if (user.to.address) {
          retourRide.toLocation = user.to.address;
        } else {
          retourRide.toLocation = user.to;
        }
      }
      console.log('user', user);
      retourUserObj[user.id] = user;
    });
    retourRide.users = retourUserObj as Map<string, any>;
    Object.keys(retourRide).forEach((key) => {
      if (retourRide[key] == null) {
        delete retourRide[key];
      }
    });

    this.dialogRef.close();
    this.dialog.open(EditRideComponent, {
      width: '500px',
      data: {
        // retourRide: result.retourRide,
        ride: retourRide, // result.ride
        rideId: this.data.ride.id,
        retourId: retourId,
        defaultVehicle: retourRide.vehicleId,
        // parentRideId: result.parentRideId,
        retourGenerating: true,
        // dates: { start, end },
        // users: result.users,
        dates: { start, end },
      },
    });
    // return retourRide;
  }
  async deleteRide() {
    let notificationBool = false;

    if (this.emailNotificationsParticipants.enabled) {
      notificationBool = this.emailNotificationsParticipants.enabled;
    }

    if (this.smsNotificationsParticipants.enabled) {
      notificationBool = this.smsNotificationsParticipants.enabled;
    }

    if (this.users.length === 0) {
      notificationBool = false;
    }

    const noDeleteReason = this.generalSettings.noDeleteReason ?? false;
    const deleteRef = this.dialog.open(DeleteRideComponent, {
      width: '600px',
      data: {
        notification: notificationBool,
        noDeleteReason: noDeleteReason,
      },
    });

    deleteRef.afterClosed().subscribe(async (deleteResult: any) => {
      console.log('The dialog was closed');
      if (deleteResult !== false) {
        /////////////
        if (this.data.ride.parentRideId) {
          // ride is part of group, ask if users wants to delete all
          const updateRef = this.dialog.open(CopyUpdateComponent, {
            width: '600px',
            data: {
              oldRide: this.data.ride,
              delete: true,
              deleteReason: deleteResult,
            },
          });
          updateRef.afterClosed().subscribe(async (result) => {
            console.log('The dialog was closed with result: ', result);
            // sucessfully deleted copies (or an error!)
            await this.backupRide(deleteResult);

            const rideRef = this.db
              .collection(`organisations/${this.organisationId}/rides`)
              .doc(this.data.ride.id);
            await rideRef.delete();
            this.notifyParticipants();

            if (result) {
              //all rides of the parentId of the selected ride are removed
              this._snackBar.open('De ritten zijn verwijderd.', 'X', {
                duration: 5000,
              });
            } else if (result === false) {
              //only selected ride is deleted
              this._snackBar.open('De rit is verwijderd.', 'X', {
                duration: 5000,
              });
            }
            // result undefined / user cancelled dialog, do nothing
          });
        } else {
          await this.backupRide(deleteResult);

          const rideRef = this.db
            .collection(`organisations/${this.organisationId}/rides`)
            .doc(this.data.ride.id);
          await rideRef.delete();
          this.notifyParticipants();
          this._snackBar.open('De rit is verwijderd.', 'X', {
            duration: 5000,
          });
        }
      }
    });
  }

  async backupRide(deleteResult: string) {
    const backupRideRef = this.db.firestore
      .collection(`eventLogs/${this.organisationId}/deletedRides`)
      .doc(this.data.ride.id);

    const backupRide = {} as any;
    backupRide.date = new Date();
    backupRide.doneByUser = this.userEmail;
    backupRide.doneByUid = this.userId;
    backupRide.deleteReason = deleteResult;
    backupRide.rideData = { ...this.data.ride };
    await backupRideRef.set(backupRide);
    await getDocs(
      this.db.firestore.collection(
        `organisations/${this.organisationId}/rides/${this.data.ride.id}/edits`
      )
    ).then((docs) => {
      docs.forEach((doc) => {
        const docRef = this.db.firestore
          .collection(
            `eventLogs/${this.organisationId}/deletedRides/${this.data.ride.id}/edits`
          )
          .doc(doc.id);
        setDoc(docRef, doc.data());
        deleteDoc(doc.ref);
      });
    });
    await getDocs(
      this.db.firestore.collection(
        `organisations/${this.organisationId}/rides/${this.data.ride.id}/scans`
      )
    ).then((docs) => {
      docs.forEach((doc) => {
        deleteDoc(doc.ref);
      });
    });
  }

  copyRide() {
    this.dialogRef.close();
    this.dialog.open(CopyRideComponent, {
      width: '500px',
      data: { ride: this.data.ride, vehicle: this.data.vehicle },
    });
  }

  viewEdits() {
    this.dialog.open(RideEditsComponent, {
      width: '500px',
      data: {
        ride: this.data.ride,
        vehicle: this.data.vehicle,
      },
      autoFocus: false,
    });
  }

  async notifyParticipants() {
    this.loading = true;
    if (
      (this.emailNotificationsParticipants.enabled ||
        this.smsNotificationsParticipants.enabled) &&
      (this.emailNotificationsParticipants
        .emailNotificationsParticipantsRideDeleted ||
        this.smsNotificationsParticipants
          .smsNotificationsParticipantsRideDeleted) &&
      this.users.length > 0
    ) {
      for (const user of this.users) {
        await this.deleteNotificationParticipant(
          this.data.ride.start,
          moment(new Date(this.data.ride.start)).format('DD-MM-YYYY'),
          moment(new Date(this.data.ride.start)).format('HH:mm'),
          this.organisationId,
          user
        );
      }
      this.loading = false;
      this.dialogRef.close();
    } else {
      this.loading = false;
      this.dialogRef.close();
    }
  }

  async deleteNotificationParticipant(
    rideDate,
    date: string,
    startRideTime: string,
    orgId: string,
    user
  ) {
    let sendMail = false;
    let sendSMS = false;

    const userData = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/users/${user.id}`
        )
      )
    ).data();

    if (!user?.smsNotificationPhoneNumber) {
      if (userData?.smsNotificationPhoneNumber) {
        user.smsNotificationPhoneNumber = userData.smsNotificationPhoneNumber;
      }
    }

    if (!user.email && !user?.smsNotificationPhoneNumber) {
      return;
    }

    if (user.notifyByEmail) {
      sendMail = true;
    } else {
      if (userData?.notifyByEmail) {
        sendMail = true;
      }
    }

    if (user.notifyBySMS) {
      sendSMS = true;
    } else {
      if (userData?.notifyBySMS) {
        sendSMS = true;
      }
    }

    if (
      !this.emailNotificationsParticipants
        .emailNotificationsParticipantsRideDeleted
    ) {
      sendMail = false;
    }

    if (
      !this.smsNotificationsParticipants.smsNotificationsParticipantsRideDeleted
    ) {
      sendSMS = false;
    }

    try {
      if (sendMail) {
        const callBody = {
          orgId: orgId,
          userName: user.title,
          mailType: 'rideDeleted',
          emailTo: user.email,
          optionalData: {
            date: rideDate,
            startTime: startRideTime,
          },
        };
        const callable = this.fns.httpsCallable('httpSendMail');
        const callRes = await lastValueFrom(callable(callBody));
        console.log(callRes);
      }

      if (sendSMS) {
        const callBody = {
          messageToSend: `Uw geplande rit op ${date} om ${startRideTime} is verwijderd.`,
          phoneTo: user?.smsNotificationPhoneNumber,
        };
        const callable = this.fns.httpsCallable('smsSendToCentral');
        const callRes = await lastValueFrom(callable(callBody));
        console.log(callRes);
      }
    } catch (error) {
      console.log(error);
    }
  }
}
