<div mat-dialog-title>
  {{ data.user.name }} <span>({{ data.user.credits }})</span>
  <div class="history-subtitle">Credits historie</div>
</div>
<div mat-dialog-content>
  <div
    class="history-list"
    *ngIf="!loading"
  >
    <div *ngIf="history.length === 0">
      Deze Deelnemer heeft nog geen credits gehad.
    </div>
    <div
      class="history-list-item"
      *ngFor="let historyItem of history"
    >
      <div class="item-title">{{ historyItem.description }}</div>
      <div
        class="item-change"
        *ngIf="historyItem.date && historyItem.type === 'incasso_notice'"
      >
        {{ historyItem.date.toDate() | date : 'd/MM/yyyy HH:mm' }}
      </div>
      <div
        class="item-change"
        *ngIf="historyItem.creditsAdded"
      >
        <span class="green">+{{ historyItem.creditsAdded }}</span>
        {{ historyItem.date.toDate() | date : 'd/MM/yyyy HH:mm' }}
      </div>
      <div
        class="item-change"
        *ngIf="historyItem.creditsSpent"
      >
        <span class="red">-{{ historyItem.creditsSpent }}</span>
        {{ historyItem.date.toDate() | date : 'd/MM/yyyy HH:mm' }}
      </div>
      <div
        class="item-info"
        *ngIf="historyItem.actionBy"
      >
        <div
          class="item-actionby"
          *ngIf="historyItem.actionBy"
        >
          Uitgevoerd door {{ historyItem.actionBy }}
        </div>
        <div
          class="item-remarks"
          *ngIf="historyItem.remarks"
        >
          "{{ historyItem.remarks }}"
        </div>
      </div>
    </div>
  </div>
</div>
