import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from 'src/app/interfaces';
import { ConfirmChangeAvailabilityComponent } from 'src/app/availability-driver/dialogs/confirm-change-availability/confirm-change-availability.component';
import { lastValueFrom } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-add-exception',
  templateUrl: './add-exception.component.html',
  styleUrls: ['./add-exception.component.scss'],
})
export class AddExceptionComponent implements OnInit {
  exceptionForm: UntypedFormGroup;
  organisationId = localStorage.getItem('orgId');

  hours = [
    { val: 0, text: '00' },
    { val: 1, text: '01' },
    { val: 2, text: '02' },
    { val: 3, text: '03' },
    { val: 4, text: '04' },
    { val: 5, text: '05' },
    { val: 6, text: '06' },
    { val: 7, text: '07' },
    { val: 8, text: '08' },
    { val: 9, text: '09' },
    { val: 10, text: '10' },
    { val: 11, text: '11' },
    { val: 12, text: '12' },
    { val: 13, text: '13' },
    { val: 14, text: '14' },
    { val: 15, text: '15' },
    { val: 16, text: '16' },
    { val: 17, text: '17' },
    { val: 18, text: '18' },
    { val: 19, text: '19' },
    { val: 20, text: '20' },
    { val: 21, text: '21' },
    { val: 22, text: '22' },
    { val: 23, text: '23' },
  ];

  minutes = [
    { val: 0, text: '00' },
    { val: 5, text: '05' },
    { val: 10, text: '10' },
    { val: 15, text: '15' },
    { val: 20, text: '20' },
    { val: 25, text: '25' },
    { val: 30, text: '30' },
    { val: 35, text: '35' },
    { val: 40, text: '40' },
    { val: 45, text: '45' },
    { val: 50, text: '50' },
    { val: 55, text: '55' },
  ];
  changerName: any;

  constructor(
    private fb: UntypedFormBuilder,
    private db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialog: MatDialog,
    private auth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private fns: AngularFireFunctions,
    public dialogRef: MatDialogRef<AddExceptionComponent>
  ) {}

  async ngOnInit(): Promise<any> {
    this.exceptionForm = this.fb.group({
      name: ['Niet beschikbaar', Validators.required],
      dateFrom: [, Validators.required],
      dateTo: [, Validators.required],
      startHour: [0o0, Validators.required],
      startMin: [0o0, Validators.required],
      endHour: [23, Validators.required],
      endMin: [55, Validators.required],
    });

    // const daypartsSettings = (await (
    //   await this.db
    //     .doc(`organisations/${this.organisationId}/dayparts/settings`)
    //     .get()
    //     .toPromise()
    // ).data()) as any;
    // this.hours = daypartsSettings.hours;
    // this.minutes = daypartsSettings.minutes;
    // this.exceptionForm.controls.startHour.setValue('00');
  }

  async addException() {
    if (this.exceptionForm.valid) {
      console.log('form value', this.exceptionForm.value);
      const dateFrom = this.exceptionForm.value.dateFrom;

      const timeFromHour = this.exceptionForm.value.startHour;
      const timeFromMin = this.exceptionForm.value.startMin;

      const newDateFrom = new Date(
        dateFrom.getFullYear(),
        dateFrom.getMonth(),
        dateFrom.getDate(),
        timeFromHour,
        timeFromMin
      );

      const dateTo = this.exceptionForm.value.dateTo;

      const timeToHour = this.exceptionForm.value.endHour;
      const timeToMin = this.exceptionForm.value.endMin;

      const newDateTo = new Date(
        dateTo.getFullYear(),
        dateTo.getMonth(),
        dateTo.getDate(),
        timeToHour,
        timeToMin
      );

      if (newDateTo < newDateFrom) {
        return this.snackBar.open(
          'De begin datum mag niet later zijn dan de eind datum',
          'X',
          {
            duration: 5000,
          }
        );
      }

      let filteredRides = [];

      const q = query(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/daypartDays`
        ),
        where('year', '>=', dateFrom.getFullYear()),
        where('driverId', '==', this.data.userId)
      );
      const daypartDays = await getDocs(q);

      if (!daypartDays.empty) {
        daypartDays.docs.forEach((daypartDay) => {
          const rideDate = `${daypartDay.data().year}-${
            daypartDay.data().month + 1
          }-${daypartDay.data().day}`;
          if (
            moment(rideDate).isSameOrAfter(dateFrom) &&
            moment(rideDate).isSameOrBefore(dateTo)
          ) {
            filteredRides.push(daypartDay);
          }
        });
      }

      if (filteredRides.length > 0) {
        const dialogRef = this.dialog.open(ConfirmChangeAvailabilityComponent, {
          width: '375px',
          disableClose: true,
          data: { availabilityType: 'exceptions' },
        });
        dialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            this.auth.user.subscribe(async (authUser) => {
              if (authUser) {
                const newUser = (
                  await getDoc(
                    doc(
                      this.db.firestore,
                      `organisations/${this.organisationId}/users/`,
                      `${authUser.uid}`
                    )
                  )
                ).data() as User
                this.changerName = newUser.name;
              }
            });
            const user = (
              await getDoc(
                doc(
                  this.db.firestore,
                  `organisations/${this.organisationId}/users/`,
                  `${this.data.userId}`
                )
              )
            ).data();
            const saveObj = {
              dateFrom: newDateFrom,
              dateTo: newDateTo,
              name: this.exceptionForm.value.name,
            };
            console.log('saveObj', saveObj);
            await this.db
              .doc(
                `organisations/${this.organisationId}/users/${this.data.userId}`
              )
              .set(
                {
                  exceptions: arrayUnion(saveObj),
                },
                { merge: true }
              );
            this.snackBar.open('Uitzondering toegevoegd', 'X', {
              duration: 4000,
            });
            this.dialogRef.close();

            try {
              const callBody = {
                orgId: this.organisationId,
                userName: 'planner',
                mailType: 'scheduleExceptions',
                emailTo: 'self',
                optionalData: {
                  date: moment(new Date()).format('DD-MM-YYYY'),
                  changedBy: this.changerName,
                  userChanged: user.name,
                  mailPlanners: true,
                },
              };
              const callable = this.fns.httpsCallable('httpSendMail');
              const callRes = await lastValueFrom(callable(callBody));
              console.log(callRes);
            } catch (error) {
              console.log(error);
            }
          }
        });
      } else {
        const saveObj = {
          dateFrom: newDateFrom,
          dateTo: newDateTo,
          name: this.exceptionForm.value.name,
        };
        console.log('saveObj', saveObj);
        await this.db
          .doc(`organisations/${this.organisationId}/users/${this.data.userId}`)
          .set(
            {
              exceptions: arrayUnion(saveObj),
            },
            { merge: true }
          );
        this.snackBar.open('Uitzondering toegevoegd', 'X', {
          duration: 4000,
        });
      }
    }
  }
}
