<h2 mat-dialog-title>Rit verplaatsen</h2>
<mat-dialog-content class="mat-typography">
  <h3>Weet je zeker dat je de datum of tijd van deze rit wilt wijzigen?</h3>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuleren</button>
  <button
    autofocus
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Wijzigen
  </button>
</mat-dialog-actions>
