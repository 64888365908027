<h1 mat-dialog-title *ngIf="data.type == 'add'">
  Hoeveel credits wil je toevoegen?
</h1>
<h1 mat-dialog-title *ngIf="data.type == 'remove'">
  Hoeveel credits wil je verminderen?
</h1>
<div mat-dialog-content>
  <form [formGroup]="creditForm">
    <mat-form-field>
      <mat-label>Credits</mat-label>
      <input
        matInput
        type="number"
        formControlName="credits"
        min="0"
        step="0.1"
        required
        cdkFocusInitial
      />
    </mat-form-field>
    <mat-form-field class="pin" *ngIf="pinEnabled">
      <mat-label>Pincode</mat-label>
      <input
        matInput
        formControlName="pin"
        type="number"
        required
        minlength="4"
        maxlength="4"
        autocapitalize="off"
        autocomplete="one-time-code"
        #pin
      />
      <mat-hint align="end">{{ pin.value.length }} / 4</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Opmerkingen</mat-label>
      <input
        matInput
        type="text"
        autocomplete="off"
        formControlName="remarks"
        cdkFocusInitial
      />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Annuleren</button>
  <button
    mat-flat-button
    [disabled]="!creditForm.valid"
    (click)="save()"
    *ngIf="data.type == 'add'"
  >
    Toevoegen
  </button>
  <button
    mat-flat-button
    [disabled]="!creditForm.valid"
    (click)="save()"
    *ngIf="data.type == 'remove'"
    class="error-button"
  >
    Verminderen
  </button>
</div>
