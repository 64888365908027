<app-nav-bar></app-nav-bar>
<div class="header">
  <div class="h-title">
    <mat-icon>perm_contact_calendar</mat-icon>
    <span>Rooster</span>
  </div>
</div>
<div class="sub-header">
  <div class="sub-header-left">
    <span class="accountType"> {{ accountType }}:</span>
    <span> {{ name }}</span>

    <div *ngIf="!isDriver" class="userSelection">
      <button
        mat-flat-button
        [matMenuTriggerFor]="typeMenu"
        class="user-button"
      >
        <div class="user-button-text">
          {{ selectedTypeName }}
          <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
      </button>
      <button
        mat-flat-button
        [matMenuTriggerFor]="driverMenu"
        class="user-button"
        *ngIf="selectedType == 'driver'"
      >
        <div class="user-button-text">
          {{ selectedName['driver'] }}
          <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
      </button>
      <button
        mat-flat-button
        [matMenuTriggerFor]="plannerMenu"
        class="user-button"
        *ngIf="selectedType == 'planner' && !isPlanner && !isDriver"
      >
        <div class="user-button-text">
          {{ selectedName['planner'] }}
          <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #typeMenu="matMenu" [overlapTrigger]="false">
        <button
          class="driver-button"
          mat-menu-item
        >
          <div (click)="changeType('driver')">
            <span>Chauffeur</span>
          </div>
        </button>
        <button
          class="driver-button"
          mat-menu-item
        >
          <div (click)="changeType('planner')">
            <span>Planner</span>
          </div>
        </button>
      </mat-menu>
      <mat-menu #driverMenu="matMenu" [overlapTrigger]="false">
        <button
          class="driver-button"
          mat-menu-item
          *ngFor="let driver of newDrivers | async"
        >
          <div (click)="changeUser(driver)">
            <span> {{ driver.name }}</span>
          </div>
        </button>
      </mat-menu>
      <mat-menu #plannerMenu="matMenu" [overlapTrigger]="false">
        <button
          class="driver-button"
          mat-menu-item
          *ngFor="let planner of newPlanners | async"
        >
          <div (click)="changeUser(planner)">
            <span> {{ planner.name }}</span>
          </div>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="driver-info" *ngIf="currentUser">
    <span class="content-info">
      E-mail:
      <a href="mailto:{{ currentUser.email }}">{{ currentUser.email }}</a>
    </span>
    <span class="content-info" *ngIf="currentUser.phone">
      Tel:
      <a href="tel:{{ currentUser.phone }}">{{ currentUser.phone }}</a>
    </span>
  </div>
</div>
<div class="wrapper" *ngIf="userId">
  <mat-tab-group animationDuration="500ms" mat-stretch-tabs>
    <mat-tab label="PLANNING">
      <app-planning 
        [getUserId]="userId"
        [type]="selectedType"
      ></app-planning>
    </mat-tab>
    <mat-tab label="BESCHIKBAAR">
      <app-availability
        [getUserId]="userId"
        [isDriver]="isDriver"
        [isPlanner]="isPlanner"
        [driverCanModify]="usersModifyAvailability"
        [type]="selectedType"
      ></app-availability>
    </mat-tab>
    <mat-tab label="UITZONDERINGEN">
      <app-exceptions
        [getUserId]="userId"
        [isDriver]="isDriver"
        [isPlanner]="isPlanner"
        [driverCanModify]="usersModifyAvailability"
      ></app-exceptions>
    </mat-tab>
    <mat-tab 
      label="VOERTUIGEN"
      *ngIf="this.isPlanner == false && this.selectedType != 'planner'"
    >
      <app-availability-vehicles
        [getUserId]="userId"
        [isDriver]="isDriver"
        [driverCanModify]="usersModifyAvailability"
      >
      </app-availability-vehicles>
    </mat-tab>
  </mat-tab-group>
</div>
<!-- <app-planning
  *ngIf="isDriver && !usersModifyAvailability"
  [getUserId]="userId"
></app-planning> -->
