<app-nav-bar></app-nav-bar>
<div class="deleted-rides">
  <div class="header">
    <div class="header-internals">
      <div class="header-title">
        <mat-icon>delete</mat-icon>
        <div>Prullenbak</div>
      </div>
      <div class="header-button-search">
        <button
          class="header-button"
          mat-raised-button
          (click)="exportDeletedRides()"
        >
          <mat-icon>file_download</mat-icon>
          Export
        </button>
        <form
          [formGroup]="filterForm"
          class="search"
        >
          <mat-form-field
            class="mat-form-field-no-subscript white-form-field secondary-color"
          >
            <mat-label class="search-label">Zoek in</mat-label>
            <mat-select
              formControlName="searchOn"
              (selectionChange)="searchFilter()"
              cdkFocusInitial
            >
              <mat-option value="all">Alles</mat-option>
              <mat-option value="start">Rit datum</mat-option>
              <mat-option value="vehicle">Voertuig</mat-option>
              <mat-option value="user">Deelnemer</mat-option>
              <mat-option value="doneByUser">Verwijderd door</mat-option>
              <mat-option value="deleteReason"
                >Reden van verwijderen</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="mat-form-field-no-subscript white-form-field secondary-color"
          >
            <mat-label class="search-label">Zoeken</mat-label>
            <input
              matInput
              placeholder="Zoeken"
              autocomplete="off"
              formControlName="search"
            />
            <button
              mat-icon-button
              matSuffix
              [disableRipple]="true"
              (click)="clearSearch()"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <div class="content-wrapper">
    <div *ngIf="loaded">
      <div class="content-header">
        <div class="deleted-rides-count">
          <h2>Verwijderde ritten ({{ filteredDeletedRides.length ?? 0 }})</h2>
        </div>
        <form
          class="period-filter"
          [formGroup]="dateForm"
        >
          <mat-form-field class="mat-form-field-no-subscript">
            <mat-label>Selecteer filter</mat-label>
            <mat-select
              (selectionChange)="periodFilter()"
              formControlName="selectedDateFilter"
            >
              <mat-option
                *ngFor="let filter of dateFilter"
                [value]="filter.value"
              >
                {{ filter.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="mat-form-field-no-subscript">
            <mat-label>Jaar</mat-label>
            <mat-select
              (selectionChange)="periodFilter()"
              formControlName="selectedYear"
            >
              <mat-option
                *ngFor="let year of years"
                [value]="year.value"
                >{{ year.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field 
            class="mat-form-field-no-subscript"
            *ngIf="dateForm.value.selectedDateFilter == 'month'"
          >
            <mat-label>Maand</mat-label>
            <mat-select
              (selectionChange)="periodFilter()"
              formControlName="selectedMonth"
            >
              <mat-option
                *ngFor="let month of months"
                [value]="month.value"
                >{{ month.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="mat-form-field-no-subscript"
            *ngIf="dateForm.value.selectedDateFilter == 'week'"
          >
            <mat-label>Week</mat-label>
            <mat-select
              (selectionChange)="periodFilter()"
              formControlName="selectedWeek"
            >
              <mat-option
                *ngFor="let week of weekArray"
                [value]="week"
                >{{ week }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="list-items">
        <div class="list-item-wrapper headers">
          <div class="list-item-col-wrapper">
            <div class="list-item-col">
              Ritdatum
              <button
                mat-icon-button
                (click)="sortColumn('ridestart')"
                matTooltip="Sorteren omdraaien"
                [disableRipple]="true"
              >
                <mat-icon
                  *ngIf="orderDirection == 'asc' && sortByColumn == 'ridestart'"
                >
                  arrow_upward
                </mat-icon>
                <mat-icon
                  *ngIf="
                    orderDirection == 'desc' && sortByColumn == 'ridestart'
                  "
                >
                  arrow_downward
                </mat-icon>
                <mat-icon *ngIf="sortByColumn != 'ridestart'">
                  compare_arrows
                </mat-icon>
              </button>
            </div>
            <div class="list-item-col">Voertuig</div>
            <div class="list-item-col">
              Verwijderd op
              <button
                mat-icon-button
                (click)="sortColumn('date')"
                matTooltip="Sorteren omdraaien"
                [disableRipple]="true"
              >
                <mat-icon
                  *ngIf="orderDirection == 'asc' && sortByColumn == 'date'"
                >
                  arrow_upward
                </mat-icon>
                <mat-icon
                  *ngIf="orderDirection == 'desc' && sortByColumn == 'date'"
                >
                  arrow_downward
                </mat-icon>
                <mat-icon *ngIf="sortByColumn != 'date'">
                  compare_arrows
                </mat-icon>
              </button>
            </div>
            <div class="list-item-col">Verwijderd door</div>
            <div class="list-item-col">Reden van verwijderen</div>
            <div class="list-item-col"></div>
          </div>
        </div>
        <cdk-virtual-scroll-viewport
          itemSize="50px"
          class="list-wrapper"
        >
          <div
            class="list-item-wrapper"
            *ngFor="let deletedRide of filteredDeletedRides"
            (click)="moreInformationDeletedRide(deletedRide)"
          >
            <div class="list-item-col-wrapper">
              <div class="list-item-col">
                {{
                  moment(deletedRide.rideData.start.toDate()).format(
                    'DD-MM-YYYY HH:mm uur'
                  ) ?? ''
                }}
              </div>
              <div class="list-item-col">
                {{ getVehicleName(deletedRide.rideData.vehicleId) ?? '' }}
              </div>
              <div class="list-item-col">
                {{
                  moment(deletedRide.date.toDate()).format('DD-MM-YYYY') ?? ''
                }}
              </div>
              <div class="list-item-col">
                {{ deletedRide.doneByUser ?? '' }}
              </div>
              <div class="list-item-col">
                {{ deletedRide.deleteReason ?? '' }}
              </div>
              <div class="list-item-col">
                <div class="list-item-buttons">
                  <button mat-icon-button>
                    <mat-icon>visibility</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    <div
      class="loading-wrapper"
      *ngIf="!loaded || loadingRides"
      [class.loading-rides]="loadingRides"
    >
      <div class="loading">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</div>
