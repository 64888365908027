import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Daypart, DaypartDay, Vehicle } from 'src/app/interfaces';
import { DaypartRideComponent } from '../planning/dialog/daypart-ride/daypart-ride.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
})
export class PlanningComponent implements OnInit {
  userId: string;
  userIdFromAdmin: string;

  VehicleDoc: AngularFirestoreDocument<Vehicle>;
  vehicle: Observable<Vehicle>;

  driverDayparts: any[] = [];
  plannerDayparts: any[] = [];
  organisationId = localStorage.getItem('orgId');
  loading = true;

  @Input() set getUserId(userId: string) {
    this.userIdFromAdmin = userId ? userId : null;
    // console.log('value', value);
    this.zone.run(() => {
      this.getPlannedUsers();
      this.cdr.detectChanges();
    });
  }
  @Input() type: string;

  constructor(
    private db: AngularFirestore,
    private auth: AngularFireAuth,
    private dialog: MatDialog,
    private zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // this.getPlannedUsers();
  }

  async getPlannedUsers() {
    this.auth.authState.subscribe(async (user) => {
      this.userId = this.userIdFromAdmin ? this.userIdFromAdmin : user.uid;
      let dayParts: any;

      if (this.type == 'driver') {
        dayParts = this.db
          .collection(`organisations/${this.organisationId}/daypartDays`, (ref) =>
            ref
              .where('driverId', '==', this.userId)
              .where('date', '>', new Date(new Date().setHours(0, 0, 0, 0)))
              .orderBy('date')
              .limit(25)
          );
      } else if (this.type == 'planner') {
        dayParts = this.db
          .collection(`organisations/${this.organisationId}/daypartDays`, (ref) =>
            ref
              .where('plannerIds', 'array-contains', this.userId)
              .where('date', '>', new Date(new Date().setHours(0, 0, 0, 0)))
              .orderBy('date')
              .limit(25)
          );
      }

      dayParts
        .valueChanges({ idField: 'id' })
        .subscribe(async (dayparts) => {
          // trigger subscribe so all data gets refreshed
          console.log('dayparts', dayparts);
          let daypartsArray = [];

          let doneWithCount = 0;
          if (dayparts.length === 0) {
            this.loading = false;
            if (this.type == 'driver') {
              return (this.driverDayparts = []);
            } else if (this.type == 'planner') {
              return (this.plannerDayparts = []);
            }
          }
          dayparts.forEach(async (userDaypart: DaypartDay, index: number) => {
            const daypartDay = (await (
              await this.db
                .doc(
                  `organisations/${this.organisationId}/dayparts/${userDaypart.daypartId}`
                )
                .get()
                .toPromise()
            ).data()) as Daypart;

            if (daypartDay) {
              console.log('daypartDay', daypartDay);
              const daypart = daypartDay.daypart;
              const saveObj = {} as any;
              saveObj.date = userDaypart.date.toDate();
              saveObj.start = userDaypart.start.toDate();
              saveObj.end = userDaypart.end.toDate();
              saveObj.dayNumber = userDaypart.day;
              saveObj.monthNumber = userDaypart.month;
              saveObj.yearNumber = userDaypart.year;
              switch (daypart.day) {
                case 0:
                  saveObj.day = 'Maandag';
                  break;
                case 1:
                  saveObj.day = 'Dinsdag';
                  break;
                case 2:
                  saveObj.day = 'Woensdag';
                  break;
                case 3:
                  saveObj.day = 'Donderdag';
                  break;
                case 4:
                  saveObj.day = 'Vrijdag';
                  break;
                case 5:
                  saveObj.day = 'Zaterdag';
                  break;
                case 6:
                  saveObj.day = 'Zondag';
                  break;
              }

              if (this.type == 'driver') {
                saveObj.vehicleId = userDaypart.vehicleId;
                saveObj.vehicle = (await (
                  await this.db
                    .doc(
                      `organisations/${this.organisationId}/vehicles/${userDaypart.vehicleId}`
                    )
                    .get()
                    .toPromise()
                ).data()) as Vehicle;
                saveObj.driverId = userDaypart.driverId;
              }

              // format time
              saveObj.startHour = userDaypart.customStart
                ? `0${userDaypart.startHour}`.slice(-2)
                : `0${daypart.startHour}`.slice(-2);
              saveObj.startMin = userDaypart.customStart
                ? `0${userDaypart.startMinute}`.slice(-2)
                : `0${daypart.startMin}`.slice(-2);
              saveObj.endHour = userDaypart.customEnd
                ? `0${userDaypart.endHour}`.slice(-2)
                : `0${daypart.endHour}`.slice(-2);
              saveObj.endMin = userDaypart.customEnd
                ? `0${userDaypart.endMinute}`.slice(-2)
                : `0${daypart.endMin}`.slice(-2);

              saveObj.id = userDaypart.id;
              daypartsArray.push(saveObj);

              // console.log('doneWithCount', doneWithCount);
              // console.log('dayparts.length', dayparts.length);
            }
            doneWithCount++;
            console.log('doneWithCount', doneWithCount);
            console.log('dayparts.length', dayparts.length);
            if (doneWithCount === dayparts.length) {
              const sortedDaypartsArray = daypartsArray.sort(function (a, b) {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
              });

              if (this.type == 'driver') {
                this.driverDayparts = sortedDaypartsArray;
              } else if (this.type == 'planner') {
                this.plannerDayparts = sortedDaypartsArray;
              }
              this.loading = false;
            }
          });
        });
    });
  }

  openDaypartRideDialog(driverDayPart: any) {
    console.log('driverDayPart', driverDayPart);
    this.dialog.open(DaypartRideComponent, {
      width: '575px',
      data: {
        driverId: driverDayPart.driverId,
        vehicleId: driverDayPart.vehicleId,
        dayNumber: driverDayPart.dayNumber,
        monthNumber: driverDayPart.monthNumber,
        yearNumber: driverDayPart.yearNumber,
        date: driverDayPart.date,
        start: driverDayPart.start,
        end: driverDayPart.end,
      },
    });
  }

  compare(a, b) {
    if (a.startString < b.startString) {
      return -1;
    }
    if (a.startHour > b.startHour) {
      return 1;
    }
    return 0;
  }
  get getUserId() {
    return this.userIdFromAdmin;
  }
}
