import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateFilter, Month, Year } from 'src/app/interfaces';
import moment from 'moment';

@Component({
  selector: 'app-export-deleted-rides',
  templateUrl: './export-deleted-rides.component.html',
  styleUrls: ['./export-deleted-rides.component.scss']
})
export class ExportDeletedRidesComponent implements OnInit {
  years: Year[] = [];
  months: Month[] = [
    { value: 0, viewValue: 'Januari' },
    { value: 1, viewValue: 'Februari' },
    { value: 2, viewValue: 'Maart' },
    { value: 3, viewValue: 'April' },
    { value: 4, viewValue: 'Mei' },
    { value: 5, viewValue: 'Juni' },
    { value: 6, viewValue: 'Juli' },
    { value: 7, viewValue: 'Augustus' },
    { value: 8, viewValue: 'September' },
    { value: 9, viewValue: 'Oktober' },
    { value: 10, viewValue: 'November' },
    { value: 11, viewValue: 'December' },
  ];
  dateFilter: DateFilter[] = [
    { value: 'year', viewValue: 'Jaar' },
    { value: 'month', viewValue: 'Maand' },
    { value: 'week', viewValue: 'Week' },
    { value: 'day', viewValue: 'Dag' },
  ];
  exportForm: UntypedFormGroup = this.fb.group({
    selectedFilter: ['year'],
    year: [moment().year(), Validators.required],
    month: [moment().month(), Validators.required],
    week: [moment().week(), Validators.required],
    day: [moment().toDate(), Validators.required],
  });

  weeks: number[] = [];
  days: number[] = [];
  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ExportDeletedRidesComponent>,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentWeek = parseInt(moment(date).format('W'));
    const years: Year[] = [];
    let yearInStep = currentYear;
    for (let step = 0; step < 10; step++) {
      years.push({ value: yearInStep, viewValue: yearInStep.toString() });
      yearInStep = yearInStep - 1;
    }
    this.years = years;
    this.years.forEach((year) => {
      if (year.value === currentYear) {
        this.exportForm.value.year = year.value;
      }
    });
    this.months.forEach((month) => {
      if (month.value === currentMonth) {
        this.exportForm.value.month = month.value;
      }
    });
    for (let step = 1; step < 54; step++) {
      this.weeks.push(step);
      if (step === currentWeek) {
        this.exportForm.value.week = step;
      }
    }
    for (let step = 1; step < 8; step++) {
      this.days.push(step);
    }
  }

  periodChange() {
    switch (this.exportForm.value.selectedFilter) {
      case 'year':
        this.exportForm.controls.year.enable();
        this.exportForm.controls.month.disable();
        this.exportForm.controls.day.disable();
        break;
      case 'month':
        this.exportForm.controls.year.enable();
        this.exportForm.controls.month.enable();
        this.exportForm.controls.day.disable();
        break;
      case 'week':
        this.exportForm.controls.year.enable();
        this.exportForm.controls.week.enable();
        this.exportForm.controls.day.disable();
        break;
      case 'day':
        this.exportForm.controls.year.disable();
        this.exportForm.controls.week.disable();
        this.exportForm.controls.day.enable();
        break;
    }
  }

  close() {
    const exportForm = this.exportForm.value;
    if (!exportForm.selectedFilter) {
      return this.snackbar.open('Selecteer een filter', 'X', {
        duration: 5000,
      });
    }
    let valueToExport = {};
    valueToExport['type'] = exportForm.selectedFilter;
    switch (exportForm.selectedFilter) {
      case 'day':
        valueToExport['day'] = exportForm.day.getDate();
        valueToExport['month'] = exportForm.day.getMonth();
        valueToExport['year'] = exportForm.day.getFullYear();
        break;
      case 'week':
        valueToExport['week'] = `${exportForm.year}-${exportForm.week}`;
        break;
      case 'month':
        valueToExport['month'] = exportForm.month;
        valueToExport['year'] = exportForm.year;
        break;
      case 'year':
        valueToExport['year'] = exportForm.year;
        break;
    }
    if (!valueToExport) {
      return this.snackbar.open('Vul een waarde in', 'X', {
        duration: 5000,
      });
    }
    this.dialogRef.close(valueToExport);
  }
}
