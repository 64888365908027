import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { environment } from 'src/environments/environment';

export const app = initializeApp(environment.firebase);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-west1');
export const auth = getAuth(app);
export const storage = getStorage(app);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // primaryColor =
  //   localStorage.getItem('primaryColor') ??
  //   document.documentElement.style.getPropertyValue('--primary');
  // secondaryColor =
  //   localStorage.getItem('secondaryColor') ??
  //   document.documentElement.style.getPropertyValue('--secondary');
  // primaryColorPalette: Color[] = [];
  // accentColorPalette: Color[] = [];
  orgName = localStorage.getItem('orgName') ?? 'Buurtvervoer';
  icons: string[] = [
    'bus-lift',
    'cane',
    'child-seat',
    'escort',
    'guide-dog',
    'rollator',
    'wheelchair',
  ];

  constructor(
    private titleService: Title,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    this.titleService.setTitle(this.orgName);
    // this.savePrimaryColor();
    // this.saveAccentColor();
    document.body.classList.remove(localStorage.getItem('theme'));
    if (!localStorage.getItem('theme')) {
      localStorage.setItem('theme', 'default');
    }
    document.body.classList.add(localStorage.getItem('theme'));
    this.icons.forEach((icon) => {
      iconRegistry.addSvgIcon(
        icon,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`)
      );
    });
  }

  // savePrimaryColor() {
  //   this.primaryColorPalette = computeColors(this.primaryColor);
  //   updateTheme(this.primaryColorPalette, 'primary');
  // }

  // saveAccentColor() {
  //   this.accentColorPalette = computeColors(this.accentColor);
  //   updateTheme(this.accentColorPalette, 'accent');
  // }
  ngOnInit(): void {}
}
