import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { VehiclesComponent } from '../../../vehicles/vehicles.component';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
// import { ThemePalette } from '@angular/material';
import { Setting, Vehicle } from './../../../../interfaces';
import { CustomValidators } from 'src/app/validators/custom-validators';

@Component({
  selector: 'app-create-edit-vehicles',
  templateUrl: './create-edit-vehicles.component.html',
  styleUrls: ['./create-edit-vehicles.component.scss'],
})
export class CreateEditVehiclesComponent implements OnInit {
  vehicleForm: UntypedFormGroup;
  color: string = this.data.vehicle?.calendarStyle?.color ?? '#ffffff';
  backgroundColorEmpty: string =
    this.data.vehicle?.calendarStyle?.backgroundColorEmpty ?? '#efefef';
  backgroundColorFull: string =
    this.data.vehicle?.calendarStyle?.backgroundColorFull ?? '#efefef';
  backgroundColorNoDriver: string =
    this.data.vehicle?.calendarStyle?.backgroundColorNoDriver ?? '#efefef';
  orderNumbersArray: number[] = this.data.orderNumbersArray ?? [];
  toggle: boolean;
  toggle2: boolean;
  toggle3: boolean;
  toggle4: boolean;
  isNew: boolean = this.data.vehicle ? false : true;
  isActive: boolean = this.data.vehicle?.active ?? false;
  totalActive: number;
  settingDoc: AngularFirestoreDocument<Setting>;
  setting: Observable<Setting>;
  maxTotalVehicle: number;
  organisationsId = localStorage.getItem('orgId');
  ignoreDriverStatus: boolean = true;
  showDriverPlanning: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    public db: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VehiclesComponent>
  ) {}

  ngOnInit() {
    this.showDriverPlanning = JSON.parse(
      localStorage.getItem('usesDriverPlanning')
    );
    this.settingDoc = this.db.doc<Setting>(
      `organisations/${this.organisationsId}/settings/general`
    );
    this.setting = this.settingDoc.valueChanges();
    this.setting.subscribe((val) => {
      this.maxTotalVehicle = val.maxActiveVehicles ? val.maxActiveVehicles : 10;
      this.ignoreDriverStatus = val.ignoreDriverStatus ?? false;
    });
    this.totalActive = this.data.totalActive;
    this.vehicleForm = this.fb.group({
      name: [this.data.vehicle?.name ?? '', Validators.required],
      seats: [this.data.vehicle?.seats ?? '', Validators.required],
      color: [this.color, Validators.required],
      sortingNumber: [
        this.data.vehicle?.calendarStyle?.sortingNumber ?? '',
        [
          Validators.required,
          CustomValidators.orderValidator(this.orderNumbersArray,
          this.data.vehicle ? true : false,
          this.data?.vehicle?.calendarStyle?.sortingNumber),
         CustomValidators.numberInput(false, false, 0)
      ]],
      backgroundColorEmpty: [this.backgroundColorEmpty],
      backgroundColorFull: [this.backgroundColorFull],
      backgroundColorNoDriver: [this.backgroundColorNoDriver],
      description: [this.data.vehicle?.description ?? ''],
      active: [
        {
          value: this.isActive,
          disabled: this.totalActive >= this.maxTotalVehicle && !this.isActive,
        },
      ],
    });
    this.vehicleForm.get('color').valueChanges.subscribe((value) => {
      if (value === '') {
        value = '#';
        this.color = '#';
      }
    });
    this.vehicleForm
      .get('backgroundColorFull')
      .valueChanges.subscribe((value) => {
        if (value === '') {
          value = '#';
          this.backgroundColorFull = '#';
        }
      });
    this.vehicleForm
      .get('backgroundColorEmpty')
      .valueChanges.subscribe((value) => {
        if (value === '') {
          value = '#';
          this.backgroundColorEmpty = '#';
        }
      });
  }

  async onSubmit() {
    const formValue = this.vehicleForm.value;
    // tslint:disable-next-line: prefer-const
    if (this.isNew) {
      formValue.id = this.db.createId();
    } else {
      formValue.id = this.data.vehicle.id;
    }

    formValue.calendarStyle = {};
    if (formValue.color) {
      formValue.calendarStyle.color = this.color;
      delete formValue.color;
    }
    if (formValue.backgroundColorEmpty) {
      formValue.calendarStyle.backgroundColorEmpty = this.backgroundColorEmpty;
      delete formValue.backgroundColorEmpty;
    }
    if (formValue.backgroundColorFull) {
      formValue.calendarStyle.backgroundColorFull = this.backgroundColorFull;
      delete formValue.backgroundColorFull;
    }
    if (formValue.backgroundColorNoDriver) {
      formValue.calendarStyle.backgroundColorNoDriver =
        this.backgroundColorNoDriver;
      delete formValue.backgroundColorNoDriver;
    }
    if (formValue.sortingNumber) {
      formValue.calendarStyle.sortingNumber = formValue.sortingNumber;
      delete formValue.sortingNumber;
    }

    let submitItem = formValue;

    await this.saveItem(submitItem);
  }
  async saveItem(submitItem) {
    const batch = this.db.firestore.batch();
    batch.set(
      this.db
        .collection(`organisations/${this.organisationsId}/vehicles`)
        .doc(submitItem.id).ref,
      submitItem,
      { merge: true }
    );

    await batch.commit();
    this.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close();
  }
}
