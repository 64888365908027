import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { User, History } from 'src/app/interfaces';
import { db } from 'src/app/app.component';

export interface DialogData {
  user: User;
}

@Component({
  selector: 'app-credits-history',
  templateUrl: './credits-history.component.html',
  styleUrls: ['./credits-history.component.scss'],
})
export class CreditsHistoryComponent implements OnInit {
  loading: boolean = true;
  organisationId: string = localStorage.getItem('orgId');
  history: History[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  async ngOnInit() {
    const chargeDocs = await getDocs(
      query(
        collection(db, `organisations/${this.organisationId}/charges`),
        where('userRef', '==', doc(db, `users/${this.data.user.id}`)),
        orderBy('date', 'desc')
      )
    );
    chargeDocs.forEach((charge) => {
      this.history.push({ ...charge.data(), id: charge.id } as History);
    });

    const paymentDocs = await getDocs(
      query(
        collection(db, `organisations/${this.organisationId}/payments`),
        where('userRef', '==', doc(db, `users/${this.data.user.id}`)),
        orderBy('date', 'desc')
      )
    );
    paymentDocs.forEach((charge) => {
      this.history.push({ ...charge.data(), id: charge.id } as History);
    });

    this.history = this.history.sort((itemA, itemB) => {
      return itemB.date.toDate() - itemA.date.toDate();
    });

    this.loading = false;
  }
}
