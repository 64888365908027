<div mat-dialog-title>Wachtwoord van een account veranderen</div>

<div mat-dialog-content class="adjust-password">
  <form
    [formGroup]="userForm"
  >
    <mat-form-field>
      <mat-label>E-mail</mat-label>
      <input
        formControlName="email"
        type="email"
        matInput
        required
      />
      <mat-error *ngIf="userForm.controls.email.errors?.required">
        Je moet dit veld invullen
      </mat-error>
      <mat-error *ngIf="userForm.controls.email.errors?.email">
        Ongeldige e-mail
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Wachtwoord</mat-label>
      <input
        formControlName="password"
        matInput
        required
      />
      <mat-error *ngIf="userForm.controls.password.errors?.required">
        Je moet dit veld invullen
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Wachtwoord bevestigen </mat-label>
      <input
        formControlName="passwordVerification"
        matInput
        required
      />
      <mat-error
        *ngIf="userForm.controls.passwordVerification.errors?.required"
      >
        Je moet dit veld invullen
      </mat-error>
      <mat-error
        *ngIf="userForm.controls.passwordVerification.errors?.customError"
      >
        Wachtwoorden komen niet overeen
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
  >
    Annuleren
  </button>
  <button
    [class.spinner]="saving"
    mat-flat-button
    [disabled]="!userForm.valid || saving"
    (click)="save()"
    cdkFocusInitial
  >
    OPSLAAN
  </button>
</div>
