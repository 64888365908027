import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  dialogType: string;
  optionalData?: {
    maxTotalVehicles?: number;
    sepaMandateId?: string;
    plannerName?: string;
  };
}

@Component({
  selector: 'app-ride-error',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningDialog implements OnInit {
  text: string;
  title: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
    switch (this.data.dialogType) {
      case 'importFailedTariffs':
        this.title = 'Importeren mislukt';
        this.text =
          'Er kan maar 1 standaard rit zijn. Pas dit aan en probeer opnieuw.';
        break;

      case 'importFailedCars':
        this.title = 'Importeren mislukt';
        this.text = `Er mogen maximaal ${this.data.optionalData.maxTotalVehicles} actieve voertuigen zijn. Pas dit aan en probeer opnieuw.`;
        break;

      case 'uploadSepaMandateIdCheck':
        this.title = 'Controleer machtigingskenmerk';
        this.text =
          'Komt het kenmerk van de SEPA machtiging overeen met met het hieronder genoemde machtigingskenmerk?';
        break;

      case 'retourAlreadyBooked':
        this.title = 'Retourrit al bekend!';
        this.text =
          'Er bestaat al een retourrit voor deze rit.\nWeet je zeker dat je dat deze retourrit wilt inplannen?';
        break;

      case 'vehicleAlreadyBooked':
        this.title = 'Dubbele boeking!';
        this.text =
          'Er is al een rit voor dit voertuig gepland op dit tijdstip.';
        break;

      case 'userInRide':
        this.title = 'Dubbele boeking!';
        this.text =
          'Deze deelnemer is voor dit tijdslot al in een auto geboekt.\nWeet je zeker dat je de deelnemer aan deze rit wilt toevoegen?';
        break;

      case 'driverInVehicle':
        this.title = 'Dubbele boeking!';
        this.text =
          'Deze chauffeur is voor dit tijdslot al in een auto geboekt.\nWeet je zeker dat je de chauffeur aan deze rit wilt toevoegen?';
        break;

      case 'plannerInDaypart':
        this.title = 'Dubbele boeking!';
        this.text =
          `${this.data.optionalData.plannerName} is voor dit tijdslot al in een dagdeel ingepland.\nWeet je zeker dat je ${this.data.optionalData.plannerName} bij dit dagdeel wilt inplannen?`;
        break;
    }
  }
}
