<app-nav-bar></app-nav-bar>
<div class="users">
  <div class="header">
    <div class="h-title">
      <mat-icon>directions_car</mat-icon>
      <span>Voertuigen beheren</span>
    </div>
    <div class="export-button">
      <button
        mat-raised-button
        (click)="openImportExport()"
      >
        <mat-icon>import_export</mat-icon>
        <span>Importeren/Exporteren</span>
      </button>
    </div>
  </div>
  <mat-card
    class="room-below-card"
    *ngIf="vehicles; else loading"
    style="padding: 24px"
  >
    <div class="title">
      <h3>
        Alle voertuigen <span>({{ totalVehicles }})</span>
      </h3>
      <div class="search">
        <mat-form-field class="mat-form-field-no-subscript">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label class="placeholder">Zoeken</mat-label>
          <input
            matInput
            autocomplete="off"
            placeholder="Zoeken"
            [(ngModel)]="searchQuery"
            (ngModelChange)="onFilterChange('search', $event)"
          />
        </mat-form-field>
      </div>
    </div>
    <div #TABLE>
      <mat-table
        mat-table
        id="tblData"
        #table
        [dataSource]="filteredVehicles"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Naam</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <p
              class="text-truncate"
              *ngIf="vehicle.name"
            >
              {{ vehicle.name }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- seats Column -->
        <ng-container matColumnDef="seats">
          <mat-header-cell *matHeaderCellDef>Zitplaatsen</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <p
              *ngIf="vehicle.seats"
              class="email text-truncate"
            >
              {{ vehicle.seats }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- mileage Column -->
        <ng-container matColumnDef="currentMileage">
          <mat-header-cell *matHeaderCellDef>Kilometerstand</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <p
              *ngIf="vehicle.currentMileage"
              class="email text-truncate"
            >
              {{ vehicle.currentMileage }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- color Column -->
        <ng-container matColumnDef="color">
          <mat-header-cell *matHeaderCellDef>Auto kleur</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <div
              class="dot"
              [ngStyle]="{ 'background-color': vehicle.calendarStyle.color }"
            ></div>
            <p class="phone text-truncate">
              {{ vehicle.calendarStyle.color }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- sortingNumber Column -->
        <ng-container matColumnDef="sortingNumber">
          <mat-header-cell *matHeaderCellDef>Volgordenummer</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <p class="phone text-truncate">
              {{ vehicle.calendarStyle.sortingNumber }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- colorEmpty Column -->
        <ng-container matColumnDef="backgroundColorEmpty">
          <mat-header-cell *matHeaderCellDef>Rit leeg kleur</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <div
              class="dot"
              [ngStyle]="{
                'background-color': vehicle.calendarStyle.backgroundColorEmpty
              }"
            ></div>
            <p class="phone text-truncate">
              {{ vehicle.calendarStyle.backgroundColorEmpty }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- colorFull Column -->
        <ng-container matColumnDef="backgroundColorFull">
          <mat-header-cell *matHeaderCellDef>Rit vol kleur</mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <div
              class="dot"
              [ngStyle]="{
                'background-color': vehicle.calendarStyle.backgroundColorFull
              }"
            ></div>
            <p class="phone text-truncate">
              {{ vehicle.calendarStyle.backgroundColorFull }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- colorEmptyDriver Column -->
        <ng-container
          matColumnDef="backgroundColorNoDriver"
          *ngIf="showDriverPlanning && !ignoreDriverStatus"
        >
          <mat-header-cell *matHeaderCellDef
            >Geen chauffeur kleur</mat-header-cell
          >
          <mat-cell *matCellDef="let vehicle">
            <div
              class="dot"
              [ngStyle]="{
                'background-color':
                  vehicle.calendarStyle.backgroundColorNoDriver ?? '#efefef'
              }"
            ></div>
            <p class="phone text-truncate">
              {{ vehicle.calendarStyle.backgroundColorNoDriver ?? '#efefef' }}
            </p>
          </mat-cell>
        </ng-container>

        <!-- button Column -->
        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let vehicle">
            <div class="buttons">
              <button
                mat-icon-button
                [disabled]="!vehicle.active"
                [matMenuTriggerFor]="moreMenu"
                aria-label="More"
                (click)="$event.stopPropagation()"
              >
                <mat-icon class="secondary-text">more_vert</mat-icon>
              </button>

              <mat-menu #moreMenu="matMenu">
                <button
                  mat-menu-item
                  (click)="editVehicle(vehicle)"
                >
                  <span>Bewerken</span>
                </button>
                <!-- <button mat-menu-item (click)="deactivateVehicle(vehicle)">
                                    <span>Inactief</span>
                                </button> -->
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="
            !showDriverPlanning || ignoreDriverStatus
              ? displayedColumnsWithout
              : displayedColumnsWith
          "
        ></tr>
        <tr
          mat-row
          [ngClass]="{ 'card-inactive': !vehicle.active }"
          *matRowDef="
            let vehicle;
            columns: !showDriverPlanning || ignoreDriverStatus
              ? displayedColumnsWithout
              : displayedColumnsWith
          "
          class="user"
          (click)="editVehicle(vehicle)"
          matRipple
        ></tr>
      </mat-table>
    </div>
  </mat-card>
  <ng-template #loading>
    <div id="loading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  <input
    hidden
    type="file"
    accept=".xlsx"
    #uploader
    (change)="uploadedFile($event)"
  />
</div>
<button
  mat-fab
  class="fab-fixed"
  (click)="editVehicle()"
>
  <mat-icon>add</mat-icon>
</button>
