<div class="wrapper">
  <mat-card>
    <mat-card-content>
      <mat-selection-list role="list" [multiple]="false" *ngIf="type == 'driver'">
        <h3>
          Klik op een dagdeel waarvoor jij bent ingepland om de details van je
          rit(ten) te zien.
        </h3>
        <mat-list-option
          *ngFor="let driverDaypart of driverDayparts"
          (click)="openDaypartRideDialog(driverDaypart)"
          (keyup.enter)="openDaypartRideDialog(driverDaypart)"
          class="ride-item"
        >
          <div class="startTime">
            <h4>{{ driverDaypart.name }}</h4>
            <div class="driver-planned-dayparts">
              <div class="planned-rides-driver" [ngStyle]="{ 'border-left-color': driverDaypart.vehicle.calendarStyle.color }">
                <h5 style="margin-top: 0 !important;">
                  Datum: {{ driverDaypart.day }},
                  {{ driverDaypart.date | date: 'mediumDate' }}
                </h5>
                <div class="flex">
                  <h5>
                    Van:
                    {{ driverDaypart.startHour }}:{{ driverDaypart.startMin }}
                  </h5>
                  <h5>
                    Tot:
                    {{ driverDaypart.endHour }}:{{ driverDaypart.endMin }}
                  </h5>
                </div>
                <div class="flex">
                  <h5 *ngIf="driverDaypart.vehicle">Voertuig: {{ driverDaypart.vehicle.name }}</h5>
                </div>
              </div>
            </div>
          </div>
        </mat-list-option>
        <p *ngIf="driverDayparts.length === 0 && loading === false">
          Je bent nog nergens voor ingepland
        </p>
      </mat-selection-list>

      <mat-selection-list role="list" [multiple]="false" *ngIf="type == 'planner'">
        <mat-list-option
          *ngFor="let plannerDayPart of plannerDayparts"
          class="ride-item"
        >
          <div class="startTime">
            <h4>{{ plannerDayPart.name }}</h4>
            <div class="driver-planned-dayparts">
              <div class="planned-rides-driver">
                <h5 style="margin-top: 0 !important;">
                  Datum: {{ plannerDayPart.day }},
                  {{ plannerDayPart.date | date: 'mediumDate' }}
                </h5>
                <div class="flex">
                  <h5>
                    Van:
                    {{ plannerDayPart.startHour }}:{{ plannerDayPart.startMin }}
                  </h5>
                  <h5>
                    Tot:
                    {{ plannerDayPart.endHour }}:{{ plannerDayPart.endMin }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </mat-list-option>
        <p *ngIf="plannerDayparts.length === 0 && loading === false">
          Je bent nog nergens voor ingepland
        </p>
      </mat-selection-list>
    </mat-card-content>
  </mat-card>
</div>

