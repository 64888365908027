<app-nav-bar></app-nav-bar>
<div class="header">
  <div class="h-title">
    <mat-icon>date_range</mat-icon>
    <span>Dagdelen</span>
  </div>
</div>
<div
  class="wrapper"
  *ngIf="this.usesPlannerPlanning"
>
  <mat-tab-group
    animationDuration="500ms" 
    mat-stretch-tabs
    (selectedTabChange)="changeTab($event)"
  >
    <mat-tab label="CHAUFFEUR"></mat-tab>
    <mat-tab label="PLANNER"></mat-tab>
  </mat-tab-group>
</div>
<div class="wrapper room-below-card">
  <mat-card>
    <mat-card-header> </mat-card-header>
    <mat-card-content>
      <mat-list role="list">
        <h3 *ngIf="newDayparts.length === 0 && loading === false">
          Er zijn geen dagdelen ingesteld
        </h3>
        <mat-list-item
          *ngFor="let daypart of newDayparts"
          matRipple
          (click)="editDaypart(daypart, daypart.id)"
        >
          <div class="startTime">
            <h4>{{ daypart.name }}</h4>
            <div class="dayparts">
              <div class="time">
                <h5>
                  Van:
                  {{ daypart.startHour }}:{{ daypart.startMin }}
                </h5>
                <h5>
                  Tot:
                  {{ daypart.endHour }}:{{ daypart.endMin }}
                </h5>
                <!-- <h5 *ngIf="daypart.name">({{ daypart.name }})</h5> -->
              </div>

              <div class="button-group">
                <button
                  mat-icon-button
                  (click)="
                    $event.stopPropagation(); editDaypart(daypart, daypart.id)
                  "
                >
                  <mat-icon> edit </mat-icon>
                </button>

                <button
                  mat-icon-button
                  (click)="$event.stopPropagation(); removeDaypart(daypart.id)"
                >
                  <mat-icon> delete </mat-icon>
                </button>
              </div>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
<button mat-fab class="fab-fixed" color="primary" (click)="addDayPart()">
  <mat-icon>add</mat-icon>
</button>
