<div class="wrapper">
  <mat-card>
    <mat-card-content>
      <mat-list role="list">
        <h3>
          Uitzonderingen waarop je niet kunt (denk aan vakantie tandarts etc.)
        </h3>
        <mat-list-item
          class="exception"
          *ngFor="let exception of exceptions"
        >
          <div class="time">
            <div class="dayparts">
              <div class="exception-driver">
                <h4 *ngIf="exception.name">{{ exception.name }}</h4>
                <div class="exception-driver-content">
                  <div class="exception-driver-time">
                    <h5>
                      Van:
                      {{ exception.dateFrom | date: 'd/MM/YYYY HH:mm' }}
                    </h5>
                    <h5>
                      Tot:
                      {{ exception.dateTo | date: 'd/MM/YYYY HH:mm' }}
                    </h5>
                  </div>
                  <div>
                    <button
                      mat-icon-button
                      (click)="deleteException(exception.index)"
                      [disabled]="isDriver && !driverCanModify"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="exceptions.length === 0">
          <p>Er zijn nog geen uitzonderingen.</p>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
<button
  *ngIf="!isDriver || driverCanModify"
  mat-fab
  class="fab-fixed"
  color="primary"
  (click)="openAddExceptionDialog()"
>
  <mat-icon>add</mat-icon>
</button>

