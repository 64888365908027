<h1
  mat-dialog-title
  *ngIf="!loadingDialog"
>
  Weet je zeker dat je een incasso wilt aanmaken?
</h1>
<div
  mat-dialog-content
  *ngIf="!loadingDialog"
>
  Dit genereert, per Buurtvervoerder, een SEPA bestand van deze maand!<br />
  <form
    [formGroup]="sepaForm"
    autocomplete="off"
  >
    <div *ngFor="let organisation of organisations">
      <mat-checkbox [formControlName]="organisation.formControl">{{
        organisation.name
      }}</mat-checkbox>
    </div>
    <mat-form-field>
      <mat-label>Maand</mat-label>
      <mat-select formControlName="month">
        <mat-option
          *ngFor="let month of months"
          [value]="month"
        >
          {{ month.name }}
          {{ month.period }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div
  mat-dialog-actions
  *ngIf="!loadingDialog"
  align="end"
>
  <button
    mat-button
    mat-dialog-close
  >
    Annuleren
  </button>
  <button
    mat-flat-button
    (click)="createSepa()"
    [class.spinner]="loading"
    [disabled]="!sepaForm.valid"
  >
    Incasso aanmaken
  </button>
</div>
