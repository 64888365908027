import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { debounceTime, map, take } from 'rxjs/operators';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
} from 'date-fns';
import {
  combineLatest,
  lastValueFrom,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { RideDetailsComponent } from './dialogs/ride-details/ride-details.component';
import { ConfirmChangeComponent } from './dialogs/confirm-change/confirm-change.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditRideComponent } from './dialogs/edit-ride/edit-ride.component';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { CopyUpdateComponent } from './dialogs/copy-update/copy-update.component';
import * as XLSX from 'xlsx';
import {
  Daypart,
  DaypartDay,
  Vehicle,
  User,
  Setting,
  Ride,
  VehicleGroup,
} from '../interfaces';
import { PlanDaypartComponent } from './dialogs/plan-daypart/plan-daypart.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import {
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import { getDateString, redirectIfDriver } from '../globals';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NotifyUsersRideDialog } from './dialogs/edit-ride/dialogs/notify-users-ride/notify-users-ride.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const colors: any = {
  blue: {
    primary: '#1e90ff',
    secondary: '#1e90ff',
  },
  green: {
    primary: '#7AD319',
    secondary: '#7AD319',
  }
};

@Component({
  selector: 'app-planner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    DatePipe,
  ],
})
export class PlannerComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Week;
  calendarStartHour = 9;
  calendarEndHour = 21;
  weekdays = [
    'zondag',
    'maandag',
    'dinsdag',
    'woensdag',
    'donderdag',
    'vrijdag',
    'zaterdag',
  ];
  searchQuery: string = '';
  searchQueryChanged: Subject<string> = new Subject<string>();
  planType: 'rides' | 'drivers' | 'planners' = 'rides';
  planTypeChanged: Subject<string> = new Subject<string>();
  CalendarView = CalendarView;
  minimized: string = 'out';
  allowPlannerScheduling = true;

  loadingEvents = true;
  viewDate: Date = new Date();
  viewBeginDate: Date = new Date();
  viewEndDate: Date = new Date();
  weekPerMonth: Array<number> = [];

  rides: Observable<Ride[]>;
  rides$: ReplaySubject<CalendarEvent<any>[]> = new ReplaySubject(1);
  ridesSubscription: Subscription;
  daypartDays: Observable<DaypartDay[]>;
  daypartDays$: ReplaySubject<CalendarEvent<any>[]> = new ReplaySubject(1);
  daypartDaysSubscription: Subscription;
  daypartDaysPlanner: Observable<DaypartDay[]>;
  daypartDaysPlanner$: ReplaySubject<CalendarEvent<any>[]> = new ReplaySubject(1);
  daypartDaysPlannerSubscription: Subscription;
  public ridesPerVehicle$: ReplaySubject<Map<string, Array<CalendarEvent>>> =
    new ReplaySubject<Map<string, Array<CalendarEvent>>>(1);
  vehicles: Vehicle[] = [];
  public filteredVehicles$: ReplaySubject<Vehicle[]> = new ReplaySubject(1);
  vehicleGroups: VehicleGroup[] = [];
  dayParts: Daypart[] = [];
  dayPartsPlanner: Daypart[] = [];
  filterOnVehicle: string = 'all';
  filterOnVehicleChanged: Subject<string> = new Subject<string>();
  selectedVehicle?: Vehicle;
  filterOnVehicleGroup: string = 'all';
  filterOnVehicleGroupChanged: Subject<string> = new Subject<string>();
  selectedVehicleGroup?: VehicleGroup;
  userData: Observable<User>;
  public filteredEvents$: ReplaySubject<any> = new ReplaySubject<any>(1);

  organisationRef: string;
  ridesCollection: AngularFirestoreCollection<Ride>;
  usersDocument: AngularFirestoreDocument<User>;
  combinedObservable: any;

  activeDayIsOpen = false;
  organisationId = localStorage.getItem('orgId');
  userId: string;
  rideId: string;

  endHour: number;
  startHour: number;
  rideDuration: number;
  hourSegments: number;

  settings: Setting;
  publicSettingsBanner: string;

  ignoreDriverStatus: boolean = false;
  refreshingRides: boolean;
  exportingRides: boolean = false;
  driverArray = [];
  showDriverPlanning: boolean;
  showPlannerPlanning: boolean = false;

  constructor(
    public db: AngularFirestore,
    private http: HttpClient,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private auth: AngularFireAuth,
    private router: Router, // @Inject(MAT_DIALOG_DATA) public data: DialogData
    private datePipe: DatePipe,
    private fns: AngularFireFunctions
  ) {}

  async ngOnInit(): Promise<any> {
    this.showDriverPlanning = JSON.parse(
      localStorage.getItem('usesDriverPlanning')
    );
    this.userId = (await this.auth.currentUser).uid;
    console.log('this.organisationId', this.organisationId);
    this.organisationRef = `organisations/${this.organisationId}`;

    this.ridesCollection = this.db.collection<Ride>(
      `organisations/${this.organisationId}/rides`
    );

    this.usersDocument = this.db.doc<User>(
      `organisations/${this.organisationId}/users/${this.userId}`
    );
    console.log('usersDocument', this.usersDocument);
    console.log('userId', this.userId);
    this.userData = this.usersDocument.valueChanges().pipe(take(1));
    this.userData.subscribe((userData) => {
      if (userData.accountType === 'driver') {
        this.router.navigate(['/availability'], {});
      } else if (userData.accountType === 'planner') {
        this.allowPlannerScheduling = false;
      }
      if (userData.viewType === 'month') {
        this.setView(CalendarView.Month);
        // this.setView(CalendarView.Week);
      } else if (userData.viewType === 'day') {
        this.setView(CalendarView.Day);
      } else {
        this.setView(CalendarView.Week);
      }
    });
    this.settings = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/settings/general`
        )
      )
    ).data() as Setting;

    if (this.settings) {
      this.rideDuration = this.settings.customRideDuration
        ? this.settings.customRideDuration
        : 15;
      this.ignoreDriverStatus = this.settings.ignoreDriverStatus
        ? this.settings.ignoreDriverStatus
        : false;
      if (this.settings.daypartSettings) {
        if (this.settings.daypartSettings.startHour) {
          this.calendarStartHour = this.settings.daypartSettings.startHour;
        }
        if (this.settings.daypartSettings.endHour) {
          this.calendarEndHour = this.settings.daypartSettings.endHour;
        }
      }
      this.hourSegments = this.settings.customSegment
        ? 60 / this.settings.customSegment
        : 60 / 15;
      this.showPlannerPlanning = this.settings.usesPlannerPlanning
        ? this.settings.usesPlannerPlanning
        : false;
    }
    const daypartsDocs = await getDocs(
      collection(
        this.db.firestore,
        `organisations/${this.organisationId}/dayparts`
      )
    );
    daypartsDocs.forEach((daypartDoc) => {
      const daypart = daypartDoc.data() as Daypart;
      daypart.id = daypartDoc.id;
      if (daypart.type == "driver") {
        this.dayParts.push(daypart);
      } else if (daypart.type == "planner") {
        this.dayPartsPlanner.push(daypart);
      }
    });
    const vehicleDocs = await getDocs(
      query(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/vehicles`
        ),
        orderBy('calendarStyle.sortingNumber', 'asc'),
        where('active', '==', true)
      )
    );
    vehicleDocs.forEach((vehicleDoc) => {
      const vehicle = vehicleDoc.data() as Vehicle;
      vehicle.id = vehicleDoc.id;
      this.vehicles.push(vehicle);
    });
    console.log('vehicles', this.vehicles);
    const vehicleGroupDocs = await getDocs(
      query(
        collection(
          this.db.firestore,
          `organisations/${this.organisationId}/vehicleGroups`
        ),
        orderBy('sortingNumber', 'asc')
      )
    );
    vehicleGroupDocs.forEach((vehicleGroupDoc) => {
      const vehicleGroup = vehicleGroupDoc.data() as VehicleGroup;
      vehicleGroup.id = vehicleGroupDoc.id;
      this.vehicleGroups.push(vehicleGroup);
    });
    console.log('vehicleGroups', this.vehicleGroups);
    this.fetchEvents();
    this.combinedObservable = combineLatest([
      this.rides$,
      this.daypartDays$,
      this.daypartDaysPlanner$,
      this.searchQueryChanged.pipe(debounceTime(300)),
      this.planTypeChanged,
      this.filterOnVehicleChanged,
      this.filterOnVehicleGroupChanged,
    ]);
    this.combinedObservable.pipe().subscribe((values) => {
      console.log('combinedObservableValues:', values);
      const rideEvents = values[0];
      const daypartEvents = values[1];
      const daypartPlannerEvents = values[2];
      const searchQuery = values[3].toLowerCase();
      const planType = values[4];
      const filterOnVehicle = values[5];
      const filterOnVehicleGroup = values[6];
      console.log('filterOnVehicleGroup', filterOnVehicleGroup);

      let events: CalendarEvent<any>[] = [];

      //events = planType === 'rides' ? rideEvents : daypartEvents;
      if (planType === 'rides') {
        events = rideEvents;
      } else if (planType === 'drivers') {
        events = daypartEvents;
      } else if (planType === 'planners') {
        events = daypartPlannerEvents;
      }
      events = events.filter((event) => {
        let searchPassed = false;
        let vehicleFilterPassed = false;
        let vehicleGroupFilterPassed = false;
        if (event.title && event.title.toLowerCase().includes(searchQuery)) {
          searchPassed = true;
        }
        if (planType !== 'rides') {
          vehicleFilterPassed = true;
        } else if (filterOnVehicle === 'all') {
          vehicleFilterPassed = true;
        } else if (
          planType === 'rides' &&
          event.meta.vehicle &&
          event.meta.vehicle.id === filterOnVehicle
        ) {
          vehicleFilterPassed = true;
        }
        if (planType !== 'rides') {
          vehicleGroupFilterPassed = true;
        } else if (filterOnVehicleGroup === 'all') {
          vehicleGroupFilterPassed = true;
        } else if (
          planType === 'rides' &&
          event.meta.vehicle &&
          this.selectedVehicleGroup.vehicles?.includes(event.meta.vehicle.id)
        ) {
          vehicleGroupFilterPassed = true;
        }

        if (searchPassed && vehicleFilterPassed && vehicleGroupFilterPassed) {
          return event;
        }
      });
      if (planType === 'rides') {
        events.sort((a, b) => {
          if (a.start < b.start) {
            return 1;
          }
          if (a.start > b.start) {
            return -1;
          }
          if (
            a.start.getTime() === b.start.getTime() &&
            a.meta.vehicle &&
            a.meta.vehicle.calendarStyle &&
            a.meta.vehicle.calendarStyle.sortingNumber &&
            b.meta.vehicle &&
            b.meta.vehicle.calendarStyle &&
            b.meta.vehicle.calendarStyle.sortingNumber
          ) {
            if (
              a.meta.vehicle.calendarStyle.sortingNumber >
              b.meta.vehicle.calendarStyle.sortingNumber
            ) {
              return 1;
            } else {
              return -1;
            }
          }
          return 0;
        });
      }
      if (planType === 'rides' && this.vehicles.length > 0) {
        const ridesPerVehicleMap: Map<string, Array<CalendarEvent>> = new Map();
        events.forEach((event) => {
          if (event.meta.ride.vehicleId) {
            if (!ridesPerVehicleMap.has(event.meta.ride.vehicleId)) {
              ridesPerVehicleMap.set(event.meta.ride.vehicleId, []);
            }
            const array = ridesPerVehicleMap.get(event.meta.ride.vehicleId);
            array.push(event);
            ridesPerVehicleMap.set(event.meta.ride.vehicleId, array);
          }
        });
        this.ridesPerVehicle$.next(ridesPerVehicleMap);
        console.log('ridesPerVehicle:', ridesPerVehicleMap);
      }

      const filteredVehicles = [];
      this.filteredVehicles$.next(filteredVehicles);
      this.vehicles.forEach((vehicle) => {
        if (this.filterOnVehicleGroup === 'all') {
          filteredVehicles.push(vehicle);
        } else if (this.selectedVehicleGroup?.vehicles?.includes(vehicle.id)) {
          filteredVehicles.push(vehicle);
        }
      });

      console.log('events:', events);
      console.log('filteredVehicles', filteredVehicles);
      this.filteredEvents$.next(events);
      this.filteredVehicles$.next(filteredVehicles);
    });

    this.searchQueryChanged.next(this.searchQuery);
    this.planTypeChanged.next(this.planType);
    this.filterOnVehicleChanged.next(this.filterOnVehicle);
    this.filterOnVehicleGroupChanged.next(this.filterOnVehicleGroup);
    this.filteredVehicles$.next([]);

    this.filteredEvents$.subscribe((filteredEvents) => {
      console.log('filteredEvents', filteredEvents);
    });

    this.filteredVehicles$.subscribe((filteredVehicles) => {
      console.log('filteredVehicles', filteredVehicles);
    });

    const q = query(
      collection(
        this.db.firestore,
        `organisations/${this.organisationId}/users/`
      ),
      where('accountType', '==', 'driver')
    );
    const drivers = await getDocs(q);

    drivers.forEach((driver) => {
      let driverObj = driver.data();
      driverObj['id'] = driver.id;
      this.driverArray.push(driverObj);
    });

    redirectIfDriver(this.db.firestore, this.router);

    this.publicSettingsBanner = localStorage.getItem('banner');
  }

  onFilterChange(type: string, ev?) {
    console.log('filterchange', type, ev);
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    } else if (type === 'planType') {
      this.planTypeChanged.next(ev);
    } else if (type === 'vehicle') {
      if (ev !== 'all') {
        this.selectedVehicle = this.vehicles.find((vehicle) => {
          return vehicle.id === ev;
        });
      } else {
        this.selectedVehicle = undefined;
      }
      this.filterOnVehicleChanged.next(ev);
    } else if (type === 'vehicleGroup') {
      this.filterOnVehicleGroup = ev;
      if (ev !== 'all') {
        this.selectedVehicleGroup = this.vehicleGroups.find((vehicleGroup) => {
          return vehicleGroup.id === ev;
        });
      } else {
        this.selectedVehicleGroup = undefined;
      }
      this.filterOnVehicleGroupChanged.next(ev);
    }
  }

  async fetchEvents() {
    console.log('refetching events');
    console.log('this.vehicles', this.vehicles);
    if (this.ridesSubscription) {
      this.ridesSubscription.unsubscribe();
    }
    if (this.daypartDaysSubscription) {
      this.daypartDaysSubscription.unsubscribe();
    }
    if (this.daypartDaysPlannerSubscription) {
      this.daypartDaysPlannerSubscription.unsubscribe();
    }
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view];

    this.viewBeginDate = getStart(this.viewDate, { weekStartsOn: 1 });
    this.viewEndDate = getEnd(this.viewDate, { weekStartsOn: 1 });
    this.weekPerMonth = [];
    const beginDateView = moment(this.viewBeginDate)
      .startOf('isoWeek')
      .toDate();
    // console.log('beginDateView', beginDateView);
    // console.log('viewEndDate', this.viewEndDate);
    for (let i = 0; i < 6; i++) {
      const date = new Date(
        new Date(beginDateView).setDate(beginDateView.getDate() + i * 7)
      );
      // console.log('date', date);
      if (date < this.viewEndDate) {
        const weeksMonth = moment(date).isoWeek();
        this.weekPerMonth.push(weeksMonth);
      }
    }
    console.log('this.viewBeginDate', this.viewBeginDate);
    console.log('this.view', this.view);
    let ridesCollection = this.db.collection(this.ridesCollection.ref, (ref) =>
      ref.where('start', '>', getStart(this.viewDate))
    );

    const selectedYear = this.viewBeginDate.getFullYear();
    if (this.view === 'month') {
      const selectedMonth = this.viewBeginDate.getMonth();
      ridesCollection = this.db.collection(this.ridesCollection.ref, (ref) =>
        ref
          .where('year', '==', selectedYear)
          .where('month', '==', selectedMonth)
      );
    }
    if (this.view === 'week') {
      const selectedWeek = getWeekString(this.viewEndDate);
      console.log('selectedWeek', selectedWeek);
      ridesCollection = this.db.collection(this.ridesCollection.ref, (ref) =>
        ref.where('week', '==', selectedWeek)
      );
    }
    if (this.view === 'day') {
      const selectedMonth = this.viewBeginDate.getMonth();
      const selectedDay = this.viewBeginDate.getDate();
      ridesCollection = this.db.collection(this.ridesCollection.ref, (ref) =>
        ref
          .where('year', '==', selectedYear)
          .where('month', '==', selectedMonth)
          .where('day', '==', selectedDay)
      );
    }
    this.rides = ridesCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as object;
          if (data['start']) {
            data['start'] = data['start'].toDate();
          }
          if (data['end']) {
            data['end'] = data['end'].toDate();
          }
          const rideData = data as Ride;
          const id = a.payload.doc.id;
          return { id, ...rideData };
        })
      )
    );
    this.ridesSubscription = this.rides.subscribe((rides) => {
      console.log('rides', rides);
      const events = [];
      rides.forEach((ride) => {
        const selectedVehicle = this.vehicles.find((vehicle) => {
          return vehicle.id === ride.vehicleId;
        });

        let color = {
          primary: '#bfbfbf',
          secondary: '#FFFFFF',
        };

        if (selectedVehicle) {
          color.primary = selectedVehicle.calendarStyle.color;
          color.secondary = selectedVehicle.calendarStyle.color
            ? selectedVehicle.calendarStyle.color
            : '#EFEFEF';
          if (ride.special && this.settings.activateSignalColor) {
            color = {
              primary: this.settings.signalColor,
              secondary: this.settings.signalColor,
            };
          } else if (this.showDriverPlanning && !this.settings.ignoreDriverStatus && !ride.driverId) {
            color.secondary = selectedVehicle.calendarStyle
              .backgroundColorNoDriver
              ? selectedVehicle.calendarStyle.backgroundColorNoDriver
              : '#EFEFEF';
          } else if (ride.availableSeats === 0) {
            color.secondary = selectedVehicle.calendarStyle.backgroundColorFull
              ? selectedVehicle.calendarStyle.backgroundColorFull
              : '#EFEFEF';
          } else if (ride.availableSeats === selectedVehicle.seats) {
            color.secondary = selectedVehicle.calendarStyle.backgroundColorEmpty
              ? selectedVehicle.calendarStyle.backgroundColorEmpty
              : '#EFEFEF';
          } 
        }

        let title = ride.title
          ? ride.title
          : ride.availableSeats === 0
          ? 'VOL'
          : `${ride.availableSeats} Vrij`;

        if (ride.remarkFromDriver || this.rideHasDriverRemarks(ride.users)) {
          title = '<span class="title-italic">' + title + ' </span>';
        }
        if (ride.hasCopy) {
          title = `<span class="ride-icons-wrapper"><span class="ride-icon">repeat</span>&nbsp;${title}</span>`;
        }

        const aRGBHex = color.secondary.replace('#', '').match(/.{1,2}/g);
        const RGB =
          1 -
          (0.299 * parseInt(aRGBHex[0], 16) +
            0.733 * parseInt(aRGBHex[1], 16) +
            0.114 * parseInt(aRGBHex[2], 16)) /
            255;

        title = '<span>' + title + '</span>';

        const timeTitleStart = this.datePipe.transform(
          new Date(ride.start),
          'HH:mm'
        );
        const timeTitleEnd = this.datePipe.transform(
          new Date(ride.end),
          'HH:mm'
        );

        if (ride.end - ride.start >= 1200000) {
          title =
            title +
            '<span class="position-fix">' +
            timeTitleStart +
            ' - ' +
            timeTitleEnd +
            '</span>';
        }

        let classes = '';
        if (new Date(ride.end) <= new Date()) {
          classes = 'ride-done ';
        }
        if (RGB < 0.45) {
          classes = classes + 'text-color-black';
        } else {
          classes = classes + 'text-color-white';
        }
        events.push({
          title,
          start: ride.start,
          end: ride.end,
          cssClass: classes,
          color,
          meta: {
            ride,
            vehicle: selectedVehicle,
          },
          draggable: true,
        });
      });
      console.log('rides$', events);
      this.rides$.next(events);
    });
    let dayPartDaysRef;
    if (this.view === 'month') {
      const selectedMonth = this.viewBeginDate.getMonth();
      dayPartDaysRef = this.db.collection(
        `${this.organisationRef}/daypartDays`,
        (ref) =>
          ref
            .where('year', '==', selectedYear)
            .where('month', '==', selectedMonth)
            .where('type', '!=', 'planner')
      );
    }
    if (this.view === 'week') {
      const selectedWeek = getWeekString(this.viewEndDate);
      dayPartDaysRef = this.db.collection(
        `${this.organisationRef}/daypartDays`,
        (ref) => 
          ref
            .where('week', '==', selectedWeek)
            .where('type', '!=', 'planner')
      );
    }
    if (this.view === 'day') {
      const selectedMonth = this.viewBeginDate.getMonth();
      const selectedDay = this.viewBeginDate.getDate();
      dayPartDaysRef = this.db.collection(
        `${this.organisationRef}/daypartDays`,
        (ref) =>
          ref
            .where('year', '==', selectedYear)
            .where('month', '==', selectedMonth)
            .where('day', '==', selectedDay)
            .where('type', '!=', 'planner')
      );
    }
    this.daypartDays = dayPartDaysRef.valueChanges({ idField: 'id' });
    this.daypartDaysSubscription = this.daypartDays.subscribe((dayPartDays) => {
      console.log('dayPartDays', dayPartDays);
      const events = [];
      const firstDateOfWeek = this.getMonday(this.viewBeginDate);
      this.dayParts.forEach((dayPart: Daypart) => {
        let start = new Date(
          new Date(
            new Date(firstDateOfWeek).setDate(
              firstDateOfWeek.getDate() + dayPart.daypart.day
            )
          ).setHours(dayPart.daypart.startHour, dayPart.daypart.startMin)
        );
        let end = new Date(
          new Date(
            new Date(firstDateOfWeek).setDate(
              firstDateOfWeek.getDate() + dayPart.daypart.day
            )
          ).setHours(dayPart.daypart.endHour, dayPart.daypart.endMin)
        );
        this.vehicles.forEach((vehicle) => {
          if (vehicle.active === false) return;
          let eventObj: CalendarEvent = {
            title: `${dayPart.daypart.name} - ${vehicle.name}`,
            start: null,
            end: null,
            cssClass: 'text-color-black',
            color: {
              primary: vehicle.calendarStyle.color,
              secondary: '#FFFFFF',
            },
            meta: {
              dayPart,
              vehicle,
              dayPartDay: null,
            },
          };
          let totalWeeks = 1;
          if (this.view === 'month') {
            totalWeeks = 5;
          }
          for (let step = 0; step < totalWeeks; step++) {
            const daysToAdd = step * 7;
            const startInWeek = new Date(
              start.getFullYear(),
              start.getMonth(),
              start.getDate() + daysToAdd,
              start.getHours(),
              start.getMinutes()
            );
            const endInWeek = new Date(
              end.getFullYear(),
              end.getMonth(),
              end.getDate() + daysToAdd,
              end.getHours(),
              end.getMinutes()
            );
            eventObj.start = startInWeek;
            eventObj.end = endInWeek;
            const startString = getDateString(eventObj.start);
            const dayPartDay = dayPartDays.find((dayPartDay: DaypartDay) => {
              return (
                dayPartDay.startString == startString &&
                dayPartDay.daypartId == dayPart.id &&
                dayPartDay.vehicleId == vehicle.id
              );
            });
            if (dayPartDay) {
              eventObj.meta.dayPartDay = dayPartDay;
              // If changes are already made to a day, you can change things here.
              if (dayPartDay.driverName) {
                eventObj.title = `${eventObj.title} - ${dayPartDay.driverName}`;
              }
              // If changes are already made to a day, you can change things here.
              if (dayPartDay.driverName && !dayPartDay.warning) {
                eventObj.color.secondary = vehicle.calendarStyle.color;
              }
              if (dayPartDay.start) {
                eventObj.start = dayPartDay.start.toDate();
              }
              if (dayPartDay.end) {
                eventObj.end = dayPartDay.end.toDate();
              }
            }
            if (new Date(eventObj.end) <= new Date()) {
              eventObj.cssClass = eventObj.cssClass + ' ride-done ';
            }
            events.push({ ...eventObj });
          }
        });
      });
      console.log('daypartDays$', events);
      this.daypartDays$.next(events);
    });

    let dayPartDaysPlannerRef;
    if (this.view === 'month') {
      const selectedMonth = this.viewBeginDate.getMonth();
      dayPartDaysPlannerRef = this.db.collection(
        `${this.organisationRef}/daypartDays`,
        (ref) =>
          ref
            .where('year', '==', selectedYear)
            .where('month', '==', selectedMonth)
            .where('type', '==', 'planner')
      );
    }
    if (this.view === 'week') {
      const selectedWeek = getWeekString(this.viewEndDate);
      dayPartDaysPlannerRef = this.db.collection(
        `${this.organisationRef}/daypartDays`,
        (ref) => 
          ref
          .where('week', '==', selectedWeek)
          .where('type', '==', 'planner')
      );
    }
    if (this.view === 'day') {
      const selectedMonth = this.viewBeginDate.getMonth();
      const selectedDay = this.viewBeginDate.getDate();
      dayPartDaysPlannerRef = this.db.collection(
        `${this.organisationRef}/daypartDays`,
        (ref) =>
          ref
            .where('year', '==', selectedYear)
            .where('month', '==', selectedMonth)
            .where('day', '==', selectedDay)
            .where('type', '==', 'planner')
      );
    }
    this.daypartDaysPlanner = dayPartDaysPlannerRef.valueChanges({ idField: 'id' });
    this.daypartDaysPlannerSubscription = this.daypartDaysPlanner.subscribe((dayPartDays) => {
      let color = colors.green;
      const events = [];
      const firstDateOfWeek = this.getMonday(this.viewBeginDate);
      this.dayPartsPlanner.forEach((dayPart: Daypart) => {
        let start = new Date(
          new Date(
            new Date(firstDateOfWeek).setDate(
              firstDateOfWeek.getDate() + dayPart.daypart.day
            )
          ).setHours(dayPart.daypart.startHour, dayPart.daypart.startMin)
        );
        let end = new Date(
          new Date(
            new Date(firstDateOfWeek).setDate(
              firstDateOfWeek.getDate() + dayPart.daypart.day
            )
          ).setHours(dayPart.daypart.endHour, dayPart.daypart.endMin)
        );
        let eventObj: CalendarEvent = {
          title: `${dayPart.daypart.name}`,
          start: null,
          end: null,
          cssClass: 'text-color-black',
          color: {
            primary: '#000000',
            secondary: '#FFFFFF',
          },
          meta: {
            dayPart,
            dayPartDay: null,
          },
        };
        let totalWeeks = 1;
        if (this.view === 'month') {
          totalWeeks = 5;
        }
        for (let step = 0; step < totalWeeks; step++) {
          const daysToAdd = step * 7;
          const startInWeek = new Date(
            start.getFullYear(),
            start.getMonth(),
            start.getDate() + daysToAdd,
            start.getHours(),
            start.getMinutes()
          );
          const endInWeek = new Date(
            end.getFullYear(),
            end.getMonth(),
            end.getDate() + daysToAdd,
            end.getHours(),
            end.getMinutes()
          );
          eventObj.start = startInWeek;
          eventObj.end = endInWeek;
          const startString = getDateString(eventObj.start);
          const dayPartDay = dayPartDays.find((dayPartDay: DaypartDay) => {
            return (
              dayPartDay.startString == startString &&
              dayPartDay.daypartId == dayPart.id
            );
          });

          if (dayPartDay) {
            eventObj.meta.dayPartDay = dayPartDay;
            
            if (dayPartDay.planners.length > 0) {
              eventObj.title = `${eventObj.title}: <br><br> ${dayPartDay.planners.join('<br>')}`;
              eventObj.color = color;
            }
            if (dayPartDay.start) {
              eventObj.start = dayPartDay.start.toDate();
            }
            if (dayPartDay.end) {
              eventObj.end = dayPartDay.end.toDate();
            }
          }
          if (new Date(eventObj.end) <= new Date()) {
            eventObj.cssClass = eventObj.cssClass + ' ride-done ';
          }
          events.push({ ...eventObj });
        }
      });
      this.daypartDaysPlanner$.next(events);
    });
  }

  getMonday(d: Date) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: Array<CalendarEvent<{ ride: Ride }>>;
  }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventClicked(
    event: CalendarEvent<{
      ride: Ride;
      dayPart?: Daypart;
      vehicle: Vehicle;
      dayPartDay?: DaypartDay;
    }>
    //filteredEvent: CalendarEvent<{}
  ): void {
    console.log('event.meta', event);
    console.log('event.meta', event.meta);
    console.log('event.start', event.start.getTime());
    if (event.meta.ride) {
      this.dialog.open(RideDetailsComponent, {
        width: '500px',
        data: { ride: { ...event.meta.ride }, vehicle: event.meta.vehicle },
        autoFocus: false,
      });
    }
    // if (this.filterRides(event.meta.ride)) {
    //   // this.events$ = this.filteredRides
    //   this.dialog.open(RideDetailsComponent, {
    //     width: '500px',
    //     data: { ride: { ...this.filteredRides.subscribe }, vehicle: event.meta.vehicle },
    //   });
    // }
    if (
      event.meta.dayPart && this.planType == 'drivers' ||
      event.meta.dayPart && this.planType == 'planners' && this.allowPlannerScheduling
    ) {
      this.dialog.open(PlanDaypartComponent, {
        width: '500px',
        data: {
          start: event.start,
          end: event.end,
          dayPart: { ...event.meta.dayPart},
          vehicle: { ...event.meta.vehicle},
          dayPartDay: { ...event.meta.dayPartDay},
          planType: this.planType,
        },
      });
    }
  }

  eventTimesChanged({
    type,
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    const oldStart = event.start;
    const oldEnd = event.end;
    this.rideId = event.meta.ride.id;
    const rideRef = this.db
      .collection(`organisations/${this.organisationId}/rides`)
      .doc(event.meta.ride.id);
    let year = newStart.getFullYear();
    let month = newStart.getMonth();
    let week = getWeekString(newStart);
    let day = newStart.getDate();
    console.log('type', type);
    console.log('event', event);

    let actuallyMoved = false;
    if (newStart.toUTCString() !== oldStart.toUTCString()) {
      console.log('start changed');
      actuallyMoved = true;
    }
    if (newEnd.toUTCString() !== oldEnd.toUTCString()) {
      console.log('end changed');
      actuallyMoved = true;
    }
    if (actuallyMoved) {
      const confirmDialogRef = this.dialog.open(ConfirmChangeComponent, {
        width: '600px',
      });
      confirmDialogRef.afterClosed().subscribe(async (confirmResult) => {
        console.log('The dialog was closed with result: ', confirmResult);
        if (confirmResult) {
          const user = (
            await this.db
              .doc(`organisations/${this.organisationId}/users/${this.userId}`)
              .get()
              .toPromise()
          ).data() as User;
          const ride = (
            await this.db
              .collection(`organisations/${this.organisationId}/rides`)
              .doc(this.rideId)
              .get()
              .toPromise()
          ).data() as Ride;
          const saveObj = ride as any;
          let editObj = [];
          let changesObj = {};
          saveObj.start = newStart;
          saveObj.end = newEnd;
          if (oldStart != saveObj.start || oldEnd != saveObj.end) {
            editObj.push({
              field: 'start',
              from: oldStart,
              to: newStart,
            }),
              editObj.push({
                field: 'end',
                from: oldEnd,
                to: newEnd,
              });
            changesObj = {
              changes: editObj,
              date: new Date(),
              editedByEmail: user.email,
              editedByUid: this.userId,
            };
            await rideRef.collection('edits').add(changesObj);
          }

          if (event.meta.ride.parentRideId) {
            saveObj['parentRideId'] = event.meta.ride.id;
            // ride is part of group, ask if users wants to edit all
            // if yes, override all data in future rides (after recalculating the date)
            const updateDialogRef = this.dialog.open(CopyUpdateComponent, {
              width: '600px',
              data: {
                oldRide: event.meta.ride,
                newRide: saveObj,
                drag: true,
                vehicle: event.meta.vehicle,
              },
            });
            updateDialogRef.afterClosed().subscribe(async (result) => {
              console.log('The dialog was closed with result: ', result);
              if (result) {
                await rideRef.set(saveObj, { merge: true });
                this.snackBar.open('Ritten zijn verplaatst', '', {
                  duration: 5000,
                });
                this.notifyParticipants(ride.users, saveObj.start);
              } else if (result === false) {
                // result false / user said 'no'
                // if no, remove parentRideId from this ride, then continue saving.
                saveObj['hasCopy'] = deleteField();
                saveObj['parentRideId'] = deleteField();
                await this.db
                  .collection(`organisations/${this.organisationId}/rides`)
                  .doc(event.meta.ride.id)
                  .set(saveObj, { merge: true });
                this.snackBar.open('Rit is verplaatst', '', { duration: 5000 });
                this.notifyParticipants(ride.users, saveObj.start);
              }
              // result undefined / user cancelled dialog, do nothing, user can press save again if they want to.
            });
          } else {
            this.notifyParticipants(ride.users, saveObj.start);
            await this.db
              .collection(`organisations/${this.organisationId}/rides`)
              .doc(event.meta.ride.id)
              .set(saveObj, { merge: true });
            this.snackBar.open('Rit is verplaatst', '', {
              duration: 5000,
            });
          }
        }
      });
    }
  }

  async setView(view: CalendarView) {
    this.view = view;
    console.log('view', view);
    await this.usersDocument.set(
      {
        viewType: `${view}`,
      },
      { merge: true }
    );
    this.fetchEvents();
  }

  newRide(date?: Date, vehicleId?: string) {
    console.log('new ride');
    if (this.planType === 'drivers' || this.planType === 'planners') {
      // Can't create new ride when you're planning drivers or planners
      return;
    }
    let start;
    let end;
    if (date) {
      start = date;
      end = new Date(start.getTime() + this.rideDuration * 60000);
    } else {
      start = new Date();
      start.setHours(this.calendarStartHour, 0, 0, 0);
      end = new Date();
      end.setHours(
        this.calendarStartHour,
        start.getMinutes() + this.rideDuration,
        0,
        0
      );
    }
    const dialogRef = this.dialog.open(EditRideComponent, {
      width: '500px',
      data: {
        ride: {},
        defaultVehicle: vehicleId ? vehicleId : this.filterOnVehicle,
        dates: { start, end },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (result && result.generatedRetour) {
        console.log('result', result);
        this.dialog.open(EditRideComponent, {
          width: '500px',
          data: {
            ride: result.retourRide, //result.ride,
            defaultVehicle: result.retourRide.vehicleId,
            parentRideId: result.parentRideId,
            retourRide: result.retourRide,
            retourGenerating: true,
            // dates: { start, end },
          },
        });
      }
    });
  }

  async refreshRides() {
    this.refreshingRides = true;
    let selectedType = {} as any;
    if (this.view === 'month') {
      selectedType.value = this.viewBeginDate.getMonth();
      selectedType.type = 'month';
    }
    if (this.view === 'week') {
      selectedType.value = getWeekString(this.viewEndDate);
      selectedType.type = 'week';
    }
    if (this.view === 'day') {
      selectedType.type = 'day';
      selectedType.value = {
        selectedMonth: this.viewBeginDate.getMonth(),
        selectedDay: this.viewBeginDate.getDate(),
      };
    }

    try {
      const response = (await this.http
        .post(`${environment.functionsUrl}/httpRefreshRides`, {
          organisationId: this.organisationId,
          selectedType,
          planType: this.planType,
        })
        .toPromise()) as { message: string };

      console.log('response', response);
      if (response.message === 'succeed') {
        this.snackBar.open('Ritten zijn vernieuwd', 'X', {
          duration: 5000,
        });
      } else {
        this.snackBar.open(
          'Er is iets misegaan bij het vernieuwen van de ritten',
          'X',
          {
            duration: 5000,
          }
        );
      }
      this.refreshingRides = false;
    } catch (e) {
      console.error(e);
      this.refreshingRides = false;
      this.snackBar.open(
        'Er is iets misegaan bij het vernieuwen van de ritten',
        'X',
        {
          duration: 5000,
        }
      );
    }
  }

  rideHasDriverRemarks(users: Map<string, any>): boolean {
    let rideHasDriverRemarks = false;
    if (users) {
      for (const [key, value] of Object.entries(users)) {
        if (value.remarkFromDriver) {
          rideHasDriverRemarks = true;
        }
      }
    }

    return rideHasDriverRemarks;
  }

  exportRides(type: string) {
    this.exportingRides = true;
    let exportArray = [];
    let driverArray = this.driverArray;
    let exportType;
    let driver;
    let addressFrom: string;
    let addressTo: string;
    let cost: number;

    try {
      if (type === CalendarView.Day) {
        exportType = 'day';
        this.rides$.subscribe((rides) => {
          rides.forEach((ride) => {
            if (ride.meta.vehicle.name) {
              if (ride.meta.ride.driverId) {
                driver = driverArray.find(
                  ({ id }) => id === ride.meta.ride.driverId
                ).name;
              } else {
                driver = 'N.V.T.';
              }

              if (ride.meta.ride.users) {
                const users = exportRideFixUserArray(
                  Object.entries(ride.meta.ride.users)
                );
                console.log('usersInRide', users);
                users.forEach((user) => {
                  addressFrom = user.from.address
                    ? user.from.address
                    : user.from;
                  addressTo = user.to.address ? user.to.address : user.to;
                  if (user.rideProduct?.cost) {
                    cost = user.rideProduct.cost;
                  } else {
                    if (ride.meta.ride.rideProduct?.cost) {
                      cost = ride.meta.ride.rideProduct.cost;
                    } else {
                      cost = 0;
                    }
                  }

                  const pushObjRide = {
                    Chauffeur: driver,
                    Voertuig: ride.meta.vehicle.name,
                    Tijd: moment(ride.meta.ride.start).format('HH:mm'),
                    Ophaaladres: addressFrom,
                    Bestemming: addressTo,
                    Naam_reiziger: user.title,
                    Opmerkingen_voor_chauffeur: user.commentsForDriver,
                    Aantal_credits: cost,
                    Volgorde: user.order,
                  };

                  exportArray.push(pushObjRide);
                });
              } else {
                const pushObjRide = {
                  Chauffeur: driver,
                  Voertuig: ride.meta.vehicle.name,
                  Tijd: moment(ride.meta.ride.start).format('HH:mm'),
                  Ophaaladres: 'N.V.T.',
                  Bestemming: 'N.V.T.',
                  Naam_reiziger: 'N.V.T.',
                  Opmerkingen_voor_chauffeur: 'N.V.T.',
                  Aantal_credits: 'N.V.T.',
                };

                exportArray.push(pushObjRide);
              }
            }
          });
          exportArray.sort((a, b) => {
            const timeA = a.Tijd;
            const timeB = b.Tijd;
            if (timeA < timeB) {
              return -1;
            }
            if (timeA > timeB) {
              return 1;
            }
            if (timeA === timeB && a.Voertuig === b.Voertuig) {
              if (a.Volgorde < b.Volgorde) {
                return -1;
              }
              if (a.Volgorde > b.Volgorde) {
                return 1;
              }
            }

            return 0;
          });
        });
        if (exportArray.length < 1) {
          throw 'no-rides';
        }
        // delete Volgorde because we don't want to show it in the export.
        exportArray.forEach((ride) => {
          delete ride.Volgorde;
        });
        exportRideToExcel(
          exportArray,
          exportType,
          this.viewBeginDate,
          this.viewEndDate
        );
        // } else if (type === CalendarView.Week) {
        //   exportType = 'week';
      } else {
        throw 'not-supported';
      }
      this.exportingRides = false;
    } catch (e) {
      console.error(e);
      this.exportingRides = false;
      switch (e) {
        case 'no-rides':
          this.snackBar.open('Er zijn geen ritten om te exporteren', 'X', {
            duration: 5000,
          });
          break;
        case 'not-supported':
          this.snackBar.open('Dit calender type is nog niet ondersteund', 'X', {
            duration: 5000,
          });
          break;
        default:
          this.snackBar.open(
            'Er is iets misgegaan bij het exporteren van de ritten',
            'X',
            {
              duration: 5000,
            }
          );
          break;
      }
    }
  }

  dateChanged($event) {
    const newDateValue = $event.value;
    this.viewBeginDate = newDateValue;
    this.viewDate = newDateValue;
    this.fetchEvents();
  }

  toggleMenu(): void {
    this.minimized = this.minimized === 'in' ? 'out' : 'in';
  }

  async notifyParticipants(users, start) {
    const usersArray: any[] = Object.values(users);
    if (
      (this.settings.emailNotificationsParticipants ||
        this.settings.smsNotificationsParticipants) &&
      (this.settings.emailNotificationsParticipantsRideConfirmed ||
        this.settings.smsNotificationsParticipantsRideConfirmed) &&
      usersArray.length > 0
    ) {
      const dialogRef = this.dialog.open(NotifyUsersRideDialog, {
        width: '600px',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          for (const user of usersArray) {
            const from =
              typeof user.from === 'string' ? user.from : user.from.address;
            const to = typeof user.to === 'string' ? user.to : user.to.address;
            await this.planNotificationParticipant(
              start,
              moment(new Date(start)).format('DD-MM-YYYY'),
              moment(new Date(start)).format('HH:mm'),
              from,
              to,
              this.organisationId,
              user
            );
          }
        }
      });
    }
  }

  async planNotificationParticipant(
    rideDate,
    date: string,
    startRideTime: string,
    startRideLocation: string,
    endRideLocation: string,
    orgId: string,
    user
  ) {
    let sendMail = false;
    let sendSMS = false;

    const userData = (
      await getDoc(
        doc(
          this.db.firestore,
          `organisations/${this.organisationId}/users/${user.id}`
        )
      )
    ).data();

    if (!user.smsNotificationPhoneNumber) {
      if (userData.smsNotificationPhoneNumber) {
        user.smsNotificationPhoneNumber = userData.smsNotificationPhoneNumber;
      }
    }

    if (!user.email && !user.smsNotificationPhoneNumber) {
      return;
    }

    if (user.notifyByEmail) {
      sendMail = true;
    } else {
      if (userData.notifyByEmail) {
        sendMail = true;
      }
    }

    if (user.notifyBySMS) {
      sendSMS = true;
    } else {
      if (userData.notifyBySMS) {
        sendSMS = true;
      }
    }

    if (!this.settings.emailNotificationsParticipantsRideChanged) {
      sendMail = false;
    }

    if (!this.settings.smsNotificationsParticipantsRideChanged) {
      sendSMS = false;
    }

    try {
      if (sendMail) {
        const callBody = {
          orgId: orgId,
          userName: user.title,
          mailType: 'rideConfirmation',
          emailTo: user.email,
          optionalData: {
            date: rideDate,
            startTime: startRideTime,
            startLocation: startRideLocation,
            endLocation: endRideLocation,
          },
        };
        const callable = this.fns.httpsCallable('httpSendMail');
        const callRes = await lastValueFrom(callable(callBody));
        console.log(callRes);
      }

      if (sendSMS) {
        const callBody = {
          messageToSend: `Hierbij krijgt u een bevestiging voor uw rit op ${date} van ${startRideLocation} naar ${endRideLocation} om ${startRideTime}.`,
          phoneTo: user.smsNotificationPhoneNumber,
        };
        const callable = this.fns.httpsCallable('smsSendToCentral');
        const callRes = await lastValueFrom(callable(callBody));
        console.log(callRes);
      }
    } catch (error) {
      console.log(error);
    }
  }
}

function exportRideToExcel(exportArray, exportType, beginDate, endDate) {
  let fileName = 'planning.xlsx';

  switch (exportType) {
    case 'day': {
      fileName = `planning_${moment(beginDate).format('DD-MM-YYYY')}.xlsx`;
      break;
    }
    case 'week': {
      fileName = `planning_${moment(beginDate).format('DD-MM-YYYY')}_${moment(
        endDate
      ).format('DD-MM-YYYY')}.xlsx`;
      break;
    }
  }

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Rides');
  // /* save to file */
  XLSX.writeFile(wb, fileName);
  console.log('exportRideArray', exportArray);
}

// FOR THE PDF, LEAVE ALONE
// function exportRideToPDF() {
// try {
//   const response = (await this.http
//     .post(`${environment.functionsUrl}/httpExportRidesPdf`, {
//       array: exportArray,
//       type: exportType,
//     })
//     .toPromise()) as { message: string };

//   console.log('response', response);
//   if (response.message === 'succeed') {
//     this._snackBar.open('Ritten zijn geexporteerd', 'X', {
//       duration: 5000,
//     });
//   } else {
//     this._snackBar.open(
//       'Er is iets misegaan bij het exporteren van de ritten',
//       'X',
//       {
//         duration: 5000,
//       }
//     );
//   }
// } catch (e) {
//   console.error(e);
//   this._snackBar.open(
//     'Er is iets misegaan bij het exporteren van de ritten',
//     'X',
//     {
//       duration: 5000,
//     }
//   );
// }
// }

function exportRideFixUserArray(Array) {
  let fixedUserArray = [];
  Array.forEach((data) => {
    const pushObj = data[1];
    pushObj['id'] = data[0];
    fixedUserArray.push(pushObj);
  });
  return fixedUserArray;
}

function getWeekString(date) {
  const weekYear = moment(date).isoWeekYear();
  const week = moment(date).isoWeek();
  return `${weekYear}-${week}`;
}
