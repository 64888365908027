<h1 mat-dialog-title>Betaalde functionaliteiten beheren</h1>

<div
  mat-dialog-content
  class="dialog-no-overflow"
>
  <form
    [formGroup]="orgsForm"
    autocomplete="off"
  >
    <mat-form-field>
      <mat-label>Organisatie</mat-label>
      <mat-select formControlName="org">
        <mat-option
          *ngFor="let org of orgs"
          [value]="org"
        >
          {{ org.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName="usesDriverPlanning"
      >Chauffeursroostersysteem</mat-slide-toggle
    >
    <mat-slide-toggle formControlName="usesSEPASystem"
      >SEPA Betaalmethode</mat-slide-toggle
    >
    <mat-slide-toggle formControlName="usesDriverRemarksInApp"
      >Chauffeurs opmerkingen in app</mat-slide-toggle
    >
    <mat-slide-toggle formControlName="usesUserNotificationSystem"
      >Deelnemer notificatiesysteem</mat-slide-toggle
    >
  </form>
</div>
<div
  mat-dialog-actions
  align="end"
>
  <button
    mat-button
    mat-dialog-close
  >
    Annuleren
  </button>

  <button
    mat-flat-button
    [disabled]="!this.orgsForm.valid"
    (click)="save()"
  >
    Opslaan
  </button>
</div>
