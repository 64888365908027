<div class="wrapper">
  <mat-card>
    <mat-card-content>
      <mat-list 
        role="list"
        *ngIf="type == 'driver'"
      >
        <h3>
          Selecteer de tijdvakken waarin jij normaal gezien beschikbaar bent
        </h3>
        <mat-list-item
          class="dayparts-item"
          *ngFor="let daypart of newDaypartsDriver"
        >
          <div class="driver-planned-dayparts">
            <div class="startTime">
              <h4>{{ daypart.name }}</h4>
              <div class="dayparts">
                <div class="availibility-driver">
                  <h5>
                    Van:
                    {{ daypart.startHour }}:{{ daypart.startMin }}
                  </h5>
                  <h5>
                    Tot:
                    {{ daypart.endHour }}:{{ daypart.endMin }}
                  </h5>
                </div>
                <div class="availability-toggle">
                  <mat-slide-toggle
                    (change)="toggleChange($event, daypart.id)"
                    [disabled]="(isDriver || isPlanner) && !driverCanModify"
                    [checked]="daypart.checked"
                  >
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="newDaypartsDriver.length === 0 && loading === false">
          <p>Er zijn nog geen dagdelen aangemaakt.</p>
        </mat-list-item>
      </mat-list>

      <mat-list 
        role="list"
        *ngIf="type == 'planner'"
      >
        <h3>
          Selecteer de tijdvakken waarin jij normaal gezien beschikbaar bent
        </h3>
        <mat-list-item
          class="dayparts-item"
          *ngFor="let daypart of newDaypartsPlanner"
        >
          <div class="driver-planned-dayparts">
            <div class="startTime">
              <h4>{{ daypart.name }}</h4>
              <div class="dayparts">
                <div class="availibility-driver">
                  <h5>
                    Van:
                    {{ daypart.startHour }}:{{ daypart.startMin }}
                  </h5>
                  <h5>
                    Tot:
                    {{ daypart.endHour }}:{{ daypart.endMin }}
                  </h5>
                </div>
                <div class="availability-toggle">
                  <mat-slide-toggle
                    (change)="toggleChange($event, daypart.id)"
                    [disabled]="(isDriver || isPlanner) && !driverCanModify"
                    [checked]="daypart.checked"
                  >
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="newDaypartsPlanner.length === 0 && loading === false">
          <p>Er zijn nog geen dagdelen aangemaakt.</p>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
